import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Typography, List, ListItem, ListItemText, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, IconButton,
  TextField, Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { createItem, getItems, deleteItem } from '../services/api';

const SavedItemsManager = ({ open, onClose, onAddToEstimate, onAddToInvoice, savedItems, setSavedItems }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [newItem, setNewItem] = useState({ description: '', quantity: 1, unitPrice: 0, tax: 0 });

  const fetchSavedItems = useCallback(async () => {
    try {
      const items = await getItems();
      setSavedItems(items);
    } catch (error) {
      console.error('Error fetching saved items:', error);
    }
  }, [setSavedItems]);

  useEffect(() => {
    fetchSavedItems();
  }, [fetchSavedItems]);

  const handleAddSavedItem = (item) => {
    setCurrentItems(prev => [...prev, { ...item, id: Date.now() }]);
  };

  const handleRemoveItem = (index) => {
    setCurrentItems(prev => prev.filter((_, i) => i !== index));
  };

  const handleAddToDocument = () => {
    if (onAddToEstimate) {
      onAddToEstimate(currentItems);
    } else if (onAddToInvoice) {
      onAddToInvoice(currentItems);
    }
    setCurrentItems([]);
    onClose();
  };

  const handleNewItemChange = (field, value) => {
    setNewItem(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveItem = async () => {
    if (newItem.description && newItem.unitPrice > 0) {
      try {
        const createdItem = await createItem(newItem);
        setSavedItems(prev => [...prev, createdItem]);
        setNewItem({ description: '', quantity: 1, unitPrice: 0, tax: 0 });
      } catch (error) {
        console.error('Error saving item:', error);
      }
    }
  };

  const handleDeleteSavedItem = async (itemId) => {
    try {
      await deleteItem(itemId);
      setSavedItems(prev => prev.filter(item => item.id !== itemId));
    } catch (error) {
      console.error('Error deleting saved item:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Manage Items
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>Add New Item</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              label="Description"
              value={newItem.description}
              onChange={(e) => handleNewItemChange('description', e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <TextField
              label="Quantity"
              type="number"
              value={newItem.quantity}
              onChange={(e) => handleNewItemChange('quantity', parseInt(e.target.value))}
              style={{ marginRight: '10px' }}
            />
            <TextField
              label="Unit Price"
              type="number"
              value={newItem.unitPrice}
              onChange={(e) => handleNewItemChange('unitPrice', parseFloat(e.target.value))}
              style={{ marginRight: '10px' }}
            />
            <TextField
              label="Tax (%)"
              type="number"
              value={newItem.tax}
              onChange={(e) => handleNewItemChange('tax', parseFloat(e.target.value))}
              style={{ marginRight: '10px' }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleSaveItem}
            >
              Save Item
            </Button>
          </Box>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>Saved Items</Typography>
          <List>
            {savedItems.map((item) => (
              <ListItem 
                key={item.id}
                secondaryAction={
                  <IconButton 
                    edge="end" 
                    aria-label="delete" 
                    onClick={() => handleDeleteSavedItem(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText 
                  primary={item.description}
                  secondary={`$${item.unitPrice} | Qty: ${item.quantity} | Tax: ${item.tax}%`}
                />
                <Button onClick={() => handleAddSavedItem(item)}>Add to Current</Button>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>Current Items</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Unit Price</TableCell>
                  <TableCell align="right">Tax (%)</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">${item.unitPrice}</TableCell>
                    <TableCell align="right">{item.tax}%</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddToDocument} color="primary" variant="contained">
          {onAddToEstimate ? 'Add to Estimate' : 'Add to Invoice'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SavedItemsManager;
