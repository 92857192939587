import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAdminTimesheets } from '../services/api';
import { 
  AccessTime, EventNote, AttachMoney, WorkOutline, 
  CalendarToday, HourglassEmpty, CheckCircleOutline, Person, Event
} from '@mui/icons-material';
import styles from './Timesheets.module.scss';

const Timesheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectFilter, setProjectFilter] = useState('All');
  const [employeeFilter, setEmployeeFilter] = useState('All');
  const [eventFilter, setEventFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projects, setProjects] = useState(['All']);
  const [employees, setEmployees] = useState(['All']);
  const [events, setEvents] = useState(['All']);

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const response = await getAdminTimesheets();
      console.log('Raw response from server:', response);

      if (response && Array.isArray(response.timesheets)) {
        setTimesheets(response.timesheets);
        setSummary(response.summary);
        const uniqueProjects = [...new Set(response.timesheets.map(ts => ts.event.project_name))];
        setProjects(['All', ...uniqueProjects]);
        const uniqueEmployees = [...new Set(response.timesheets.map(ts => ts.employee.name))];
        setEmployees(['All', ...uniqueEmployees]);
        const uniqueEvents = [...new Set(response.timesheets.map(ts => ts.event.title))];
        setEvents(['All', ...uniqueEvents]);
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
      setSummary(null);
    } finally {
      setLoading(false);
    }
  };

  const filteredTimesheets = timesheets.filter(timesheet => {
    const projectMatch = projectFilter === 'All' || timesheet.event.project_name === projectFilter;
    const employeeMatch = employeeFilter === 'All' || timesheet.employee.name === employeeFilter;
    const eventMatch = eventFilter === 'All' || timesheet.event.title === eventFilter;
    const dateMatch = (!startDate || new Date(timesheet.clock_in) >= startDate) &&
                      (!endDate || new Date(timesheet.clock_in) <= endDate);
    return projectMatch && employeeMatch && eventMatch && dateMatch;
  });

  const totalHoursWorked = filteredTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);
  const totalCalculatedPayment = filteredTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  const headerCells = [
    { icon: <AccessTime />, label: 'Clock In', color: '#3498db' },
    { icon: <EventNote />, label: 'Clock Out', color: '#e74c3c' },
    { icon: <HourglassEmpty />, label: 'Hours Worked', color: '#f39c12' },
    { icon: <WorkOutline />, label: 'Project', color: '#9b59b6' },
    { icon: <Person />, label: 'Employee', color: '#34495e' },
    { icon: <Event />, label: 'Event', color: '#16a085' },
    { icon: <AttachMoney />, label: 'Payment Type', color: '#2ecc71' },
    { icon: <CalendarToday />, label: 'Rate/Percentage', color: '#1abc9c' },
    { icon: <CheckCircleOutline />, label: 'Calculated Payment', color: '#2ecc71' },
  ];

  return (
    <div className={styles.timesheetsContainer}>
      <h1 className={styles.timesheetsTitle}>Timesheets</h1>
      {summary && (
        <Grid container spacing={3} className={styles.summaryContainer}>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <HourglassEmpty className={styles.summaryIcon} />
                  Total Hours Worked
                </Typography>
                <Typography variant="h4">{totalHoursWorked.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <AttachMoney className={styles.summaryIcon} />
                  Total Calculated Payment
                </Typography>
                <Typography variant="h4">${totalCalculatedPayment.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Box className={styles.filterContainer}>
        <Box className={styles.filterWrapper}>
          <Typography variant="subtitle2" className={styles.filterLabel}>Project</Typography>
          <FormControl variant="outlined" className={styles.filterSelect}>
            <Select
              value={projectFilter}
              onChange={(e) => setProjectFilter(e.target.value)}
            >
              {projects.map((project) => (
                <MenuItem key={project} value={project}>{project}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={styles.filterWrapper}>
          <Typography variant="subtitle2" className={styles.filterLabel}>Employee</Typography>
          <FormControl variant="outlined" className={styles.filterSelect}>
            <Select
              value={employeeFilter}
              onChange={(e) => setEmployeeFilter(e.target.value)}
            >
              {employees.map((emp) => (
                <MenuItem key={emp} value={emp}>{emp}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={styles.filterWrapper}>
          <Typography variant="subtitle2" className={styles.filterLabel}>Event</Typography>
          <FormControl variant="outlined" className={styles.filterSelect}>
            <Select
              value={eventFilter}
              onChange={(e) => setEventFilter(e.target.value)}
            >
              {events.map((event) => (
                <MenuItem key={event} value={event}>{event}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box className={styles.filterWrapper}>
            <Typography variant="subtitle2" className={styles.filterLabel}>Start Date</Typography>
            <DatePicker
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} className={styles.datePicker} />}
            />
          </Box>
          <Box className={styles.filterWrapper}>
            <Typography variant="subtitle2" className={styles.filterLabel}>End Date</Typography>
            <DatePicker
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} className={styles.datePicker} />}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      {loading ? (
        <div className={styles.loadingContainer}>Loading...</div>
      ) : (
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                {headerCells.map((cell, index) => (
                  <TableCell key={index} className={styles.tableCell} align="center">
                    <div className={styles.headerContent}>
                      <span className={styles.headerIcon} style={{ color: cell.color }}>
                        {cell.icon}
                      </span>
                      <span className={styles.headerLabel}>{cell.label}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheets.map((timesheet, index) => (
                <TableRow key={timesheet.id} className={styles.tableRow}>
                  <TableCell className={styles.tableCell} align="center">{new Date(timesheet.clock_in).toLocaleString()}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.clock_out ? new Date(timesheet.clock_out).toLocaleString() : 'Not clocked out'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.hours_worked ? timesheet.hours_worked.toFixed(2) : 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.event.project_name || 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.employee.name || 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.event.title || 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{timesheet.payment_type || 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.payment_type === 'percentage' 
                      ? `${timesheet.percentage}%` 
                      : timesheet.rate ? `$${timesheet.rate}${timesheet.payment_type === 'hourly' ? '/hr' : ''}` : 'N/A'
                    }
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    ${timesheet.calculated_payment ? timesheet.calculated_payment.toFixed(2) : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Timesheets;
