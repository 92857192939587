import React from 'react';

const MinimalistInvoiceTemplate = ({ invoice }) => {
  const formatDate = (date) => {
    return date instanceof Date ? date.toLocaleDateString() : 'N/A';
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTax = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '24px', fontWeight: 'normal' }}>Invoice</h1>
        <p>#{invoice.invoiceNumber}</p>
        <p>Issued: {formatDate(invoice.issueDate)}</p>
        <p>Due: {formatDate(invoice.dueDate)}</p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
        <div>
          <h2 style={{ fontSize: '18px', marginBottom: '10px' }}>From</h2>
          <p>{invoice.businessDetails.companyName}</p>
          <p>{invoice.businessDetails.address}</p>
          <p>{invoice.businessDetails.city}, {invoice.businessDetails.state} {invoice.businessDetails.zipCode}</p>
        </div>
        <div>
          <h2 style={{ fontSize: '18px', marginBottom: '10px' }}>To</h2>
          <p>{invoice.customerDetails.name}</p>
          <p>{invoice.customerDetails.address}</p>
          <p>{invoice.customerDetails.city}, {invoice.customerDetails.state} {invoice.customerDetails.zipCode}</p>
        </div>
      </div>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid #ddd' }}>
            <th style={{ textAlign: 'left', padding: '10px 0' }}>Description</th>
            <th style={{ textAlign: 'right', padding: '10px 0' }}>Quantity</th>
            <th style={{ textAlign: 'right', padding: '10px 0' }}>Unit Price</th>
            <th style={{ textAlign: 'right', padding: '10px 0' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item, index) => (
            <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <td style={{ padding: '10px 0' }}>{item.description}</td>
              <td style={{ textAlign: 'right', padding: '10px 0' }}>{item.quantity}</td>
              <td style={{ textAlign: 'right', padding: '10px 0' }}>${item.unitPrice.toFixed(2)}</td>
              <td style={{ textAlign: 'right', padding: '10px 0' }}>${(item.quantity * item.unitPrice).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ textAlign: 'right' }}>
        <p>Subtotal: ${calculateSubtotal().toFixed(2)}</p>
        <p>Tax: ${calculateTax().toFixed(2)}</p>
        <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Total: ${calculateTotal().toFixed(2)}</p>
      </div>

      {invoice.notes && (
        <div style={{ marginTop: '40px', fontSize: '14px' }}>
          <h2 style={{ fontSize: '18px', marginBottom: '10px' }}>Notes</h2>
          <p>{invoice.notes}</p>
        </div>
      )}
    </div>
  );
};

export default MinimalistInvoiceTemplate;

