import React from 'react';

const EmployeeProjects = () => {
  return (
    <div>
      <h2>Employee Projects</h2>
      {/* Add projects content here */}
    </div>
  );
};

export default EmployeeProjects;
