import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEmployeeDetails, updateEmployeeNotes } from '../services/api';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Box, Grid, Button, Paper, IconButton, List, ListItem, ListItemIcon, ListItemText,
  Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment, Avatar
} from '@mui/material';
import { 
  Person, ListAlt, Schedule, EventNote, HourglassEmpty, 
  WorkOutline, Event, CheckCircleOutline, Email, Phone, Work, Home,
  ExpandMore, CalendarToday, Description, Business, Search, ArrowBack,
  AttachMoney, AccessTime, InsertDriveFile, PictureAsPdf, Description as DescriptionIcon, FileCopy
} from '@mui/icons-material';
import styles from './EmployeeDetails.module.scss';

const EmployeeDetails = () => {
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [searchTerm, setSearchTerm] = useState('');
  const [documentSearchTerm, setDocumentSearchTerm] = useState('');
  const [notes, setNotes] = useState('');  // Add this line
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const data = await getEmployeeDetails(id);
        setEmployee(data);
        setNotes(data.notes || '');  // Add this line
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee details:', error);
        setError('Failed to fetch employee details');
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  const renderDocuments = () => {
    // Dummy data for documents
    const documents = [
      { id: 1, name: 'Employment Contract', type: 'PDF', uploadDate: '2023-01-15', size: '2.3 MB' },
      { id: 2, name: 'Performance Review 2022', type: 'DOCX', uploadDate: '2022-12-20', size: '1.5 MB' },
      { id: 3, name: 'Training Certificate', type: 'PDF', uploadDate: '2023-03-05', size: '500 KB' },
      { id: 4, name: 'NDA Agreement', type: 'PDF', uploadDate: '2023-01-02', size: '1.1 MB' },
      { id: 5, name: 'Employee Handbook', type: 'PDF', uploadDate: '2023-01-10', size: '3.7 MB' },
      { id: 6, name: 'Onboarding Checklist', type: 'XLSX', uploadDate: '2023-01-03', size: '250 KB' },
      { id: 7, name: 'Benefits Summary', type: 'PDF', uploadDate: '2023-02-15', size: '1.8 MB' },
      { id: 8, name: 'Emergency Contact Form', type: 'DOCX', uploadDate: '2023-01-05', size: '320 KB' },
    ];

    const filteredDocuments = documents.filter(doc =>
      doc.name.toLowerCase().includes(documentSearchTerm.toLowerCase()) ||
      doc.type.toLowerCase().includes(documentSearchTerm.toLowerCase()) ||
      doc.uploadDate.includes(documentSearchTerm)
    );

    const getDocumentIcon = (type) => {
      switch (type) {
        case 'PDF':
          return <PictureAsPdf sx={{ color: '#F40F02' }} />;
        case 'DOCX':
          return <DescriptionIcon sx={{ color: '#4285F4' }} />;
        case 'XLSX':
          return <FileCopy sx={{ color: '#0F9D58' }} />;
        default:
          return <InsertDriveFile sx={{ color: '#FFA000' }} />;
      }
    };

    return (
      <div>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif', mb: 2 }}>
          <InsertDriveFile sx={{ verticalAlign: 'middle', mr: 1, color: '#4CAF50' }} />
          Employee Documents
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Search documents"
            value={documentSearchTerm}
            onChange={(e) => setDocumentSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Upload Date</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDocuments.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: 'grey.200', width: 36, height: 36, mr: 2 }}>
                        {getDocumentIcon(doc.type)}
                      </Avatar>
                      <Typography>{doc.name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{doc.type}</TableCell>
                  <TableCell>{doc.uploadDate}</TableCell>
                  <TableCell>{doc.size}</TableCell>
                  <TableCell>
                    <Button variant="outlined" size="small" startIcon={<InsertDriveFile />}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  if (loading) return <div className="loading"><CircularProgress /></div>;
  if (error) return <div className="error"><Typography color="error">{error}</Typography></div>;
  if (!employee) return <div className="error"><Typography>No employee data found</Typography></div>;

  const renderDetails = () => (
    <div>
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif' }}>Employee Details</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Person sx={{ mr: 2, color: '#4CAF50' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
              <strong>Name:</strong> {employee.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Email sx={{ mr: 2, color: '#2196F3' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
              <strong>Email:</strong> {employee.email}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Phone sx={{ mr: 2, color: '#FFC107' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
              <strong>Phone:</strong> {employee.phone}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Work sx={{ mr: 2, color: '#9C27B0' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
              <strong>Position:</strong> {employee.position}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Home sx={{ mr: 2, color: '#E91E63' }} />
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
              <strong>Address:</strong> {employee.address}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <EventNote sx={{ mr: 2, color: '#FF5722', alignSelf: 'flex-start', mt: 1 }} />
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif', mb: 1 }}>
              <strong>Notes:</strong>
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ fontFamily: 'Urbanist, sans-serif' }}
            />
            <Button 
              variant="contained" 
              color="primary" 
              sx={{ mt: 1 }}
              onClick={handleSaveNotes}
            >
              Save Notes
            </Button>
          </Box>
        </Box>
      </Grid>
    </div>
  );

  const headerCells = [
    { icon: <Schedule style={{ color: '#4CAF50' }} />, label: 'CLOCK IN' },
    { icon: <EventNote style={{ color: '#2196F3' }} />, label: 'CLOCK OUT' },
    { icon: <HourglassEmpty style={{ color: '#FFC107' }} />, label: 'HOURS WORKED' },
    { icon: <WorkOutline style={{ color: '#9C27B0' }} />, label: 'PROJECT' },
    { icon: <Event style={{ color: '#E91E63' }} />, label: 'EVENT' },
    { icon: <CheckCircleOutline style={{ color: '#009688' }} />, label: 'CALCULATED PAYMENT' },
  ];

  const renderTimesheets = () => {
    const validTimesheets = employee.recent_timesheets.filter(timesheet => 
      timesheet.calculated_payment !== null &&
      timesheet.clock_in &&
      timesheet.clock_out &&
      timesheet.hours_worked !== null &&
      timesheet.event &&
      timesheet.event.project_name &&
      timesheet.event.title
    );

    console.log('Filtered timesheet data:', validTimesheets);

    return (
      <div className="timesheets-container">
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif' }}>Recent Timesheets</Typography>
        {validTimesheets.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((cell, index) => (
                    <TableCell key={index} align="center">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {cell.icon}
                        <Typography variant="caption" sx={{ mt: 0.5, fontFamily: 'Urbanist, sans-serif' }}>
                          {cell.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {validTimesheets.map((timesheet, index) => (
                  <TableRow key={timesheet.id} className={index % 2 === 0 ? 'table-row even' : 'table-row odd'}>
                    <TableCell className="table-cell" align="center">{new Date(timesheet.clock_in).toLocaleString()}</TableCell>
                    <TableCell className="table-cell" align="center">{new Date(timesheet.clock_out).toLocaleString()}</TableCell>
                    <TableCell className="table-cell" align="center">{timesheet.hours_worked.toFixed(2)}</TableCell>
                    <TableCell className="table-cell" align="center">{timesheet.event.project_name}</TableCell>
                    <TableCell className="table-cell" align="center">{timesheet.event.title}</TableCell>
                    <TableCell className="table-cell" align="center">
                      ${timesheet.calculated_payment.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography className="no-data" align="center" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
            No valid timesheets
          </Typography>
        )}
      </div>
    );
  };

  const renderEvents = () => {
    if (!employee.assigned_events || employee.assigned_events.length === 0) {
      return (
        <Typography variant="body1" sx={{ fontFamily: 'Urbanist, sans-serif', textAlign: 'center', mt: 2 }}>
          No events assigned to this employee.
        </Typography>
      );
    }

    const currentDate = new Date();
    const upcomingEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) >= currentDate
    );
    const completedEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) < currentDate
    );

    console.log("All assigned events:", employee.assigned_events);
    console.log("Upcoming events:", upcomingEvents);
    console.log("Completed events:", completedEvents);

    const renderEventList = (events, title) => (
      <div>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif', mb: 2 }}>
          <Event sx={{ verticalAlign: 'middle', mr: 1, color: '#4CAF50' }} />
          {title}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={`Search ${title.toLowerCase()}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {events
          .filter(event => event.title.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((event, index) => {
            console.log(`Event ${index}:`, event);
            
            // Find all timesheets for this event
            const eventTimesheets = employee.recent_timesheets.filter(ts => ts.event && ts.event.id === event.id);
            console.log(`Timesheets for event ${index}:`, eventTimesheets);

            // Calculate total payment and hours
            const totalPayment = eventTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);
            const totalHours = eventTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);

            return (
              <Accordion key={event.id} sx={{ mb: 2, boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{ 
                    backgroundColor: 'rgba(0, 0, 0, 0.03)', 
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' } 
                  }}
                >
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
                    <CalendarToday sx={{ mr: 1, fontSize: 20, color: '#2196F3' }} />
                    {event.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                      <Description sx={{ mr: 1, fontSize: 20, color: '#FFC107' }} />
                      <strong>Description:</strong> {event.description}
                    </Typography>
                    {event.project && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <Business sx={{ mr: 1, fontSize: 20, color: '#9C27B0' }} />
                        <strong>Project:</strong> {event.project.name}
                      </Typography>
                    )}
                    {totalPayment > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <AttachMoney sx={{ mr: 1, fontSize: 20, color: '#4CAF50' }} />
                        <strong>Total Payment:</strong> ${totalPayment.toFixed(2)}
                      </Typography>
                    )}
                    {totalHours > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <AccessTime sx={{ mr: 1, fontSize: 20, color: '#FF9800' }} />
                        <strong>Total Hours Worked:</strong> {totalHours.toFixed(2)}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', mt: 1, mb: 0.5, fontWeight: 'medium' }}>
                      <Schedule sx={{ mr: 1, fontSize: 20, verticalAlign: 'middle', color: '#E91E63' }} />
                      Time Slots:
                    </Typography>
                    <List dense sx={{ pl: 2, mt: -1 }}>
                      {event.time_slots.map((slot, slotIndex) => (
                        <ListItem key={slotIndex} sx={{ py: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <Schedule fontSize="small" sx={{ color: '#009688' }} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={`${new Date(slot.start_time).toLocaleString()} - ${new Date(slot.end_time).toLocaleString()}`}
                            primaryTypographyProps={{ variant: 'body2', fontFamily: 'Urbanist, sans-serif' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderEventList(upcomingEvents, "Upcoming Events")}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderEventList(completedEvents, "Completed Events")}
        </Grid>
      </Grid>
    );
  };

  const handleSaveNotes = async () => {
    try {
      await updateEmployeeNotes(id, notes);
      // You might want to show a success message here
    } catch (error) {
      console.error('Error saving notes:', error);
      // You might want to show an error message here
    }
  };

  return (
    <div className={`${styles['employee-details']} ${styles['fade-in']}`}>
      <IconButton 
        onClick={() => navigate('/admin/employees')} 
        className={styles['back-button']}
      >
        <ArrowBack />
      </IconButton>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
        <Avatar 
          src={employee.avatar_url || undefined} 
          alt={employee.name} 
          sx={{ width: 100, height: 100, mb: 2 }}
        >
          {employee.name ? employee.name.charAt(0) : ''}
        </Avatar>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          {employee.name}
        </Typography>
      </Box>

      <Box className="tab-container" sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, display: 'flex' }}>
        <Button 
          onClick={() => setActiveTab('details')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'details' ? 'primary.main' : 'text.secondary',
            borderBottom: activeTab === 'details' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <Person sx={{ mr: 1 }} fontSize="small" />
          Details
        </Button>
        <Button 
          onClick={() => setActiveTab('timesheets')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'timesheets' ? 'primary.main' : 'text.secondary',
            borderBottom: activeTab === 'timesheets' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <ListAlt sx={{ mr: 1 }} fontSize="small" />
          Timesheets
        </Button>
        <Button 
          onClick={() => setActiveTab('events')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'events' ? 'primary.main' : 'text.secondary',
            borderBottom: activeTab === 'events' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <Event sx={{ mr: 1 }} fontSize="small" />
          Events
        </Button>
        <Button 
          onClick={() => setActiveTab('documents')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'documents' ? 'primary.main' : 'text.secondary',
            borderBottom: activeTab === 'documents' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <InsertDriveFile sx={{ mr: 1 }} fontSize="small" />
          Documents
        </Button>
      </Box>

      <div key={activeTab} className={`${styles['tab-content']} ${styles['fade-up']}`}>
        {activeTab === 'details' && renderDetails()}
        {activeTab === 'timesheets' && renderTimesheets()}
        {activeTab === 'events' && renderEvents()}
        {activeTab === 'documents' && renderDocuments()}
      </div>
    </div>
  );
};

export default EmployeeDetails;
