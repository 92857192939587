import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from './EmployeeNavbar.module.scss';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from './AuthContext'; // Import useAuth

const EmployeeNavbar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth(); // Use the logout function from AuthContext

  const handleLogout = () => {
    logout(); // Use the logout function from AuthContext
    navigate('/login');
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLinks}>
        <Link to="/employee" className={styles.navbarLink} title="Dashboard">
          <HomeIcon className={styles.icon} />
          <span className={styles.linkText}>Dashboard</span>
        </Link>
        <Link to="/employee/schedule" className={styles.navbarLink} title="My Schedule">
          <CalendarMonthIcon className={styles.icon} />
          <span className={styles.linkText}>My Schedule</span>
        </Link>
        <Link to="/employee/timesheets" className={styles.navbarLink} title="My Timesheets">
          <AccessTimeIcon className={styles.icon} />
          <span className={styles.linkText}>My Timesheets</span>
        </Link>
        <Link to="/employee/availability" className={styles.navbarLink} title="My Availability">
          <EventAvailableIcon className={styles.icon} />
          <span className={styles.linkText}>My Availability</span>
        </Link>
        <Link to="/employee/profile" className={styles.navbarLink} title="My Profile">
          <AccountBoxIcon className={styles.icon} />
          <span className={styles.linkText}>My Profile</span>
        </Link>
        <button onClick={handleLogout} className={styles.navbarLink} title="Logout">
          <LogoutIcon className={styles.icon} />
          <span className={styles.linkText}>Logout</span>
        </button>
      </div>
    </nav>
  );
};

export default EmployeeNavbar;
