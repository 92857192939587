import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, TextField, Button, Checkbox, 
  FormControlLabel, List, ListItem, ListItemText, ListItemSecondaryAction,
  Select, MenuItem, FormControl, InputLabel, Avatar, Box, CircularProgress
} from '@mui/material';
import { 
  getEmployees,
  assignEmployeesToEvent,
  assignEmployeesToProject,
  getProjectEvents,
  getProjectEmployees,
  getEventEmployees
} from '../services/api';
import styles from './EmployeeAssignment.module.scss';

const EventEmployeeAssignment = ({ projectId, onAssignmentUpdate }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [selectedEventId, setSelectedEventId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [events, setEvents] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [allEmployeesResponse, eventsData, projectEmployeesData] = await Promise.all([
        getEmployees(),
        getProjectEvents(projectId),
        getProjectEmployees(projectId)
      ]);

      console.log('All Employees Response:', allEmployeesResponse);
      console.log('Events Data:', eventsData);
      console.log('Project Employees Data:', projectEmployeesData);

      // Access the employees array from the response
      const allEmployees = allEmployeesResponse.employees || [];
      const projectEmployees = projectEmployeesData.employees || [];
      
      console.log('All Employees:', allEmployees);
      console.log('Project Employees:', projectEmployees);

      // Mark project employees as already assigned
      const combinedEmployees = allEmployees.map(emp => ({
        ...emp,
        assignedToProject: projectEmployees.some(pe => pe.id === emp.id)
      }));

      console.log('Combined Employees:', combinedEmployees);

      setEmployees(combinedEmployees);
      setEvents(Array.isArray(eventsData) ? eventsData : []);

      if (eventsData.length > 0) {
        setSelectedEventId(eventsData[0].id);
        const eventEmployeesData = await getEventEmployees(eventsData[0].id);

        // Initialize selectedEmployees state
        const initialSelectedEmployees = {};
        combinedEmployees.forEach(emp => {
          const isAssigned = eventEmployeesData.employees.some(ee => ee.id === emp.id);
          initialSelectedEmployees[emp.id] = {
            selected: isAssigned,
            paymentType: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).payment_type : 'hourly',
            rate: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).rate : '',
            percentage: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).percentage : ''
          };
        });
        setSelectedEmployees(initialSelectedEmployees);
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data. Please try again.');
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEventChange = async (event) => {
    const eventId = event.target.value;
    setSelectedEventId(eventId);
    try {
      setLoading(true);
      const response = await getEventEmployees(eventId);
      console.log('Event Employees Data:', response);
      
      if (response && Array.isArray(response.employees)) {
        // Update selectedEmployees state
        const updatedSelectedEmployees = { ...selectedEmployees };
        employees.forEach(emp => {
          const assignedEmployee = response.employees.find(e => e.id === emp.id);
          updatedSelectedEmployees[emp.id] = {
            selected: !!assignedEmployee,
            paymentType: assignedEmployee ? assignedEmployee.payment_type : 'hourly',
            rate: assignedEmployee ? assignedEmployee.rate || '' : '',
            percentage: assignedEmployee ? assignedEmployee.percentage || '' : ''
          };
        });
        setSelectedEmployees(updatedSelectedEmployees);
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching event employees:', err);
      setError('Failed to fetch event employees. Please try again.');
      setLoading(false);
    }
  };

  const handleEmployeeToggle = (employeeId) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { 
        ...prev[employeeId],
        selected: !prev[employeeId]?.selected
      }
    }));
  };

  const handlePaymentTypeChange = (employeeId, paymentType) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { ...prev[employeeId], paymentType }
    }));
  };

  const handleRateChange = (employeeId, value) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { 
        ...prev[employeeId], 
        rate: (prev[employeeId].paymentType === 'hourly' || prev[employeeId].paymentType === 'fixed') 
          ? value 
          : prev[employeeId].rate,
        percentage: prev[employeeId].paymentType === 'percentage' 
          ? value 
          : prev[employeeId].percentage
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const employeesToAssign = Object.entries(selectedEmployees)
      .filter(([_, value]) => value.selected)
      .map(([id, value]) => ({
        id: parseInt(id),
        payment_type: value.paymentType,
        rate: value.paymentType === 'hourly' || value.paymentType === 'fixed' ? parseFloat(value.rate) || null : null,
        percentage: value.paymentType === 'percentage' ? parseFloat(value.percentage) || null : null
      }));

    console.log('Employees to assign:', employeesToAssign);

    if (employeesToAssign.length === 0) {
      setError('Please select at least one employee to assign.');
      return;
    }

    try {
      setLoading(true);
      
      // Assign employees to the event
      const eventResult = await assignEmployeesToEvent(selectedEventId, employeesToAssign);
      console.log('Event assignment result:', eventResult);

      // Assign employees to the project
      const projectResult = await assignEmployeesToProject(projectId, employeesToAssign);
      console.log('Project assignment result:', projectResult);

      if (eventResult.success && projectResult.success) {
        // After successful assignment, fetch updated assignments
        const updatedAssignments = await getProjectEvents(projectId);
        onAssignmentUpdate(updatedAssignments);
        
        // Update the local state to reflect the new assignments
        setEvents(updatedAssignments);
        
        // Update the selectedEmployees state to reflect the new assignments
        const updatedSelectedEmployees = { ...selectedEmployees };
        employeesToAssign.forEach(emp => {
          updatedSelectedEmployees[emp.id] = {
            ...updatedSelectedEmployees[emp.id],
            selected: true,
            paymentType: emp.payment_type,
            rate: emp.rate || '',
            percentage: emp.percentage || ''
          };
        });
        setSelectedEmployees(updatedSelectedEmployees);

        // Fetch the updated event employees
        const updatedEventEmployees = await getEventEmployees(selectedEventId);
        console.log('Updated Event Employees:', updatedEventEmployees);

        setError(null);
      } else {
        throw new Error('Failed to assign employees to event or project');
      }
    } catch (err) {
      console.error('Error assigning employees:', err);
      setError(`Failed to assign employees: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const filteredEmployees = Array.isArray(employees) 
    ? employees.filter(emp => 
        (emp.name || emp.first_name + ' ' + emp.last_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : [];

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box className={styles.employeeAssignment}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="event-select-label">Select Event</InputLabel>
        <Select
          labelId="event-select-label"
          value={selectedEventId}
          onChange={handleEventChange}
          label="Select Event"
        >
          {events.map((event) => (
            <MenuItem key={event.id} value={event.id}>{event.title}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Search Employees"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchField}
        margin="normal"
      />

      <List className={styles.employeeList}>
        {filteredEmployees.map((employee) => (
          <ListItem 
            key={employee.id} 
            dense 
            onClick={() => handleEmployeeToggle(employee.id)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmployees[employee.id]?.selected || false}
                  tabIndex={-1}
                  disableRipple
                />
              }
              label={
                <div className={styles.employeeInfo}>
                  <Avatar src={employee.avatar} alt={employee.name || `${employee.first_name} ${employee.last_name}`} className={styles.avatar} />
                  <ListItemText 
                    primary={employee.name || `${employee.first_name} ${employee.last_name}`} 
                    secondary={employee.position || employee.job_title}
                  />
                  {employee.assignedToProject && (
                    <Typography variant="caption" color="textSecondary">
                      (Assigned to Project)
                    </Typography>
                  )}
                </div>
              }
            />
            <ListItemSecondaryAction>
              <FormControl variant="outlined" size="small" className={styles.paymentTypeSelect}>
                <Select
                  value={selectedEmployees[employee.id]?.paymentType || 'hourly'}
                  onChange={(e) => handlePaymentTypeChange(employee.id, e.target.value)}
                  disabled={!selectedEmployees[employee.id]?.selected}
                >
                  <MenuItem value="hourly">Hourly</MenuItem>
                  <MenuItem value="fixed">Fixed</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={
                  selectedEmployees[employee.id]?.paymentType === 'percentage' 
                    ? 'Percentage' 
                    : selectedEmployees[employee.id]?.paymentType === 'fixed'
                      ? 'Fixed Rate'
                      : 'Hourly Rate'
                }
                type="number"
                value={selectedEmployees[employee.id]?.paymentType === 'percentage' 
                  ? selectedEmployees[employee.id]?.percentage || ''
                  : selectedEmployees[employee.id]?.rate || ''}
                onChange={(e) => handleRateChange(employee.id, e.target.value)}
                disabled={!selectedEmployees[employee.id]?.selected}
                size="small"
                className={styles.rateField}
                InputProps={{
                  startAdornment: selectedEmployees[employee.id]?.paymentType !== 'percentage' ? '$' : null,
                  endAdornment: selectedEmployees[employee.id]?.paymentType === 'percentage' ? '%' : null
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Button 
        onClick={handleSubmit}
        variant="contained" 
        color="primary" 
        className={styles.submitButton}
        fullWidth
      >
        Assign Employees to Event
      </Button>
    </Box>
  );
};

export default EventEmployeeAssignment;