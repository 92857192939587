import React, { useState, useCallback } from 'react';
import { Paper, Grid } from '@mui/material';
import EventEmployeeAssignment from './EventEmployeeAssignment';
import EventAssignmentsList from './EventAssignmentsList';
import styles from './EmployeeAssignment.module.scss';

const EmployeeAssignment = ({ projectId, onUpdate }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleAssignmentUpdate = useCallback(() => {
    setRefreshTrigger(prev => prev + 1); // Increment to trigger a refresh
    onUpdate(); // Call the parent's onUpdate if needed
  }, [onUpdate]);

  return (
    <Paper elevation={3} className={styles.employeeAssignment}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EventEmployeeAssignment 
            projectId={projectId} 
            onAssignmentUpdate={handleAssignmentUpdate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EventAssignmentsList 
            projectId={projectId} 
            refreshTrigger={refreshTrigger}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeAssignment;