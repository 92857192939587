import React, { useState, useEffect } from 'react';
import { 
  Typography, List, ListItem, ListItemText, 
  Accordion, AccordionSummary, AccordionDetails,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getProjectEvents, getEventEmployees } from '../services/api';

const EventAssignmentsList = ({ projectId, refreshTrigger }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const eventsData = await getProjectEvents(projectId);
        const eventsWithEmployees = await Promise.all(eventsData.map(async (event) => {
          const employeesData = await getEventEmployees(event.id);
          return { ...event, employees: employeesData.employees || [] };
        }));
        setEvents(eventsWithEmployees);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events and assignments:', err);
        setError('Failed to fetch events and assignments. Please try again.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, [projectId, refreshTrigger]); // Add refreshTrigger to the dependency array

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Event Assignments
      </Typography>
      {events.length > 0 ? (
        events.map((event) => (
          <Accordion key={event.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{event.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {event.employees.length > 0 ? (
                <List>
                  {event.employees.map((emp) => (
                    <ListItem key={emp.id}>
                      <ListItemText 
                        primary={emp.employee?.name || emp.name || 'Unknown Employee'}
                        secondary={`${emp.payment_type} - ${emp.payment_type === 'percentage' ? emp.percentage + '%' : '$' + emp.rate}`} 
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No employees assigned to this event.</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>No events found for this project.</Typography>
      )}
    </div>
  );
};

export default EventAssignmentsList;
