import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './MobileMenu.module.scss';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className={styles.mobileMenu}>
      <button onClick={toggleMenu} className={styles.menuButton}>
        ☰
      </button>
      {isOpen && (
        <div className={styles.menuItems}>
          <Link to="/" className={styles.menuItem} onClick={toggleMenu}>Home</Link>
          <Link to="/admin-dashboard" className={styles.menuItem} onClick={toggleMenu}>Dashboard</Link>
          {/* Add more menu items as needed */}
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
