import React, { useState, useEffect, useCallback } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
  Grid,
  Paper,
  Avatar
} from '@mui/material';
import { format } from 'date-fns';
import { getEventDetails, getEventEmployees } from '../services/api';
import EventForm from './EventForm';
import styles from './EventDetails.module.scss';
// Add these imports
import { keyframes } from '@emotion/react';
import { Description, LocationOn, AttachMoney, Schedule, Person } from '@mui/icons-material';
import { Box } from '@mui/material';

// Modify the animation to include a translate effect
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const EventDetails = ({ eventId, open, onClose, onEventUpdated }) => {
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const fetchEventData = useCallback(async () => {
    if (!eventId) return;
    setLoading(true);
    try {
      const [details, employeesData] = await Promise.all([
        getEventDetails(eventId),
        getEventEmployees(eventId)
      ]);
      setEventDetails(details);
      setEmployees(employeesData.employees || []);
    } catch (err) {
      console.error('Error fetching event details:', err);
      setError('Failed to load event details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (open) {
      fetchEventData();
    }
  }, [open, fetchEventData]);

  const handleEdit = () => {
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const handleEventUpdated = async (updatedEvent) => {
    setIsEditOpen(false);
    setEventDetails(updatedEvent);
    if (onEventUpdated) {
      onEventUpdated(updatedEvent);
    }
  };

  return (
    <>
      {!isEditOpen && (
        <Dialog 
          open={open && !isEditOpen} 
          onClose={onClose} 
          maxWidth="md" 
          fullWidth
          scroll="paper"
        >
          <DialogContent 
            className={styles.dialogContent}
            sx={{
              animation: `${fadeInUp} 0.8s ease-out`,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '80vh',
              padding: '24px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <DialogTitle className={styles.dialogTitle} sx={{ padding: 0, marginBottom: 2 }}>
              <Typography variant="h5">{eventDetails?.emoji} {eventDetails?.title}</Typography>
            </DialogTitle>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} className={styles.detailsSection} sx={{ height: '100%', p: 2 }}>
                  <Typography variant="h6" gutterBottom>Event Details</Typography>
                  <Typography className={styles.detailItem} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
                    <Description color="primary" sx={{ mt: 0.5 }} /> 
                    <Box>
                      <strong>Description:</strong><br />
                      {eventDetails?.description}
                    </Box>
                  </Typography>
                  {eventDetails?.addresses && eventDetails.addresses.map((address, index) => (
                    <Typography key={index} className={styles.detailItem} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
                      <LocationOn color="secondary" sx={{ mt: 0.5 }} /> 
                      <Box>
                        <strong>Address:</strong><br />
                        {address}
                      </Box>
                    </Typography>
                  ))}
                  <Typography className={styles.detailItem} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AttachMoney color="success" /> <strong>Value:</strong> ${eventDetails?.value ? eventDetails.value.toFixed(2) : 'N/A'}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} className={styles.detailsSection} sx={{ height: '100%', p: 2 }}>
                  <Typography variant="h6" gutterBottom>Time Slots</Typography>
                  {eventDetails?.time_slots && eventDetails.time_slots.map((slot, index) => (
                    <Box key={index} className={styles.timeSlot} sx={{ mb: 2 }}>
                      <Typography sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 1 }}>
                        <Schedule color="primary" sx={{ mt: 0.5 }} />
                        <Box>
                          <strong>Start:</strong><br />
                          {format(new Date(slot.start_time), 'PPP p')}
                        </Box>
                      </Typography>
                      <Typography sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                        <Schedule color="secondary" sx={{ mt: 0.5 }} />
                        <Box>
                          <strong>End:</strong><br />
                          {format(new Date(slot.end_time), 'PPP p')}
                        </Box>
                      </Typography>
                    </Box>
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} className={styles.detailsSection} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Person color="primary" /> Assigned Employees
                  </Typography>
                  {employees.length > 0 ? (
                    <ul className={styles.employeeList}>
                      {employees.map((employee) => (
                        <li key={employee.id} className={styles.employeeItem}>
                          <Avatar src={employee.avatar} alt={employee.name} className={styles.avatar} />
                          <div className={styles.employeeInfo}>
                            <Typography variant="subtitle1">{employee.name}</Typography>
                            <Typography variant="body2">{employee.email}</Typography>
                            <Typography variant="body2">{employee.phone}</Typography>
                            <Typography variant="body2">
                              {employee.payment_type === 'hourly' && `$${employee.rate}/hour`}
                              {employee.payment_type === 'fixed' && `$${employee.rate}`}
                              {employee.payment_type === 'percentage' && `${employee.percentage}%`}
                            </Typography>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography>No employees assigned to this event.</Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={styles.dialogActions} sx={{ padding: '16px 24px' }}>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={handleEdit} color="primary" variant="contained">
              Edit Event
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <EventForm
        open={isEditOpen}
        onClose={handleCloseEdit}
        projectId={eventDetails?.project_id}
        onEventCreated={handleEventUpdated}
        event={eventDetails}
      />
    </>
  );
};

export default EventDetails;
