import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { uploadLogo, deleteLogo } from '../services/api';

const API_URL = process.env.REACT_APP_API_URL || 'https://teams.everprocleaning.com';

const LogoUploader = ({ open, onClose, onSelectLogo, logos, setLogos }) => {
  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('file', file);
      console.log('Uploading file:', file.name, 'Size:', file.size, 'Type:', file.type);
      const uploadedLogo = await uploadLogo(formData);
      setLogos(prevLogos => [...prevLogos, uploadedLogo]);
      console.log('Logo uploaded successfully:', uploadedLogo);
      
      onSelectLogo(uploadedLogo);
      onClose();
    } catch (error) {
      console.error('Error uploading logo:', error.response ? error.response.data : error.message);
    }
  };

  if (logos.length > 0) {
    onSelectLogo(logos[0]);
    onClose();
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Upload Logo</DialogTitle>
      <DialogContent>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleLogoUpload}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" color="primary">
            UPLOAD NEW LOGO
          </Button>
        </label>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoUploader;
