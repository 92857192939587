import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, CircularProgress, Button, Avatar, Tooltip, Tabs, Tab, IconButton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { getEmployeeEventDetails, getProjectPhotos, uploadProjectPhoto, getEmployeeTimesheets } from '../services/api';
import styles from './EmployeeEventDetails.module.scss';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ProjectPhotos from './ProjectPhotos';
import { CSSTransition } from 'react-transition-group';

const avatarColors = ['#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b', '#0288d1', '#00796b', '#fbc02d', '#f57c00', '#455a64'];

const EmployeeEventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [projectPhotos, setProjectPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [fadeIn, setFadeIn] = useState(true);
  const [projectPhotosLoading, setProjectPhotosLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (eventId) {
      const fetchEventDetails = async () => {
        try {
          setLoading(true);
          const [eventData, timesheetsData] = await Promise.all([
            getEmployeeEventDetails(eventId),
            getEmployeeTimesheets()
          ]);
          console.log('Event data:', eventData);
          console.log('Timesheets data:', timesheetsData);
          setEvent(eventData);
          if (eventData.project && eventData.project.id) {
            const photos = await getProjectPhotos(eventData.project.id);
            console.log('Project photos:', photos);
            setProjectPhotos(photos);
          }
          // Filter timesheets for the specific event
          const filteredTimesheets = timesheetsData.timesheets.filter(
            timesheet => timesheet.event.id === parseInt(eventId)
          );
          console.log('Filtered timesheets:', filteredTimesheets);
          setTimesheets(filteredTimesheets);
          setError(null);
        } catch (err) {
          console.error('Error fetching event details:', err);
          setError('Failed to fetch event details. Please try again later.');
        } finally {
          setLoading(false);
        }
      };

      fetchEventDetails();
    }
  }, [eventId]); // Only re-run the effect if eventId changes

  const handleTabChange = (event, newValue) => {
    setFadeIn(false);
    setTimeout(() => {
      setTabValue(newValue);
      setFadeIn(true);
    }, 500); // Increased timeout to match the new transition duration
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !event || !event.project) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('photo', file);
      
      await uploadProjectPhoto(event.project.id, formData);
      
      // Refresh project photos
      const updatedPhotos = await getProjectPhotos(event.project.id);
      setProjectPhotos(updatedPhotos);
    } catch (error) {
      console.error('Error uploading photo:', error);
      // You might want to show an error message to the user here
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  if (!event) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>Event not found.</Typography>
      </div>
    );
  }

  return (
    <Paper elevation={3} className={styles.eventDetailsContainer}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        className={styles.backButton}
      >
        Back
      </Button>
      <Typography variant="h4" className={styles.eventTitle}>
        <EventIcon className={styles.titleIcon} />
        {event.title}
      </Typography>
      
      <Tabs value={tabValue} onChange={handleTabChange} className={styles.tabs}>
        <Tab label="Details" icon={<EventIcon />} />
        <Tab label="Project Photos" icon={<PhotoLibraryIcon />} />
        <Tab label="Timesheets" icon={<AccessTimeIcon />} />
      </Tabs>

      <CSSTransition
        in={fadeIn}
        timeout={500}
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
        unmountOnExit
      >
        <div className={`${styles.tabContent} ${fadeIn ? styles.fadeIn : ''}`}>
          {tabValue === 0 && (
            <div>
              <div className={styles.detailItem}>
                <AccessTimeIcon className={`${styles.detailIcon} ${styles.timeIcon}`} />
                <Typography>
                  {event.time_slots && event.time_slots.length > 0
                    ? `${moment(event.time_slots[0].start_time).format('MMMM D, YYYY h:mm A')} - ${moment(event.time_slots[0].end_time).format('h:mm A')}`
                    : 'Time not specified'}
                </Typography>
              </div>
              {event.addresses && event.addresses.length > 0 && (
                <div className={styles.detailItem}>
                  <LocationOnIcon className={`${styles.detailIcon} ${styles.locationIcon}`} />
                  <div className={styles.addressContainer}>
                    {event.addresses.map((address, index) => (
                      <Typography key={index} className={styles.addressText}>{address}</Typography>
                    ))}
                  </div>
                </div>
              )}
              {event.employees && event.employees.length > 0 && (
                <div className={styles.detailItem}>
                  <PeopleIcon className={`${styles.detailIcon} ${styles.peopleIcon}`} />
                  <div className={styles.employeesContainer}>
                    <Typography variant="subtitle1">Assigned Employees:</Typography>
                    <div className={styles.avatarGroup}>
                      {event.employees.map((employee, index) => (
                        <Tooltip key={employee.id} title={employee.name} arrow>
                          <Avatar 
                            className={styles.employeeAvatar}
                            style={{ 
                              backgroundColor: avatarColors[index % avatarColors.length]
                            }}
                          >
                            {employee.name.charAt(0)}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {event.description && (
                <div className={styles.descriptionContainer}>
                  <Typography variant="h6">Description:</Typography>
                  <Typography>{event.description}</Typography>
                </div>
              )}
            </div>
          )}

          {tabValue === 1 && (
            <div>
              <ProjectPhotos 
                projectId={event.project.id} 
                onLoadComplete={() => {
                  setProjectPhotosLoading(false);
                  setFadeIn(true);
                }}
              />
            </div>
          )}

          {tabValue === 2 && (
            <div className={styles.timesheetsContainer}>
              <Typography variant="h6">Timesheets for this Event</Typography>
              {timesheets.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <TableContainer component={Paper} className={`${styles.tableWrapper} ${styles.tableContainer}`}>
                    <Table className={styles.table}>
                      <TableHead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                        <TableRow>
                          {[
                            { icon: <AccessTimeIcon />, label: 'Clock In', color: '#3498db' },
                            { icon: <EventNoteIcon />, label: 'Clock Out', color: '#e74c3c' },
                            { icon: <AttachMoneyIcon />, label: 'Payment Type', color: '#2ecc71' },
                            { icon: <CalendarTodayIcon />, label: 'Rate/Percentage', color: '#1abc9c' },
                            { icon: <HourglassEmptyIcon />, label: 'Hours Worked', color: '#f39c12' },
                            { icon: <CheckCircleOutlineIcon />, label: 'Calculated Payment', color: '#27ae60' },
                          ].map((cell, index) => (
                            <TableCell key={index} align="center" className={styles.tableCell}>
                              <div className={styles.headerContent}>
                                <div className={styles.headerIcon} style={{ backgroundColor: cell.color }}>
                                  {React.cloneElement(cell.icon, { style: { color: 'white' } })}
                                </div>
                                <span className={styles.headerLabel}>{cell.label}</span>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timesheets.map((timesheet) => (
                          <TableRow key={timesheet.id}>
                            <TableCell className={styles.tableCell}>{moment(timesheet.clock_in).format('MMMM D, YYYY h:mm A')}</TableCell>
                            <TableCell className={styles.tableCell}>{timesheet.clock_out ? moment(timesheet.clock_out).format('MMMM D, YYYY h:mm A') : 'Not clocked out'}</TableCell>
                            <TableCell className={styles.tableCell}>{timesheet.payment_type}</TableCell>
                            <TableCell className={styles.tableCell}>{timesheet.payment_type === 'hourly' ? `$${timesheet.rate}/hr` : `${timesheet.percentage}%`}</TableCell>
                            <TableCell className={styles.tableCell}>{timesheet.hours_worked.toFixed(2)}</TableCell>
                            <TableCell className={styles.tableCell}>${timesheet.calculated_payment.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow className={styles.totalRow}>
                          <TableCell className={styles.tableCell} colSpan={4}></TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#f39c12' }}>
                                <HourglassEmptyIcon className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>{timesheets.reduce((sum, t) => sum + t.hours_worked, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#27ae60' }}>
                                <AttachMoneyIcon className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>${timesheets.reduce((sum, t) => sum + t.calculated_payment, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Typography>No timesheets available for this event.</Typography>
              )}
            </div>
          )}
        </div>
      </CSSTransition>
    </Paper>
  );
};

export default EmployeeEventDetails;
