import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Autocomplete,
  CircularProgress,
  Alert,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Client } from './types';
import { getClients, createClient } from '../../services/api';
import debounce from 'lodash/debounce';
import { test } from './test';

interface Props {
  onClientSelect: (client: Client) => void;
  onClientCreate: (client: Client) => void;
  styles: {
    paper: string;
    sectionTitle: string;
    formSection: string;
    inputUnderline: string;
  };
  selectedClient: Client | null;
  initialData: {
    name: string;
    company: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    notes: string;
    isNewClient: boolean;
  };
}

interface AddressSuggestion {
  formatted_address: {
    street_number: string;
    street_name: string;
    city: string;
    state: string;
    zip: string;
  };
}

interface NominatimResult {
  display_name: string;
  address: {
    house_number?: string;
    road?: string;
    city?: string;
    town?: string;
    state?: string;
    postcode?: string;
    municipality?: string;
  };
}

interface ClientFormData {
  id?: string | number;
  name: string;
  company: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  notes: string;
  isNewClient: boolean;
  created_at?: string;
}

const US_STATES = [
  ['Alabama', 'AL'], ['Alaska', 'AK'], ['Arizona', 'AZ'], ['Arkansas', 'AR'],
  ['California', 'CA'], ['Colorado', 'CO'], ['Connecticut', 'CT'], ['Delaware', 'DE'],
  ['Florida', 'FL'], ['Georgia', 'GA'], ['Hawaii', 'HI'], ['Idaho', 'ID'],
  ['Illinois', 'IL'], ['Indiana', 'IN'], ['Iowa', 'IA'], ['Kansas', 'KS'],
  ['Kentucky', 'KY'], ['Louisiana', 'LA'], ['Maine', 'ME'], ['Maryland', 'MD'],
  ['Massachusetts', 'MA'], ['Michigan', 'MI'], ['Minnesota', 'MN'], ['Mississippi', 'MS'],
  ['Missouri', 'MO'], ['Montana', 'MT'], ['Nebraska', 'NE'], ['Nevada', 'NV'],
  ['New Hampshire', 'NH'], ['New Jersey', 'NJ'], ['New Mexico', 'NM'], ['New York', 'NY'],
  ['North Carolina', 'NC'], ['North Dakota', 'ND'], ['Ohio', 'OH'], ['Oklahoma', 'OK'],
  ['Oregon', 'OR'], ['Pennsylvania', 'PA'], ['Rhode Island', 'RI'], ['South Carolina', 'SC'],
  ['South Dakota', 'SD'], ['Tennessee', 'TN'], ['Texas', 'TX'], ['Utah', 'UT'],
  ['Vermont', 'VT'], ['Virginia', 'VA'], ['Washington', 'WA'], ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'], ['Wyoming', 'WY']
] as const;

const CACHE_KEY = 'clientsCache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getCache = () => {
  const cached = localStorage.getItem(CACHE_KEY);
  if (!cached) return null;
  
  const { data, timestamp } = JSON.parse(cached);
  if (Date.now() - timestamp > CACHE_DURATION) {
    localStorage.removeItem(CACHE_KEY);
    return null;
  }
  return data;
};

const setCache = (data: Client[]) => {
  localStorage.setItem(CACHE_KEY, JSON.stringify({
    data,
    timestamp: Date.now()
  }));
};

const ClientEstimateForm: React.FC<Props> = ({ onClientSelect, onClientCreate, styles, selectedClient, initialData }) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newClient, setNewClient] = useState<ClientFormData>(initialData || {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    notes: '',
    isNewClient: true
  });
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const [addressSuggestions, setAddressSuggestions] = useState<NominatimResult[]>([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const cachedData = getCache();
        if (cachedData) {
          setClients(cachedData);
          setLoading(false);
          return;
        }

        const response = await getClients();
        const clientsData = response.clients || response.data || [];
        const formattedClients = clientsData.map((client: any) => ({
          id: client.id,
          name: client.name || '',
          company: client.company || '',
          email: client.email || '',
          phone: formatPhoneNumber(client.phone || ''),
          address: client.address || '',
          city: client.city || '',
          state: client.state || '',
          zipCode: client.zipCode || '',
          notes: client.notes || '',
          created_at: client.created_at || new Date().toISOString(),
          isNewClient: false
        }));
        
        setClients(formattedClients);
        setCache(formattedClients);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  const handleClientSelect = async (client: Client) => {
    if (!client) return;
    
    console.log('Selected client:', client);

    // First set all the client data we have
    const formattedClient = {
      id: client.id,
      name: client.name || '',
      company: client.company || '',
      email: client.email || '',
      phone: formatPhoneNumber(client.phone || ''),
      address: client.address || '',
      city: client.city || '',
      state: client.state || '',
      zipCode: client.zipCode || '',
      notes: client.notes || '',
      isNewClient: false,
      created_at: client.created_at
    };

    console.log('Formatted client data:', formattedClient);

    // Update form state immediately
    setNewClient(formattedClient);
    
    // Pass to parent
    onClientSelect({
      ...client,
      phone: formatPhoneNumber(client.phone || '')
    });

    // If we have an address, look up missing details
    if (client.address) {
      try {
        const searchQuery = encodeURIComponent(`${client.address} ${client.city || ''} ${client.state || ''}`);
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${searchQuery}&countrycodes=us&addressdetails=1&limit=1`,
          { headers: { 'Accept': 'application/json' } }
        );
        const [data] = await response.json();
        
        if (data?.address) {
          const addr = data.address;
          // Force a new state update with all fields
          setNewClient(current => ({
            ...current,
            address: client.address.trim(),  // Keep original address
            city: addr.city || addr.town || addr.municipality || current.city || '',
            state: US_STATES.find(([fullName]) => 
              fullName.toLowerCase() === (addr.state || '').toLowerCase()
            )?.[1] || current.state || '',
            zipCode: addr.postcode || current.zipCode || ''
          }));

          // Also update parent with complete data
          onClientSelect({
            ...client,
            city: addr.city || addr.town || addr.municipality || '',
            state: US_STATES.find(([fullName]) => 
              fullName.toLowerCase() === (addr.state || '').toLowerCase()
            )?.[1] || '',
            zipCode: addr.postcode || '',
            phone: formatPhoneNumber(client.phone || '')
          });
        }
      } catch (error) {
        console.error('Error fetching address details:', error);
      }
    }
  };

  const handleInputChange = (field: string, value: string) => {
    const updatedClient = {
      ...newClient,
      [field]: value,
      isNewClient: true
    };
    
    setNewClient(updatedClient);
    
    // Make sure we're passing changes to parent
    onClientSelect(updatedClient as Client);
  };

  const formatPhoneNumber = (value: string) => {
    const phone = value.replace(/\D/g, '');
    if (phone.length < 4) return phone;
    if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  };

  const searchAddress = async (query: string) => {
    if (!query || query.length < 3) return;
    setIsLoadingAddress(true);
    
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(query)}&format=json&countrycodes=us`
      );
      const data = await response.json();
      
      const suggestions = data.map((item: any) => ({
        formatted_address: {
          street_number: item.address.house_number || '',
          street_name: item.address.road || '',
          city: item.address.city || item.address.town || '',
          state: item.address.state || '',
          zip: item.address.postcode || ''
        }
      }));
      
      setAddressSuggestions(suggestions);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  const debouncedSearchAddress = debounce(searchAddress, 1000);

  const validateZipCode = (zipCode: string) => {
    return /^\d{5}(-\d{4})?$/.test(zipCode);
  };

  const validateForm = () => {
    const errors: {[key: string]: string} = {};
    
    if (!newClient.name.trim()) errors.name = 'Name is required';
    if (!newClient.email.trim()) errors.email = 'Email is required';
    if (newClient.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newClient.email)) {
      errors.email = 'Invalid email format';
    }
    if (!newClient.phone.trim()) errors.phone = 'Phone is required';
    if (newClient.phone && newClient.phone.replace(/\D/g, '').length !== 10) {
      errors.phone = 'Invalid phone number';
    }
    if (newClient.zipCode && !validateZipCode(newClient.zipCode)) {
      errors.zipCode = 'Invalid ZIP code';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddressSelect = (selectedAddress: NominatimResult) => {
    if (!selectedAddress?.address) return;
    
    const addr = selectedAddress.address;
    const streetNumber = addr.house_number || '';
    const street = addr.road || '';
    const city = addr.city || addr.town || addr.municipality || '';
    const state = US_STATES.find(([fullName]) => 
      fullName.toLowerCase() === (addr.state || '').toLowerCase()
    )?.[1] || '';
    const zipCode = addr.postcode || '';

    // Update form state with complete address
    setNewClient(prev => ({
      ...prev,
      address: `${streetNumber} ${street}`.trim(),
      city,
      state,
      zipCode
    }));
  };

  const fetchAddressSuggestions = debounce(async (input: string) => {
    if (!input || input.length < 3) {
      setAddressSuggestions([]);
      return;
    }
    
    setIsLoadingAddress(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&countrycodes=us&addressdetails=1&limit=5`,
        {
          headers: {
            'Accept': 'application/json'
          }
        }
      );
      const data = await response.json();
      setAddressSuggestions(data);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      setAddressSuggestions([]);
    } finally {
      setIsLoadingAddress(false);
    }
  }, 300);

  const commonStyles = {
    height: '45px',
    width: '100%',
    fontSize: '0.95rem'
  };

  const inputStyle = {
    ...commonStyles,
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px',
    fontFamily: 'inherit',
    '&:hover': {
      borderColor: '#666'
    }
  };

  const buttonStyle = {
    ...commonStyles,
    textTransform: 'none',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  };

  const handleCreateClient = async () => {
    if (!validateForm()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const phoneNumber = newClient.phone.replace(/\D/g, '');
      
      if (!newClient.isNewClient && newClient.id) {
        onClientSelect({
          ...newClient,
          created_at: newClient.created_at || new Date().toISOString()
        } as Client);
        setLoading(false);
        return;
      }

      // Check if client already exists
      const existingClient = clients.find(client => 
        client.email.toLowerCase() === newClient.email.toLowerCase() ||
        client.phone.replace(/\D/g, '') === phoneNumber
      );

      if (existingClient) {
        onClientSelect(existingClient);
        setLoading(false);
        return;
      }

      // Create new client
      const clientData = {
        name: newClient.name.trim(),
        company: newClient.company.trim(),
        email: newClient.email.trim(),
        phone: phoneNumber,
        password: phoneNumber,
        address: newClient.address.trim(),
        city: newClient.city.trim(),
        state: newClient.state.trim(),
        zipCode: newClient.zipCode.trim(),
        notes: newClient.notes.trim(),
        created_at: new Date().toISOString()
      };

      const response = await createClient(clientData);
      
      if (response && response.data) {
        const createdClient = response.data;
        const updatedClients = [...clients, createdClient];
        setClients(updatedClients);
        setCache(updatedClients);
        onClientCreate(createdClient);
      }
    } catch (err) {
      console.error('Error creating client:', err);
      setError(err.response?.data?.message || 'Failed to create client. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={styles.formSection}>
      <Typography variant="h6" className={styles.sectionTitle}>
        Client Information
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button 
            variant="outlined" 
            fullWidth 
            onClick={() => setIsSearchModalOpen(true)}
            sx={buttonStyle}
          >
            Search Existing Clients
          </Button>

          <Dialog 
            open={isSearchModalOpen} 
            onClose={() => setIsSearchModalOpen(false)}
            maxWidth="sm" 
            fullWidth
            PaperProps={{
              sx: {
                overflow: 'visible',  // Allow content to overflow
                '.MuiDialogContent-root': {
                  overflow: 'visible'  // Allow DialogContent to overflow
                }
              }
            }}
          >
            <DialogTitle 
              sx={{ 
                borderBottom: '1px solid #eee',
                px: 3,
                py: 2.5,
                backgroundColor: '#f8f9fa'
              }}
            >
              Search Existing Clients
            </DialogTitle>

            <DialogContent 
              sx={{ 
                p: 3,
                overflow: 'visible'  // Allow content to overflow
              }}
            >
              <Autocomplete
                options={clients}
                getOptionLabel={(option) => option.name || ''}
                loading={loading}
                autoComplete
                disablePortal={false}
                forcePopupIcon={false}
                blurOnSelect
                onChange={(_, newValue) => {
                  if (newValue) {
                    handleClientSelect(newValue);
                    setIsSearchModalOpen(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    placeholder="Type client name to search..."
                    variant="outlined"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '45px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        '& .MuiAutocomplete-endAdornment': {
                          display: 'none'  // Hide the dropdown arrow
                        }
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="primary" size={20} /> : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </DialogContent>

            <DialogActions 
              sx={{ 
                borderTop: '1px solid #eee',
                px: 3,
                py: 2,
                backgroundColor: '#f8f9fa',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1
              }}
            >
              <Button 
                onClick={() => setIsSearchModalOpen(false)}
                variant="contained"
                sx={{ 
                  textTransform: 'none',
                  backgroundColor: '#2196f3',
                  color: 'white',
                  fontSize: '0.95rem',
                  px: 3,
                  py: 1,
                  borderRadius: '6px',
                  boxShadow: '0 2px 8px rgba(33,150,243,0.3)',
                  '&:hover': {
                    backgroundColor: '#1976d2',
                    boxShadow: '0 4px 12px rgba(33,150,243,0.4)'
                  }
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="text"
            value={newClient.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            placeholder="Name"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="text"
            value={newClient.company}
            onChange={(e) => handleInputChange('company', e.target.value)}
            placeholder="Company"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="email"
            value={newClient.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder="Email"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="tel"
            value={newClient.phone}
            onChange={(e) => {
              const formatted = formatPhoneNumber(e.target.value);
              handleInputChange('phone', formatted);
            }}
            placeholder="Phone"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12}>
          <input
            type="text"
            value={newClient.address}
            onChange={(e) => handleInputChange('address', e.target.value)}
            placeholder="Address"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <input
            type="text"
            value={newClient.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
            placeholder="City"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <select
            value={newClient.state}
            onChange={(e) => handleInputChange('state', e.target.value)}
            style={{
              ...inputStyle,
              height: '63px',
              appearance: 'none',
              backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='18px' height='18px'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 8px center'
            }}
          >
            <option value="">State</option>
            {US_STATES.map(([name, abbr]) => (
              <option key={abbr} value={abbr}>
                {name} ({abbr})
              </option>
            ))}
          </select>
        </Grid>

        <Grid item xs={12} md={4}>
          <input
            type="text"
            value={newClient.zipCode}
            onChange={(e) => handleInputChange('zipCode', e.target.value)}
            placeholder="Zip Code"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12}>
          <textarea
            value={newClient.notes}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            placeholder="Notes"
            style={{
              ...inputStyle,
              minHeight: '45px',
              height: '45px',
              resize: 'vertical'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientEstimateForm;
