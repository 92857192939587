import React from 'react';
import { Outlet } from 'react-router-dom';
import EmployeeNavbar from './EmployeeNavbar';
import styles from './EmployeeLayout.module.scss';

const EmployeeLayout = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.navbarWrapper}>
        <EmployeeNavbar />
      </div>
      <main className={styles.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default EmployeeLayout;
