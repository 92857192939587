import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, InputLabel, Snackbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  Description, AttachMoney, Business, Person, Event,
  CalendarToday, CheckCircleOutline, HourglassEmpty,
  Add as AddIcon, Receipt as ReceiptIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styles from './AdminInvoices.module.scss';
import { getInvoices } from '../services/api';

const AdminInvoices = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('All Time');
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Paid', 'Pending', 'Overdue']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [totalInvoiceValue, setTotalInvoiceValue] = useState(0);
  const [dueInvoiceValue, setDueInvoiceValue] = useState(0);

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        console.log('Fetching invoices...');
        const fetchedInvoices = await getInvoices();
        console.log('Fetched invoices:', fetchedInvoices);
        setInvoices(fetchedInvoices);
        const uniqueClients = ['All', ...new Set(fetchedInvoices.map(inv => inv.customer_details?.name || 'Unknown'))];
        setClients(uniqueClients);
        const filteredInvoices = getFilteredInvoices(fetchedInvoices);
        const totalInvoiceValue = calculateTotalInvoiceValue(filteredInvoices);
        const dueInvoiceValue = calculateDueInvoiceValue(filteredInvoices);
        setTotalInvoiceValue(totalInvoiceValue);
        setDueInvoiceValue(dueInvoiceValue);
      } catch (err) {
        console.error('Error fetching invoices:', err);
        setError('Failed to fetch invoices. Please try again later.');
        setSnackbarMessage('Failed to fetch invoices. Please try again later.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const getFilteredInvoices = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    return invoices.filter(invoice => {
      const clientMatch = clientFilter === 'All' || invoice.customer_details?.name === clientFilter;
      const statusMatch = statusFilter === 'All' || invoice.status === statusFilter;
      const invDate = new Date(invoice.issueDate);
      let dateMatch = true;

      switch (timeFilter) {
        case 'This Month':
          dateMatch = invDate >= startOfMonth;
          break;
        case 'This Quarter':
          dateMatch = invDate >= startOfQuarter;
          break;
        case 'This Year':
          dateMatch = invDate >= startOfYear;
          break;
        default:
          dateMatch = true;
      }

      return clientMatch && statusMatch && dateMatch;
    });
  };

  const filteredInvoices = getFilteredInvoices();


  const formatCurrency = (amount) => {
    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const calculateTotal = (invoice) => {
    return invoice.items.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  const calculateTotalInvoiceValue = (invoices) => {
    return invoices.reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const calculateDueInvoiceValue = (invoices) => {
    return invoices
      .filter(invoice => invoice.status === 'Pending' || invoice.status === 'Overdue')
      .reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const handleCreateInvoice = () => {
    navigate('/admin/invoices/create');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const headerCells = [
    { icon: <Description />, label: 'Invoice ID', color: '#3498db' },
    { icon: <Business />, label: 'Client', color: '#e74c3c' },
    { icon: <AttachMoney />, label: 'Amount', color: '#f39c12' },
    { icon: <CalendarToday />, label: 'Issue Date', color: '#16a085' },
    { icon: <CalendarToday />, label: 'Due Date', color: '#9b59b6' },
    { icon: <CheckCircleOutline />, label: 'Status', color: '#2ecc71' },
  ];

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={styles.invoicesContainer}>
        <Box display="flex" alignItems="center" className={styles.titleContainer}>
          <ReceiptIcon className={styles.titleIcon} style={{ color: '#3498db' }} />
          <Typography variant="h4" component="h1" className={styles.invoicesTitle}>
            Invoices
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" className={styles.filterContainer}>
          <Box display="flex" alignItems="center">
            <FormControl variant="outlined" size="small" className={styles.filterSelect}>
              <InputLabel>Client</InputLabel>
              <Select
                value={clientFilter}
                onChange={(e) => setClientFilter(e.target.value)}
                label="Client"
              >
                {clients.map((client) => (
                  <MenuItem key={client} value={client}>{client}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={styles.filterSelect}>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Status"
              >
                {statuses.map((status) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" className={styles.filterSelect}>
              <InputLabel>Time Period</InputLabel>
              <Select
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value)}
                label="Time Period"
              >
                <MenuItem value="This Month">This Month</MenuItem>
                <MenuItem value="This Quarter">This Quarter</MenuItem>
                <MenuItem value="This Year">This Year</MenuItem>
                <MenuItem value="All Time">All Time</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={styles.buttonContainer}>
            <Button 
              variant="contained" 
              color="primary" 
              className={styles.createButton}
              startIcon={<AddIcon />}
              onClick={handleCreateInvoice}
            >
              Create New Invoice
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3} className={styles.summaryContainer}>
          <Grid item xs={12} md={4}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <HourglassEmpty className={styles.summaryIcon} style={{ color: '#3498db' }} />
                  Total Invoices
                </Typography>
                <Typography variant="h4">{filteredInvoices.length}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                {headerCells.map((cell, index) => (
                  <TableCell key={index} className={styles.tableCell} align="center">
                    <div className={styles.headerContent}>
                      <span className={styles.headerIcon} style={{ color: cell.color }}>
                        {cell.icon}
                      </span>
                      <span className={styles.headerLabel}>{cell.label}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvoices.map((invoice) => (
                <TableRow 
                  key={invoice.id} 
                  className={styles.tableRow}
                  onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell className={styles.tableCell} align="center">{invoice.invoice_number}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{invoice.customer_details?.name || 'N/A'}</TableCell>
                  <TableCell className={styles.tableCell} align="center">${formatCurrency(calculateTotal(invoice))}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{new Date(invoice.issue_date).toLocaleDateString()}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{new Date(invoice.due_date).toLocaleDateString()}</TableCell>
                  <TableCell className={styles.tableCell} align="center">{invoice.status || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </div>
    </LocalizationProvider>
  );
};

export default AdminInvoices;
