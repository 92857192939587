import React, { useState } from 'react';
import { TextField, Typography, Box, Tabs, Tab } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import styles from './AdminSettings.module.scss';

const currentDate = new Date().toISOString().split('T')[0];

const mockTermsOfService = `
1. Acceptance of Terms

By accessing and using this service, you accept and agree to be bound by the terms and provision of this agreement.

2. Description of Service

The Company provides users with access to [description of your service]. You are responsible for obtaining access to the Service and that access may involve third-party fees (such as Internet service provider or airtime charges). You are responsible for those fees.

3. User Conduct

You agree to use the Service for lawful purposes only. You must not use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service.

4. Termination of Agreement

The Company may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

5. Limitation of Liability

In no event shall the Company be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

6. Changes to Terms

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at our sole discretion.

7. Contact Us

If you have any questions about these Terms, please contact us.
`;

const mockPrivacyPolicy = `
Privacy Policy

Effective Date: 10/22/24
Last Updated: 10/22/24

At ServTrackr, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service. By accessing or using the Service, you agree to the collection and use of your information in accordance with this Privacy Policy.

1. Information Collection and Use
We collect various types of information for the purpose of providing, maintaining, and improving our Service. The types of information we collect depend on the interaction and services you engage with.

2. Types of Data Collected
2.1. Personal Data
While using our Service, you may be asked to provide certain personally identifiable information that can be used to contact or identify you. This data may include but is not limited to:

Name
Email address
Phone number
Postal address
Username and password
2.2. Usage Data
We automatically collect information on how the Service is accessed and used. This Usage Data may include:

Your computer's Internet Protocol (IP) address
Browser type and version
The pages of our Service that you visit
Time and date of your visit
Time spent on those pages
Unique device identifiers and other diagnostic data
2.3. Cookies and Tracking Data
We use cookies and similar tracking technologies to track activity on our Service and store certain information. Cookies are small data files placed on your device. You can instruct your browser to refuse cookies or alert you when cookies are being sent.

Types of cookies used:

Session Cookies: To operate our Service.
Preference Cookies: To remember your preferences and settings.
Security Cookies: For security purposes.
3. How We Use Your Data
We use the collected data for a variety of purposes, including:

To provide and improve our Service: To deliver and enhance the functionality of the Service.
To manage your account: To authenticate your identity and provide access to the Service.
To contact you: To send administrative information, updates, and responses to your inquiries.
To personalize your experience: To deliver tailored content and features.
To process transactions: To complete transactions and process payments.
To analyze usage and trends: To improve user experience, monitor trends, and gather analytical insights.
To comply with legal obligations: To fulfill regulatory and legal responsibilities.
4. Data Retention and Deletion
We retain your Personal Data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. Once it is no longer required, we securely delete or anonymize your data in accordance with legal and regulatory requirements.

If you wish to delete your data or request that we no longer use it, please contact us (details in Section 10). However, please note that certain data may be retained to comply with legal obligations or for legitimate business purposes.

5. Transfer of Data
Your information, including Personal Data, may be transferred to — and maintained on — servers located outside your jurisdiction where the data protection laws may be different. If you are located outside of the jurisdictions where our servers are maintained, you consent to the transfer of your information, including Personal Data, to those jurisdictions.

We take all necessary steps to ensure your data is treated securely in accordance with this Privacy Policy and applicable laws.

6. Disclosure of Data
We may disclose your Personal Data to third parties under the following circumstances:

With your consent: We may share your data with third parties when you have provided explicit consent to do so.
For business transfers: If we are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred as part of that transaction.
For legal obligations: We may disclose your data if required to do so by law or in response to valid legal requests by public authorities.
To protect our interests: To protect and defend the rights or property of [Your Company Name], prevent wrongdoing, and safeguard the safety of users.
7. Security of Data
We use commercially acceptable means to protect your Personal Data. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security. If you suspect a data breach, please notify us immediately (details in Section 10).

Our security measures include:

Encryption: We use SSL encryption to secure data transmitted to and from our servers.
Access Controls: We restrict access to your Personal Data to authorized personnel.
Regular Security Audits: We perform regular audits of our security practices to safeguard against vulnerabilities.
8. Your Data Protection Rights
You are entitled to the following rights regarding your data:

Right to Access: You have the right to request access to the Personal Data we hold about you.
Right to Rectification: You have the right to request corrections to inaccurate or incomplete data.
Right to Erasure: You can request that we delete your Personal Data under certain circumstances.
Right to Data Portability: You can request a copy of your data in a structured, commonly used format.
Right to Object and Restrict: You may object to our use of your data or request restriction of certain processing activities.
To exercise any of these rights, please contact us (details in Section 10).

9. Third-Party Services
Our Service may contain links to third-party websites or services that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We advise you to review the Privacy Policy of every site you visit.

We are not responsible for the content, privacy policies, or practices of any third-party websites or services.

10. Contact Us
If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us using the following methods:

Email: [Insert Email Address]
Phone: [Insert Phone Number]
Postal Address: [Insert Postal Address]
We will respond to your inquiry within a reasonable time frame and make efforts to address your concerns or requests.

11. Changes to This Privacy Policy
We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. If we make significant changes, we will notify you via email or by placing a prominent notice on our Service.

You are encouraged to review this Privacy Policy periodically for any updates. Your continued use of our Service after the changes have been made constitutes acceptance of the revised Privacy Policy.

12. Governing Law
This Privacy Policy shall be governed and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law provisions.

By using the Service, you agree to the jurisdiction of the courts of [Insert Jurisdiction] for any disputes arising from this Privacy Policy.
`;

function AdminSettingsTOS() {
  const [termsOfService, setTermsOfService] = useState(mockTermsOfService);
  const [privacyPolicy, setPrivacyPolicy] = useState(mockPrivacyPolicy);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles.tabPanel}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="TOS and Privacy Policy tabs">
          <Tab icon={<DescriptionIcon />} label="Terms of Service" />
          <Tab icon={<LockIcon />} label="Privacy Policy" />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Terms of Service
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={20}
            value={termsOfService}
            onChange={(e) => setTermsOfService(e.target.value)}
            variant="outlined"
          />
        </>
      )}
      {tabValue === 1 && (
        <>
          <Typography variant="h6" gutterBottom>
            Privacy Policy
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={20}
            value={privacyPolicy}
            onChange={(e) => setPrivacyPolicy(e.target.value)}
            variant="outlined"
          />
        </>
      )}
    </div>
  );
}

export default AdminSettingsTOS;
