import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Avatar, Grid, Box } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentIcon from '@mui/icons-material/Payment';
import styles from './ClientPayments.module.scss';

const CARD_ICONS = {
  visa: '💳',
  mastercard: '💳',
  amex: '💳',
  discover: '💳',
};

// Dummy data
const dummyPayments = [
  { id: 1, date: '2023-05-01', amount: 1000, status: 'Paid', project: 'Website Redesign', card: { brand: 'visa', last4: '4242' } },
  { id: 2, date: '2023-05-15', amount: 1500, status: 'Pending', project: 'Mobile App Development', card: { brand: 'mastercard', last4: '5555' } },
  { id: 3, date: '2023-05-30', amount: 800, status: 'Overdue', project: 'Logo Design', card: { brand: 'amex', last4: '0005' } },
];

const dummySavedCards = [
  { id: 1, brand: 'visa', last4: '4242', cardHolder: 'John Doe', expiryDate: '05/24' },
  { id: 2, brand: 'mastercard', last4: '5555', cardHolder: 'Jane Smith', expiryDate: '09/25' },
  { id: 3, brand: 'amex', last4: '0005', cardHolder: 'Alice Johnson', expiryDate: '12/23' },
];

const PaymentRow = ({ payment }) => (
  <TableRow className={styles.tableRow}>
    <TableCell>{payment.date}</TableCell>
    <TableCell className={styles.amount}>${payment.amount.toLocaleString()}</TableCell>
    <TableCell>
      <Chip 
        label={payment.status} 
        className={`${styles.statusChip} ${styles[payment.status.toLowerCase()]}`}
        size="small"
      />
    </TableCell>
    <TableCell>{payment.project}</TableCell>
    <TableCell>
      <Typography variant="body2" className={styles.cardUsed}>
        {CARD_ICONS[payment.card.brand.toLowerCase()] || '💳'} **** {payment.card.last4}
      </Typography>
    </TableCell>
  </TableRow>
);

const CreditCard = ({ card }) => (
  <div className={`${styles.creditCard} ${styles[card.brand.toLowerCase()]}`}>
    <CreditCardIcon className={styles.cardChip} />
    <Typography variant="h6" className={styles.cardBrand}>{card.brand.toUpperCase()}</Typography>
    <Typography variant="h5" className={styles.cardNumber}>**** **** **** {card.last4}</Typography>
    <div className={styles.cardBottom}>
      {['Card Holder', 'Expires'].map(label => (
        <div key={label} className={styles.cardInfo}>
          <Typography variant="caption" className={styles.cardInfoLabel}>{label}</Typography>
          <Typography variant="body2" className={styles.cardInfoValue}>
            {label === 'Card Holder' ? card.cardHolder : card.expiryDate}
          </Typography>
        </div>
      ))}
    </div>
  </div>
);

const ClientPayments = () => (
  <div className={styles.paymentsContainer}>
    <Box display="flex" alignItems="center" mb={3} className={styles.fadeUpAnimation}>
      <PaymentIcon fontSize="large" className={styles.titleIcon} />
      <Typography variant="h4" className={styles.title}>Payments</Typography>
    </Box>
    <Grid container spacing={3}>
      <Grid item xs={12} lg={9} className={styles.fadeUpAnimation} style={{animationDelay: '0.1s'}}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table}>
            <TableHead className={styles.tableHead}>
              <TableRow>
                {['Date', 'Amount', 'Status', 'Project', 'Card Used'].map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody}>
              {dummyPayments.map((payment) => (
                <PaymentRow key={payment.id} payment={payment} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} lg={3} className={styles.fadeUpAnimation} style={{animationDelay: '0.2s'}}>
        <Paper className={styles.savedCardsContainer}>
          <Box display="flex" alignItems="center" mb={2}>
            <CreditCardIcon className={styles.savedCardsTitleIcon} />
            <Typography variant="h6" className={styles.savedCardsTitle}>Saved Cards</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            {dummySavedCards.map((card) => (
              <CreditCard key={card.id} card={card} />
            ))}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </div>
);

export default ClientPayments;
