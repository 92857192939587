import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '../services/api';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import styles from './CreateClient.module.scss';

const CreateClient = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const clientData = {
        name,
        email,
        password,
        phone,
        company,
        address,
        notes
      };
      console.log('Submitting client data:', clientData);
      const response = await createClient(clientData);
      console.log('Create client response:', response);
      navigate('/admin/clients');
    } catch (err) {
      console.error('Error creating client:', err);
      if (err.response) {
        console.error('Error response data:', err.response.data);
        console.error('Error response status:', err.response.status);
        console.error('Error response headers:', err.response.headers);
        setError(`Failed to create client: ${err.response.data.error || JSON.stringify(err.response.data)}`);
      } else {
        setError('Failed to create client. Please try again.');
      }
    }
  };

  return (
    <Container className={styles.createClientContainer}>
      <Typography variant="h4" component="h1" gutterBottom>
        Create New Client
      </Typography>
      <Box component="form" onSubmit={handleSubmit} className={styles.form}>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" variant="contained" color="primary" className={styles.submitButton}>
          Create Client
        </Button>
      </Box>
    </Container>
  );
};

export default CreateClient;
