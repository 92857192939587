import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Paper, Grid, CircularProgress, Tabs, Tab, Box, TextField, Button, Modal, IconButton, Divider } from '@mui/material';
import { getProjectFinancials, getMaterialCosts, addMaterialCost, deleteMaterialCost } from '../services/api';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SavingsIcon from '@mui/icons-material/Savings';
import PersonIcon from '@mui/icons-material/Person';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoryIcon from '@mui/icons-material/Category';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './Financials.module.scss';
import AddIcon from '@mui/icons-material/Add';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Financials = ({ projectId }) => {
  const [financials, setFinancials] = useState(null);
  const [materialCosts, setMaterialCosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [newMaterialCost, setNewMaterialCost] = useState({ item: '', cost: '' });
  const [modalOpen, setModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [financialsData, materialCostsData] = await Promise.all([
        getProjectFinancials(projectId),
        getMaterialCosts(projectId)
      ]);
      console.log('Financials Data:', financialsData);
      console.log('Material Costs Data:', materialCostsData);
      setFinancials(financialsData);
      setMaterialCosts(Array.isArray(materialCostsData) ? materialCostsData : []);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load financial data');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddMaterialCost = async () => {
    try {
      const addedCost = await addMaterialCost(projectId, newMaterialCost);
      setMaterialCosts(prevCosts => [...prevCosts, addedCost]);
      setFinancials(prevFinancials => ({
        ...prevFinancials,
        material_costs: prevFinancials.material_costs + parseFloat(addedCost.cost),
        total_costs: prevFinancials.total_costs + parseFloat(addedCost.cost),
        profit: prevFinancials.profit - parseFloat(addedCost.cost)
      }));
      setNewMaterialCost({ item: '', cost: '' });
      setModalOpen(false);
    } catch (error) {
      console.error('Error adding material cost:', error);
      setError('Failed to add material cost');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!financials) return <Typography>No financial data available</Typography>;

  const formatNumber = (value) => {
    return value != null ? Number(value).toFixed(2) : 'N/A';
  };

  const TotalsTab = () => (
    <div>
      <Typography variant="h6" className={styles.tabTitle}>
        <AttachMoneyIcon className={styles.iconGreen} /> Totals
      </Typography>
      <div className={styles.totalsGrid}>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <AccessTimeIcon className={styles.iconBlue} />
          </span>
          <div>
            <Typography variant="body2">Total Hours</Typography>
            <Typography variant="body1">{formatNumber(financials.total_hours)}</Typography>
          </div>
        </div>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <WorkIcon className={styles.iconPurple} />
          </span>
          <div>
            <Typography variant="body2">Total Labor Cost</Typography>
            <Typography variant="body1">${formatNumber(financials.total_labor_cost)}</Typography>
          </div>
        </div>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <ShoppingCartIcon className={styles.iconOrange} />
          </span>
          <div>
            <Typography variant="body2">Material Costs</Typography>
            <Typography variant="body1">${formatNumber(financials.material_costs)}</Typography>
          </div>
        </div>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <ReceiptIcon className={styles.iconRed} />
          </span>
          <div>
            <Typography variant="body2">Total Costs</Typography>
            <Typography variant="body1">${formatNumber(financials.total_costs)}</Typography>
          </div>
        </div>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <TrendingUpIcon className={styles.iconGreen} />
          </span>
          <div>
            <Typography variant="body2">Total Project Value</Typography>
            <Typography variant="body1">${formatNumber(financials.total_project_value)}</Typography>
          </div>
        </div>
        <div className={styles.totalItem}>
          <span className={styles.iconCircle}>
            <SavingsIcon className={styles.iconTeal} />
          </span>
          <div>
            <Typography variant="body2">Profit</Typography>
            <Typography variant="body1">${formatNumber(financials.profit)}</Typography>
          </div>
        </div>
      </div>
    </div>
  );

  const EmployeeCostTab = () => (
    <div className={styles.tabContent}>
      <Typography variant="h6" className={styles.tabTitle}>
        <PeopleIcon className={styles.iconBlue} /> Employee Cost
      </Typography>
      <div className={styles.employeeList}>
        {financials.employee_breakdown.map((employee, index) => (
          <div key={index} className={styles.employeeRow}>
            <div className={styles.detailItem}>
              <span className={styles.iconCircle}>
                <PersonIcon className={styles.iconPurple} />
              </span>
              <span>{employee.name}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.iconCircle}>
                <HourglassEmptyIcon className={styles.iconOrange} />
              </span>
              <span>{formatNumber(employee.hours)}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.iconCircle}>
                <MonetizationOnIcon className={styles.iconGreen} />
              </span>
              <span>${formatNumber(employee.cost)}</span>
            </div>
          </div>
        ))}
        <div className={styles.totalRow}>
          <div className={styles.detailItem}></div>
          <div className={styles.detailItem}>
            <span className={styles.iconCircle}>
              <AccessTimeIcon className={styles.iconBlue} />
            </span>
            <div className={styles.totalLabel}>
              <Typography variant="body2">Total Hours</Typography>
              <Typography variant="body1">{formatNumber(financials.total_hours)}</Typography>
            </div>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.iconCircle}>
              <AttachMoneyIcon className={styles.iconGreen} />
            </span>
            <div className={styles.totalLabel}>
              <Typography variant="body2">Total Cost</Typography>
              <Typography variant="body1">${formatNumber(financials.total_labor_cost)}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const MaterialCostTab = () => {
    const totalMaterialCost = materialCosts.reduce((sum, cost) => sum + parseFloat(cost.cost), 0);

    const handleDeleteMaterialCost = async (costId) => {
      try {
        await deleteMaterialCost(projectId, costId);
        // Refresh material costs after deletion
        const updatedCosts = await getMaterialCosts(projectId);
        setMaterialCosts(updatedCosts);
      } catch (error) {
        console.error('Error deleting material cost:', error);
        // Optionally, show an error message to the user
      }
    };

    return (
      <div className={styles.tabContent}>
        <Typography variant="h6" className={styles.tabTitle}>
          <InventoryIcon className={styles.iconOrange} /> Material Cost
        </Typography>
        <div className={styles.employeeList}>
          {materialCosts.map((cost, index) => (
            <div key={index} className={styles.employeeRow}>
              <div className={styles.detailItem}>
                <span className={styles.iconCircle}>
                  <CategoryIcon className={styles.iconPurple} />
                </span>
                <span>{cost.item}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.iconCircle}>
                  <MonetizationOnIcon className={styles.iconGreen} />
                </span>
                <span>${formatNumber(cost.cost)}</span>
              </div>
              <div className={styles.detailItem}>
                <IconButton 
                  onClick={() => handleDeleteMaterialCost(cost.id)} 
                  size="small" 
                  className={styles.deleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
          {materialCosts.length > 0 && (
            <div className={styles.totalRow}>
              <div className={styles.detailItem}>
                <span className={styles.iconCircle}>
                  <SummarizeIcon className={styles.iconBlue} />
                </span>
                <div className={styles.totalLabel}>
                  <Typography variant="body2">Total Material Cost</Typography>
                  <Typography variant="body1">${formatNumber(totalMaterialCost)}</Typography>
                </div>
              </div>
              <div className={styles.detailItem}></div>
              <div className={styles.detailItem}></div>
            </div>
          )}
        </div>
        <Box className={styles.addButtonContainer}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setModalOpen(true)}
            startIcon={<AddIcon />}
          >
            Add Material Cost
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <Paper elevation={3} className={styles.financialsPaper}>
      <Typography variant="h5" gutterBottom className={styles.financialsTitle}>
        <AttachMoneyIcon 
          className={styles.iconGreen} 
          style={{ fontSize: '2rem', marginRight: '8px', verticalAlign: 'middle' }} 
        />
        Financial Overview
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} className={styles.financialsTabs}>
        <Tab label="Totals" />
        <Tab label="Employee Cost" />
        <Tab label="Material Cost" />
      </Tabs>
      <div className={styles.tabContainer}>
        {tabValue === 0 && <div className={styles.fadeUp}><TotalsTab /></div>}
        {tabValue === 1 && <div className={styles.fadeUp}><EmployeeCostTab /></div>}
        {tabValue === 2 && <div className={styles.fadeUp}><MaterialCostTab /></div>}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="add-material-cost-modal"
        aria-describedby="modal-to-add-new-material-cost"
      >
        <Box sx={modalStyle}>
          <Typography id="add-material-cost-modal" variant="h6" component="h2">
            Add Material Cost
          </Typography>
          <TextField
            label="Item"
            value={newMaterialCost.item}
            onChange={(e) => setNewMaterialCost({ ...newMaterialCost, item: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cost"
            type="number"
            value={newMaterialCost.cost}
            onChange={(e) => setNewMaterialCost({ ...newMaterialCost, cost: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddMaterialCost}>
            Add Material Cost
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

export default Financials;
