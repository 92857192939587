import React, { useState } from 'react';
import { Box, Tabs, Tab, Paper, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/Support';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GavelIcon from '@mui/icons-material/Gavel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdminSettingsBusinessDetails from './AdminSettingsBusinessDetails';
import AdminSettingsPayment from './AdminSettingsPayment';
import AdminSettingsTOS from './AdminSettingsTOS';
import AdminSettingsSupport from './AdminSettingsSupport';
import AdminSettingsAppSettings from './AdminSettingsAppSettings';
import AdminSettingsMyMoney from './AdminSettingsMyMoney';
import AdminSettingsCompliance from './AdminSettingsCompliance';
import AdminSettingsPayroll from './AdminSettingsPayroll';
import styles from './AdminSettings.module.scss';

function AdminSettings() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles.adminSettings}>
      <Box className={styles.titleContainer}>
        <SettingsIcon className={styles.titleIcon} />
        <Typography variant="h4" className={styles.title}>
          Admin Settings
        </Typography>
      </Box>
      <Paper className={styles.settingsContainer}>
        <Box className={styles.tabsContainer}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="admin settings tabs">
            <Tab icon={<BusinessIcon className={styles.businessIcon} />} label="Business Profile" />
            <Tab icon={<PaymentIcon className={styles.paymentIcon} />} label="Payment" />
            <Tab icon={<DescriptionIcon className={styles.termsIcon} />} label="Terms of Service" />
            <Tab icon={<SupportIcon className={styles.supportIcon} />} label="Support" />
            <Tab icon={<AppSettingsAltIcon className={styles.appSettingsIcon} />} label="App Settings" />
            <Tab icon={<AccountBalanceWalletIcon className={styles.myMoneyIcon} />} label="My Money" />
            <Tab icon={<GavelIcon className={styles.complianceIcon} />} label="Compliance" />
            <Tab icon={<MonetizationOnIcon className={styles.payrollIcon} />} label="Payroll" />
          </Tabs>
        </Box>

        {tabValue === 0 && <AdminSettingsBusinessDetails />}
        {tabValue === 1 && <AdminSettingsPayment />}
        {tabValue === 2 && <AdminSettingsTOS />}
        {tabValue === 3 && <AdminSettingsSupport />}
        {tabValue === 4 && <AdminSettingsAppSettings />}
        {tabValue === 5 && <AdminSettingsMyMoney />}
        {tabValue === 6 && <AdminSettingsCompliance />}
        {tabValue === 7 && <AdminSettingsPayroll />}
      </Paper>
    </div>
  );
}

export default AdminSettings;
