import React, { useState } from 'react';
import { Typography, Paper, Box, Button, TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import styles from './AdminSettings.module.scss';

function AdminSettingsPayroll() {
  const [payrollSettings, setPayrollSettings] = useState({
    payPeriod: 'biweekly',
    payDay: 'friday',
    payrollProvider: '',
    accountNumber: '',
    routingNumber: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPayrollSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSaveSettings = () => {
    console.log('Saving payroll settings:', payrollSettings);
    // Implement the logic to save the payroll settings
  };

  return (
    <Paper className={styles.tabPanel}>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h5" component="h2" className={styles.sectionTitle}>
          <MonetizationOnIcon className={styles.headerIcon} />
          Payroll Settings
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Pay Period</InputLabel>
            <Select
              name="payPeriod"
              value={payrollSettings.payPeriod}
              onChange={handleInputChange}
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Bi-weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Pay Day</InputLabel>
            <Select
              name="payDay"
              value={payrollSettings.payDay}
              onChange={handleInputChange}
            >
              <MenuItem value="monday">Monday</MenuItem>
              <MenuItem value="tuesday">Tuesday</MenuItem>
              <MenuItem value="wednesday">Wednesday</MenuItem>
              <MenuItem value="thursday">Thursday</MenuItem>
              <MenuItem value="friday">Friday</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Payroll Provider"
            name="payrollProvider"
            value={payrollSettings.payrollProvider}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Account Number"
            name="accountNumber"
            value={payrollSettings.accountNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Routing Number"
            name="routingNumber"
            value={payrollSettings.routingNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
            className={styles.saveButton}
          >
            Save Payroll Settings
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AdminSettingsPayroll;
