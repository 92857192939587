import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
  Tooltip,
  InputAdornment,
  ClickAwayListener
} from '@mui/material';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  Sort as SortIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { getProjectEvents, deleteEvent, getProjectDetails } from '../services/api';
import EventForm from './EventForm';
import EventDetails from './EventDetails';
import { format } from 'date-fns';
import styles from './Events.module.scss';

const Events = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedEventForEdit, setSelectedEventForEdit] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [selectedEventIdForDetails, setSelectedEventIdForDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isSearchHovered, setIsSearchHovered] = useState(false);

  const fetchProjectDetails = useCallback(async () => {
    try {
      const response = await getProjectDetails(projectId);
      console.log('Project details:', response);
      const projectDetails = response.data;
      setProjectName(projectDetails.name || '');
    } catch (error) {
      console.error('Error fetching project details:', error);
      setError('Failed to fetch project details. Please try again later.');
    }
  }, [projectId]);

  const fetchEvents = useCallback(async () => {
    try {
      const fetchedEvents = await getProjectEvents(projectId);
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events. Please try again later.');
    }
  }, [projectId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([fetchProjectDetails(), fetchEvents()]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchProjectDetails, fetchEvents]);

  useEffect(() => {
    filterAndSortEvents();
  }, [events, searchTerm, sortBy]);

  const filterAndSortEvents = () => {
    let filtered = events.filter(event => 
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filtered.sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(a.time_slots[0].start_time) - new Date(b.time_slots[0].start_time);
      } else if (sortBy === 'title') {
        return a.title.localeCompare(b.title);
      }
      return 0;
    });

    setFilteredEvents(filtered);
  };

  const handleAddEvent = () => {
    setSelectedEventForEdit(null);
    setIsFormOpen(true);
  };

  const handleEditEvent = (event) => {
    setSelectedEventForEdit(event);
    setIsFormOpen(true);
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await deleteEvent(eventId);
        await fetchEvents();
        setSnackbar({ open: true, message: 'Event deleted successfully', severity: 'success' });
      } catch (error) {
        console.error('Error deleting event:', error);
        setSnackbar({ open: true, message: 'Failed to delete event', severity: 'error' });
      }
    }
  };

  const handleViewEventDetails = (eventId) => {
    setSelectedEventIdForDetails(eventId);
    setIsEventDetailsOpen(true);
  };

  const handleCloseEventDetails = () => {
    setIsEventDetailsOpen(false);
    setSelectedEventIdForDetails(null);
  };

  const handleEventCreated = async () => {
    await fetchEvents();
    setIsFormOpen(false);
    setSnackbar({ open: true, message: 'Event created successfully', severity: 'success' });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleBack = () => {
    navigate(`/projects/${projectId}`);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMM d, yyyy h:mm a');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchMouseEnter = () => {
    setIsSearchExpanded(true);
    setIsSearchHovered(true);
  };

  const handleSearchMouseLeave = () => {
    if (!searchTerm) {
      setIsSearchExpanded(false);
    }
    setIsSearchHovered(false);
  };

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = events.filter(item => {
      return Object.keys(item).some(key =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredEvents(filteredData);
  }, [searchTerm, events]);

  if (loading) return <CircularProgress className={styles.loader} />;
  if (error) return <Typography color="error" className={styles.error}>{error}</Typography>;

  return (
    <div className={styles.eventsContainer}>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={handleBack} className={styles.backButton}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" className={styles.title}>
          Events for {projectName || `Project ${projectId}`}
        </Typography>
      </Box>
      
      <Box className={styles.controls} display="flex" justifyContent="space-between" alignItems="center">
        <div className={styles.searchWrapper}>
          <SearchIcon className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search events"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchField}
          />
        </div>
        <div className={styles.buttonGroup}>
          <FormControl variant="outlined" className={styles.sortSelect}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              label="Sort by"
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="title">Title</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddEvent}
            className={styles.addButton}
          >
            Add Event
          </Button>
        </div>
      </Box>

      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table>
          <TableBody>
            {filteredEvents.map((event) => (
              <TableRow key={event.id}>
                <TableCell className={styles.eventCell}>
                  <Tooltip title="Title" placement="right">
                    <span>{event.emoji} {event.title}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={styles.eventCell}>
                  <Tooltip title="Description" placement="right">
                    <span>{event.description}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={styles.eventCell}>
                  <Tooltip title="Start Time" placement="right">
                    <span>{formatDate(event.time_slots[0].start_time)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={styles.eventCell}>
                  <Tooltip title="End Time" placement="right">
                    <span>{formatDate(event.time_slots[event.time_slots.length - 1].end_time)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={styles.eventCell}>
                  <Tooltip title="Value" placement="right">
                    <span>${event.value ? event.value.toFixed(2) : 'N/A'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className={styles.actionCell}>
                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="View" placement="top">
                      <IconButton onClick={() => handleViewEventDetails(event.id)} color="primary">
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                      <IconButton onClick={() => handleEditEvent(event)} color="primary">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <IconButton onClick={() => handleDeleteEvent(event.id)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EventForm
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        projectId={projectId}
        onEventCreated={handleEventCreated}
        event={selectedEventForEdit}
      />
      <EventDetails
        eventId={selectedEventIdForDetails}
        open={isEventDetailsOpen}
        onClose={handleCloseEventDetails}
        onEdit={handleEditEvent}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Events;