import React from 'react';

const ProfessionalInvoiceTemplate = ({ invoice }) => {
  const formatDate = (date) => {
    return date instanceof Date ? date.toLocaleDateString() : 'N/A';
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTax = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '40px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#fff', boxShadow: '0 0 20px rgba(0,0,0,0.1)' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px', borderBottom: '2px solid #3498db', paddingBottom: '20px' }}>
        <div>
          <h1 style={{ color: '#3498db', margin: '0', fontSize: '36px' }}>Invoice</h1>
          <p style={{ margin: '5px 0', color: '#7f8c8d', fontSize: '18px' }}>#{invoice.invoiceNumber}</p>
        </div>
        {invoice.logo && (
          <img src={invoice.logo} alt="Company Logo" style={{ maxWidth: '150px', maxHeight: '80px' }} />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
        <div style={{ flex: 1 }}>
          <h2 style={{ color: '#3498db', fontSize: '20px', marginBottom: '15px' }}>From</h2>
          <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '16px' }}>{invoice.businessDetails.company_name}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Owner: {invoice.businessDetails.owner_name}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.businessDetails.address}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.businessDetails.city}, {invoice.businessDetails.state} {invoice.businessDetails.zip_code}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Phone: {invoice.businessDetails.phone}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Email: {invoice.businessDetails.email}</p>
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <h2 style={{ color: '#3498db', fontSize: '20px', marginBottom: '15px' }}>To</h2>
          <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '16px' }}>{invoice.customerDetails.name}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.customerDetails.address}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.customerDetails.city}, {invoice.customerDetails.state} {invoice.customerDetails.zipCode}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Phone: {invoice.customerDetails.phone}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Email: {invoice.customerDetails.email}</p>
        </div>
      </div>

      <div style={{ backgroundColor: '#f9f9f9', padding: '30px', borderRadius: '5px', marginBottom: '40px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '2px solid #3498db' }}>
              <th style={{ textAlign: 'left', padding: '12px', color: '#3498db' }}>Description</th>
              <th style={{ textAlign: 'right', padding: '12px', color: '#3498db' }}>Quantity</th>
              <th style={{ textAlign: 'right', padding: '12px', color: '#3498db' }}>Unit Price</th>
              <th style={{ textAlign: 'right', padding: '12px', color: '#3498db' }}>Tax (%)</th>
              <th style={{ textAlign: 'right', padding: '12px', color: '#3498db' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoice.items.map((item, index) => (
              <tr key={index} style={{ borderBottom: '1px solid #ecf0f1' }}>
                <td style={{ padding: '12px' }}>{item.description}</td>
                <td style={{ textAlign: 'right', padding: '12px' }}>{item.quantity}</td>
                <td style={{ textAlign: 'right', padding: '12px' }}>${item.unitPrice.toFixed(2)}</td>
                <td style={{ textAlign: 'right', padding: '12px' }}>{item.tax}%</td>
                <td style={{ textAlign: 'right', padding: '12px' }}>${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ backgroundColor: '#f9f9f9', padding: '30px', borderRadius: '5px', textAlign: 'right' }}>
        <p style={{ margin: '5px 0', fontSize: '16px' }}>Subtotal: ${calculateSubtotal().toFixed(2)}</p>
        <p style={{ margin: '5px 0', fontSize: '16px' }}>Tax: ${calculateTax().toFixed(2)}</p>
        <p style={{ margin: '15px 0', fontWeight: 'bold', fontSize: '24px', color: '#3498db' }}>Total: ${calculateTotal().toFixed(2)}</p>
      </div>

      {invoice.includeNotes && invoice.notes && (
        <div style={{ marginTop: '40px' }}>
          <h2 style={{ color: '#3498db', fontSize: '20px', marginBottom: '15px' }}>Notes</h2>
          <p style={{ fontSize: '14px', lineHeight: '1.6' }}>{invoice.notes}</p>
        </div>
      )}

      {invoice.includeTerms && (
        <div style={{ marginTop: '40px' }}>
          <h2 style={{ color: '#3498db', fontSize: '20px', marginBottom: '15px' }}>Terms and Conditions</h2>
          <p style={{ fontSize: '14px', lineHeight: '1.6' }}>{invoice.termsAndConditions || 'No terms and conditions specified.'}</p>
        </div>
      )}

      <div style={{ marginTop: '40px', fontSize: '14px', color: '#7f8c8d', textAlign: 'center' }}>
        <p>Thank you for your business!</p>
        <p>Invoice generated on {formatDate(new Date())}</p>
      </div>
    </div>
  );
};

export default ProfessionalInvoiceTemplate;
