import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, Button, Tabs, Tab, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/Payments';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import styles from './AdminSettings.module.scss';

function AdminSettingsMyMoney() {
  const [tabValue, setTabValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [processorInfo, setProcessorInfo] = useState({
    processorName: '',
    accountNumber: '',
    routingNumber: '',
    accountType: '',
  });

  const balance = 5000;
  const earnings = 1200;
  const pendingPayouts = 800;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProcessorInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Here you would typically send the processorInfo to your backend
    console.log('Submitting processor info:', processorInfo);
    handleCloseModal();
  };

  const handleApplyForPOS = () => {
    console.log('Applying for Point of Sale system');
    // Implement the logic for applying for a POS system here
  };

  const TotalsTab = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Box className={styles.infoItem}>
          <div className={`${styles.iconWrapper} ${styles.balanceIcon}`}>
            <MonetizationOnIcon className={styles.infoIcon} />
          </div>
          <Box>
            <Typography variant="subtitle2" className={styles.infoLabel}>Current Balance</Typography>
            <Typography variant="h4">${balance.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box className={styles.infoItem}>
          <div className={`${styles.iconWrapper} ${styles.earningsIcon}`}>
            <TrendingUpIcon className={styles.infoIcon} />
          </div>
          <Box>
            <Typography variant="subtitle2" className={styles.infoLabel}>This Month's Earnings</Typography>
            <Typography variant="h4">${earnings.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box className={styles.infoItem}>
          <div className={`${styles.iconWrapper} ${styles.pendingIcon}`}>
            <ReceiptIcon className={styles.infoIcon} />
          </div>
          <Box>
            <Typography variant="subtitle2" className={styles.infoLabel}>Pending Payouts</Typography>
            <Typography variant="h4">${pendingPayouts.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AccountBalanceWalletIcon />}
          fullWidth
        >
          Withdraw Funds
        </Button>
      </Grid>
    </Grid>
  );

  const ProcessorTab = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Payment Processor Information</Typography>
        <Typography variant="body1" gutterBottom>
          Configure your payment processor settings and view transaction details here.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PaymentsIcon />}
          onClick={handleOpenModal}
        >
          Set Up Payment Processor
        </Button>
      </Grid>
    </Grid>
  );

  const PointOfSaleTab = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Point of Sale System</Typography>
        <Typography variant="body1" gutterBottom>
          Apply for our integrated Point of Sale system to streamline your business operations.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PointOfSaleIcon />}
          onClick={handleApplyForPOS}
        >
          Apply for Point of Sale System
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Paper className={styles.tabPanel}>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h5" component="h2" className={styles.sectionTitle}>
          <AccountBalanceWalletIcon className={styles.headerIcon} />
          My Money
        </Typography>
      </Box>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="my money tabs" className={styles.subTabs}>
        <Tab label="Totals" icon={<MonetizationOnIcon />} />
        <Tab label="Processor" icon={<PaymentsIcon />} />
        <Tab label="Point of Sale" icon={<PointOfSaleIcon />} />
      </Tabs>
      <Box mt={3}>
        {tabValue === 0 && <TotalsTab />}
        {tabValue === 1 && <ProcessorTab />}
        {tabValue === 2 && <PointOfSaleTab />}
      </Box>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Set Up Payment Processor</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="processorName"
            label="Processor Name"
            type="text"
            fullWidth
            value={processorInfo.processorName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            value={processorInfo.accountNumber}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="routingNumber"
            label="Routing Number"
            type="text"
            fullWidth
            value={processorInfo.routingNumber}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Account Type</InputLabel>
            <Select
              name="accountType"
              value={processorInfo.accountType}
              onChange={handleInputChange}
            >
              <MenuItem value="checking">Checking</MenuItem>
              <MenuItem value="savings">Savings</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default AdminSettingsMyMoney;
