import React from 'react';

const formatTimestamp = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

const ModernTemplate = ({ estimate }) => {
  const formatDate = (date) => {
    if (!date) return 'N/A';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'N/A';
  };

  const calculateSubtotal = () => {
    return estimate?.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0) || 0;
  };

  const calculateTax = () => {
    return estimate?.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0) || 0;
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div className="modern-template" style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '20px', backgroundColor: '#f9f9f9' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', backgroundColor: '#fff', padding: '20px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
        <div>
          <h1 style={{ margin: 0 }}>Estimate</h1>
          <p style={{ margin: '5px 0' }}>Estimate Number: {estimate?.estimate_number || 'N/A'}</p>
          <p style={{ margin: '5px 0' }}>Issue Date: {formatDate(estimate?.issue_date)}</p>
          <p style={{ margin: '5px 0' }}>Valid Until: {formatDate(estimate?.expiration_date)}</p>
        </div>
        {estimate?.logo && (
          <img 
            src={`/static/project_files/${estimate.logo}`} 
            alt="Company Logo" 
            style={{ maxWidth: '150px', maxHeight: '150px' }} 
          />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', flex: 1, marginRight: '10px' }}>
          <h3 style={{ color: '#2196f3' }}>From:</h3>
          <p>{estimate?.businessDetails?.company_name}</p>
          <p>{estimate?.businessDetails?.name}</p>
          <p>{estimate?.businessDetails?.address}</p>
          <p>{estimate?.businessDetails?.city}, {estimate?.businessDetails?.state} {estimate?.businessDetails?.zipCode}</p>
          <p>Phone: {estimate?.businessDetails?.phone}</p>
          <p>Email: {estimate?.businessDetails?.email}</p>
        </div>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', flex: 1, marginLeft: '10px' }}>
          <h3 style={{ color: '#2196f3' }}>To:</h3>
          <p>{estimate?.customerDetails?.companyName}</p>
          <p>{estimate?.customerDetails?.customerName}</p>
          <p>{estimate?.customerDetails?.address}</p>
          <p>{estimate?.customerDetails?.city}, {estimate?.customerDetails?.state} {estimate?.customerDetails?.zipCode}</p>
          <p>Phone: {estimate?.customerDetails?.phone}</p>
          <p>Email: {estimate?.customerDetails?.email}</p>
        </div>
      </div>

      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
        <h3 style={{ color: '#2196f3' }}>Items</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#2196f3', color: 'white' }}>
              <th style={{ padding: '10px', textAlign: 'left' }}>Description</th>
              <th style={{ padding: '10px', textAlign: 'right' }}>Quantity</th>
              <th style={{ padding: '10px', textAlign: 'right' }}>Unit Price</th>
              <th style={{ padding: '10px', textAlign: 'right' }}>Tax (%)</th>
              <th style={{ padding: '10px', textAlign: 'right' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {estimate.items.map((item, index) => (
              <tr key={index} style={{ borderBottom: '1px solid #eee' }}>
                <td style={{ padding: '10px' }}>{item.description}</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>{item.quantity}</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>${item.unitPrice.toFixed(2)}</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>{item.tax}%</td>
                <td style={{ padding: '10px', textAlign: 'right' }}>${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ marginTop: '20px', textAlign: 'right' }}>
          <p><strong>Subtotal:</strong> ${calculateSubtotal().toFixed(2)}</p>
          <p><strong>Tax:</strong> ${calculateTax().toFixed(2)}</p>
          <p style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#2196f3' }}><strong>Total:</strong> ${calculateTotal().toFixed(2)}</p>
        </div>
      </div>

      {estimate.showAdditionalInfo && estimate.scopeOfWork && (
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
          <h3 style={{ color: '#2196f3' }}>Scope of Work</h3>
          <div dangerouslySetInnerHTML={{ __html: estimate.scopeOfWork }} />
        </div>
      )}

      {estimate.showNotes && (
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
          <h3 style={{ color: '#2196f3' }}>Notes</h3>
          <p>{estimate.notes || 'No additional notes.'}</p>
        </div>
      )}

      {estimate.projectImages && estimate.projectImages.length > 0 && (
        <div style={{ 
          backgroundColor: 'white', 
          padding: '20px', 
          borderRadius: '5px', 
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          marginTop: '2rem',
          pageBreakBefore: 'always'
        }}>
          <h3 style={{ color: '#2196f3' }}>Project Photos</h3>
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(3, 1fr)', // Change to 3 columns for smaller images
            gap: '1rem', 
            marginTop: '1rem' 
          }}>
            {estimate.projectImages.map((image, index) => (
              <div key={index} style={{ 
                borderRadius: '5px',
                overflow: 'hidden',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
              }}>
                <img 
                  src={image.url} 
                  alt={`${index + 1}`}
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    objectFit: 'cover',
                    display: 'block'
                  }} 
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '45%', borderTop: '2px solid #000', paddingTop: '10px' }}>
          {estimate?.client_signature ? (
            <>
              <p>Customer Signature:</p>
              <img 
                src={estimate.client_signature} 
                alt="Client Signature" 
                style={{ 
                  maxWidth: '200px', 
                  marginBottom: '10px'
                }} 
              />
              <p>Signed: {formatTimestamp(estimate.client_signed_at)}</p>
            </>
          ) : (
            <>
              <p style={{ fontFamily: 'Dancing Script', fontSize: '24px', fontWeight: 700 }}>
                Customer Signature: _______________________
              </p>
              <p>Date: _______________</p>
            </>
          )}
        </div>
        <div style={{ width: '45%', borderTop: '2px solid #000', paddingTop: '10px' }}>
          {estimate?.admin_signature ? (
            <>
              <p>Company Representative:</p>
              <img 
                src={estimate.admin_signature} 
                alt="Admin Signature" 
                style={{ 
                  maxWidth: '200px', 
                  marginBottom: '10px'
                }} 
              />
              <p>Signed: {formatTimestamp(estimate.admin_signed_at)}</p>
            </>
          ) : (
            <>
              <p style={{ fontFamily: 'Dancing Script', fontSize: '24px', fontWeight: 700 }}>
                Company Representative: _______________________
              </p>
              <p>Date: _______________</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModernTemplate;
