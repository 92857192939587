import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getProjectPhotos, uploadProjectPhoto } from '../services/api';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectPhotos.module.scss';

const CameraIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
    <circle cx="12" cy="13" r="4"></circle>
  </svg>
);

const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="17 8 12 3 7 8"></polyline>
    <line x1="12" y1="3" x2="12" y2="15"></line>
  </svg>
);

const ProjectPhotos = ({ projectId }) => {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchPhotos();
  }, [projectId]);

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      const response = await getProjectPhotos(projectId);
      setPhotos(response.photos);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching project photos:', err);
      setError('Failed to load photos. Please try again.');
      setLoading(false);
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handlePhotoUpload = async () => {
    for (const file of selectedFiles) {
      try {
        const formData = new FormData();
        formData.append('photo', file);
        
        await uploadProjectPhoto(projectId, formData, (progress) => {
          setUploadProgress(prev => ({...prev, [file.name]: progress}));
        });
      } catch (err) {
        console.error('Error uploading photo:', err);
        setError(`Failed to upload ${file.name}. Please try again.`);
      }
    }
    setSelectedFiles([]);
    setUploadProgress({});
    fetchPhotos();
  };

  const removeSelectedFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const openLightbox = (index) => {
    setCurrentPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setIsZoomed(false);
  };

  const nextPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const prevPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!lightboxOpen) return;
      switch (e.key) {
        case 'ArrowRight':
          nextPhoto();
          break;
        case 'ArrowLeft':
          prevPhoto();
          break;
        case 'Escape':
          closeLightbox();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [lightboxOpen, nextPhoto, prevPhoto]);

  if (loading) return <div>Loading photos...</div>;
  if (error) return <div>{error}</div>;

  const formatDateTime = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const handleOpenCamera = () => {
    navigate(`/projects/${projectId}/camera`);
  };

  const handleUploadClick = (e) => {
    console.log('Upload button clicked');  // Debug log
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDropZoneClick = (e) => {
    console.log('Drop zone clicked');  // Debug log
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div 
      className={styles.projectPhotos}
      onClick={(e) => e.preventDefault()}  // Prevent clicks at the top level
    >
      <div className={styles.header}>
        <h2>Project Photos</h2>
        <button 
          onClick={handleOpenCamera} 
          className={styles.cameraButton} 
          title="Open Camera"
          type="button"
        >
          <CameraIcon />
        </button>
      </div>
      <div 
        className={styles.uploadSection}
        onClick={(e) => e.preventDefault()}  // Prevent clicks at the section level
      >
        <div 
          className={`${styles.dropZone} ${isDragging ? styles.dragging : ''}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleDropZoneClick}  // Use dedicated handler
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*"
            multiple
            style={{ display: 'none' }}
            onClick={(e) => e.stopPropagation()}  // Prevent input click propagation
          />
          <div 
            className={styles.uploadButton}
            onClick={handleUploadClick}
            onMouseDown={(e) => e.preventDefault()}  // Prevent any mouseDown events
          >
            <UploadIcon />
          </div>
          <p onClick={(e) => e.preventDefault()}>Click to select or drag and drop photos here</p>
        </div>
      </div>
      {selectedFiles.length > 0 && (
        <div className={styles.selectedFiles}>
          <h3>Selected Files:</h3>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                <span className={styles.fileName}>{file.name}</span>
                <button onClick={() => removeSelectedFile(file.name)}>Remove</button>
                {uploadProgress[file.name] && (
                  <progress value={uploadProgress[file.name]} max="100" />
                )}
              </li>
            ))}
          </ul>
          <button onClick={handlePhotoUpload} className={styles.uploadSelectedButton}>
            Upload Selected Photos
          </button>
        </div>
      )}
      <div className={styles.photoGrid}>
        {photos.map((photo, index) => (
          <div 
            key={photo.id} 
            className={styles.photoItem}
            onClick={() => openLightbox(index)}
          >
            <img 
              src={`${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${photo.filename}`} 
              alt={`Project photo ${photo.id}`} 
              onError={(e) => {
                console.error('Image failed to load:', e.target.src);
              }}
              onLoad={() => {
                console.log('Image loaded successfully:', photo.filename);
              }}
            />
            <div className={styles.photoOverlay}>
              <div className={styles.photoName}>{photo.filename}</div>
              <div className={styles.photoDate}>
                {formatDateTime(photo.uploaded_at)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {lightboxOpen && (
        <div className={styles.lightbox} onClick={closeLightbox}>
          <button className={styles.lightboxClose} onClick={closeLightbox}>&times;</button>
          <button className={styles.lightboxPrev} onClick={(e) => { e.stopPropagation(); prevPhoto(); }}>&lt;</button>
          <button className={styles.lightboxNext} onClick={(e) => { e.stopPropagation(); nextPhoto(); }}>&gt;</button>
          <div className={`${styles.lightboxContent} ${isZoomed ? styles.zoomed : ''}`} onClick={(e) => e.stopPropagation()}>
            <img 
              src={`${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${photos[currentPhotoIndex].filename}`} 
              alt={`Project photo ${photos[currentPhotoIndex].id}`} 
              onClick={toggleZoom}
            />
            <div className={styles.lightboxCaption}>
              <div>{photos[currentPhotoIndex].filename}</div>
              <div>{new Date(photos[currentPhotoIndex].uploaded_at).toLocaleDateString()}</div>
            </div>
          </div>
          <div className={styles.lightboxCounter}>
            {currentPhotoIndex + 1} / {photos.length}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPhotos;
