import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProjectDetails, updateProjectScopeOfWork, updateProjectDetails, getCoordinatesFromAddress, shareProjectWithClient } from '../services/api';
import styles from './ProjectDetails.module.scss';
import ScheduleUpdater from './ScheduleUpdater';
import EmployeeAssignment from './EmployeeAssignment';
import { Tabs, Tab, Box, Paper, IconButton, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Pagination } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Person as PersonIcon, Description as DescriptionIcon, EventNote as EventNoteIcon, Work as WorkIcon, Group as GroupIcon, Photo as PhotoIcon, AccessTime as AccessTimeIcon, AttachMoney as AttachMoneyIcon, ArrowBack as ArrowBackIcon, LocationOn as LocationOnIcon, DateRange as DateRangeIcon, MonetizationOn as MonetizationOnIcon } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Chip } from '@mui/material';
import ProjectPhotos from './ProjectPhotos';
import Timesheets from './Timesheets';
import Financials from './Financials';
import { Map, Marker } from 'pigeon-maps';
import { blue, green, orange, red, purple, grey } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';
import { shareProject, getClients } from '../services/api';
import {
  Typography,
  CircularProgress,
  Divider,
  ListItemIcon,
  InputAdornment,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Fade } from '@mui/material';

const ProjectDetails = () => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [scopeOfWork, setScopeOfWork] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [coordinates, setCoordinates] = useState([0, 0]);
  const { id } = useParams(); // Extract 'id' from route params
  const [openShareModal, setOpenShareModal] = useState(false);
  const [clientsData, setClientsData] = useState({ clients: [], total: 0, pages: 1, current_page: 1 });
  const [selectedClients, setSelectedClients] = useState([]);
  const [shareError, setShareError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const fetchProjectDetails = useCallback(async () => {
    try {
      const response = await getProjectDetails(id);
      console.log("Fetched project details:", response.data);
      console.log("Employees in fetched data:", response.data.employees);
      setProject(response.data);
      setScopeOfWork(response.data.scope_of_work || '');
      setLoading(false);
    } catch (err) {
      console.error('Error fetching project details:', err);
      setError('Failed to fetch project details. Please try again later.');
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchProjectDetails();
  }, [fetchProjectDetails]);

  const fetchClients = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getClients(page, 20, project.id);  // Increased per_page to 20
      setClientsData(data);
      // Pre-select already assigned clients
      setSelectedClients(data.clients.filter(client => client.is_assigned).map(client => client.id));
    } catch (error) {
      console.error('Error fetching clients:', error);
      setShareError('Failed to fetch clients. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (openShareModal) {
      fetchClients();
    }
  }, [openShareModal]);

  const handleScheduleUpdate = (updatedSchedule) => {
    setProject(prevProject => ({
      ...prevProject,
      start_time: updatedSchedule.start_time,
      end_time: updatedSchedule.end_time
    }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleScopeOfWorkChange = (content) => {
    setScopeOfWork(content);
  };

  const handleSaveScopeOfWork = async () => {
    try {
      await updateProjectScopeOfWork(project.id, scopeOfWork);
      setProject(prevProject => ({
        ...prevProject,
        scope_of_work: scopeOfWork
      }));
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating scope of work:', err);
      setError('Failed to update scope of work. Please try again.');
    }
  };

  const handleEmployeeUpdate = async (updatedEmployees) => {
    console.log("Updated employees:", updatedEmployees);
    await fetchProjectDetails(); // Re-fetch project details after employee update
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedDetails({
      description: project.description,
      address: project.address,
      start_time: project.start_time,
      end_time: project.end_time,
      total_value: project.total_value,
    });
  };

  const handleSave = async () => {
    try {
      console.log('Attempting to save details:', editedDetails);
      const response = await updateProjectDetails(project.id, editedDetails);
      if (response.success) {
        // Update the local state immediately
        setProject(prevProject => ({
          ...prevProject,
          ...editedDetails,
          // Ensure dates are in the correct format
          start_time: editedDetails.start_time ? new Date(editedDetails.start_time).toISOString() : prevProject.start_time,
          end_time: editedDetails.end_time ? new Date(editedDetails.end_time).toISOString() : prevProject.end_time,
        }));
        setIsEditing(false);
        console.log('Project updated successfully:', response.project);
      } else {
        setError(`Failed to update project: ${response.message}`);
      }
    } catch (err) {
      console.error('Error updating project details:', err);
      setError(`Failed to update project details: ${err.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (project && project.address) {
      getCoordinatesFromAddress(project.address)
        .then(coords => setCoordinates(coords))
        .catch(err => console.error('Error getting coordinates:', err));
    }
  }, [project]);

  const getTabColor = (index) => {
    if (activeTab === index) {
      switch(index) {
        case 0: return blue[500];
        case 1: return green[500];
        case 2: return orange[500];
        case 3: return red[500];
        case 4: return purple[500];
        case 5: return blue[700];
        case 6: return green[700];
        default: return grey[500];
      }
    }
    return grey[500]; // default color for inactive tabs
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    setSelectedClients([]);
    setShareError(null);
  };

  const handleClientToggle = (clientId) => {
    setSelectedClients(prev =>
      prev.includes(clientId)
        ? prev.filter(id => id !== clientId)
        : [...prev, clientId]
    );
  };

  const handleShareWithClients = async () => {
    setShareError(null);
    setIsLoading(true);
    try {
      for (const clientId of selectedClients) {
        await shareProject(project.id, clientId);
      }
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleCloseShareModal();
      }, 2000);
    } catch (error) {
      console.error('Error sharing project:', error);
      setShareError(error.message || 'Failed to share project');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    fetchClients(value);
  };

  const filteredClients = clientsData.clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div className={styles.message}>Loading...</div>;
  if (error) return <div className={styles.message}>Error: {error}</div>;
  if (!project) return <div className={styles.message}>Project not found.</div>;

  console.log("Current project state:", project);
  console.log("Employees in current project state:", project?.employees);

  return (
    <div className={styles.projectDetails}>
      <Link to="/admin/projects" className={styles.backLink}>
        <ArrowBackIcon /> Back to Projects
      </Link>
      <h1 className={styles.title} style={{ marginBottom: '10px' }}>{project.name || 'Unnamed Project'}</h1>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="project tabs">
          <Tab icon={<DescriptionIcon sx={{ color: getTabColor(0) }} />} label="Details" />
          <Tab icon={<EventNoteIcon sx={{ color: getTabColor(1) }} />} label="Schedule" />
          <Tab icon={<WorkIcon sx={{ color: getTabColor(2) }} />} label="Scope of Work" />
          <Tab icon={<GroupIcon sx={{ color: getTabColor(3) }} />} label="Assign Employees" />
          <Tab icon={<PhotoIcon sx={{ color: getTabColor(4) }} />} label="Photos" />
          <Tab icon={<AccessTimeIcon sx={{ color: getTabColor(5) }} />} label="Timesheets" />
          <Tab icon={<AttachMoneyIcon sx={{ color: getTabColor(6) }} />} label="Financials" />
        </Tabs>
      </Box>
      <Fade in={activeTab === 0} timeout={500}>
        <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <div className={styles.content}>
            <div className={styles.mapContainer}>
              <Map 
                height={400} // Reduced height
                center={coordinates} 
                zoom={17} // Increased zoom level
              >
                <Marker width={50} anchor={coordinates} />
              </Map>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.detailsContainer}>
                {!isEditing && (
                  <IconButton
                    onClick={handleEdit}
                    color="primary"
                    aria-label="edit project"
                    className={styles.editButton}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ShareIcon />}
                  onClick={handleOpenShareModal}
                  className={styles.shareButton}
                >
                  Share with Clients
                </Button>
                {isEditing ? (
                  <div className={styles.editForm}>
                    <TextField
                      fullWidth
                      margin="normal"
                      name="description"
                      label="Description"
                      multiline
                      rows={4}
                      value={editedDetails.description}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      name="address"
                      label="Address"
                      value={editedDetails.address}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      name="start_time"
                      label="Start Date"
                      type="date"
                      value={editedDetails.start_time?.split('T')[0]}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      name="end_time"
                      label="End Date"
                      type="date"
                      value={editedDetails.end_time?.split('T')[0]}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      name="total_value"
                      label="Total Value"
                      type="number"
                      value={editedDetails.total_value}
                      onChange={handleInputChange}
                    />
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleSave}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                    <Button 
                      variant="outlined" 
                      onClick={() => setIsEditing(false)}
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className={styles.projectInfo}>
                    <p><DescriptionIcon sx={{ color: blue[500] }} /> <strong>Description:</strong> {project.description || 'No description available'}</p>
                    <p><LocationOnIcon sx={{ color: red[500] }} /> <strong>Address:</strong> {project.address || 'N/A'}</p>
                    <p><DateRangeIcon sx={{ color: purple[500] }} /> <strong>Start Date:</strong> {project.start_time ? new Date(project.start_time).toLocaleDateString() : 'Not set'}</p>
                    <p><DateRangeIcon sx={{ color: purple[500] }} /> <strong>End Date:</strong> {project.end_time ? new Date(project.end_time).toLocaleDateString() : 'Not set'}</p>
                    <p><WorkIcon sx={{ color: orange[500] }} /> <strong>Status:</strong> {project.active ? 'Active' : 'Inactive'}</p>
                    <p><MonetizationOnIcon sx={{ color: green[500] }} /> <strong>Total Value:</strong> ${project.total_value?.toLocaleString() || 'N/A'}</p>
                  </div>
                )}
              </div>
              <div className={styles.employees}>
                <h2><GroupIcon sx={{ color: blue[700] }} /> Employees</h2>
                {project.employees && project.employees.length > 0 ? (
                  <List>
                    {project.employees.map(employee => (
                      <ListItem key={employee.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={employee.name}
                          secondary={
                            <>
                              {employee.position}
                              {employee.payment_type && (
                                <Chip
                                  label={`${employee.payment_type}: ${employee.payment_type === 'percentage' ? employee.percentage + '%' : '$' + employee.rate}`}
                                  size="small"
                                  color="primary"
                                  style={{ marginLeft: '10px' }}
                                />
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <p>No employees assigned to this project.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 1} timeout={500}>
        <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
          <div className={styles.scheduleUpdate}>
            <ScheduleUpdater
              projectId={project.id}
              currentStartDate={project.start_time}
              currentEndDate={project.end_time}
              onUpdate={handleScheduleUpdate}
            />
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 2} timeout={500}>
        <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
          <div className={styles.scopeOfWork}>
            <Paper elevation={3} className={styles.scopeOfWorkPaper}>
              <div className={styles.scopeOfWorkHeader}>
                <h2>Scope of Work</h2>
                {!isEditing && (
                  <IconButton
                    onClick={() => setIsEditing(true)}
                    color="primary"
                    aria-label="edit scope of work"
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
              {isEditing ? (
                <>
                  <ReactQuill 
                    value={scopeOfWork} 
                    onChange={handleScopeOfWorkChange}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['link', 'image'],
                        ['clean']
                      ],
                    }}
                    className={styles.quillEditor}
                  />
                  <div className={styles.buttonGroup}>
                    <Button 
                      onClick={handleSaveScopeOfWork} 
                      variant="contained" 
                      color="primary" 
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                    <Button 
                      onClick={() => setIsEditing(false)} 
                      variant="outlined" 
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <div 
                  className={styles.scopeOfWorkContent}
                  dangerouslySetInnerHTML={{ __html: scopeOfWork || 'No scope of work defined for this project.' }} 
                />
              )}
            </Paper>
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 3} timeout={500}>
        <div style={{ display: activeTab === 3 ? 'block' : 'none' }}>
          <div className={styles.employeeAssignment}>
            <EmployeeAssignment
              projectId={project.id}
              currentEmployees={project.employees || []}
              onUpdate={handleEmployeeUpdate}
            />
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 4} timeout={500}>
        <div style={{ display: activeTab === 4 ? 'block' : 'none' }}>
          <div className={styles.projectPhotos}>
            <ProjectPhotos projectId={project.id} />
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 5} timeout={500}>
        <div style={{ display: activeTab === 5 ? 'block' : 'none' }}>
          <div className={styles.projectTimesheets}>
            <Timesheets projectId={project.id} />
          </div>
        </div>
      </Fade>
      <Fade in={activeTab === 6} timeout={500}>
        <div style={{ display: activeTab === 6 ? 'block' : 'none' }}>
          <div className={styles.projectFinancials}>
            <Financials projectId={project.id} />
          </div>
        </div>
      </Fade>

      <Dialog open={openShareModal} onClose={handleCloseShareModal} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
            <ShareIcon style={{ marginRight: '8px' }} />
            Share Project with Clients
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search clients..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: '1rem' }}
          />
          {isLoading ? (
            <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
          ) : clientsData.clients.length === 0 ? (
            <Typography>No clients available</Typography>
          ) : (
            <List style={{ maxHeight: '400px', overflow: 'auto' }}>
              {filteredClients.map((client) => (
                <React.Fragment key={client.id}>
                  <ListItem
                    button
                    onClick={() => handleClientToggle(client.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedClients.includes(client.id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={client.name} />
                    {client.is_assigned && (
                      <CheckCircleIcon color="primary" />
                    )}
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {shareError && (
            <Typography color="error" style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <ErrorIcon style={{ marginRight: '8px' }} />
              {shareError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleShareWithClients}
            color="primary"
            disabled={selectedClients.length === 0 || isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <CheckCircleIcon />}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      {showSuccessModal && (
        <div className={styles.successModal}>
          <Fade in={true} timeout={1000}>
            <div className={styles.successContent}>
              <CheckCircleOutlineIcon className={styles.successIcon} />
              <Typography variant="h4" className={styles.successMessage}>
                Project Shared Successfully!
              </Typography>
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
