import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { shareProject } from '../services/api';

function ShareProject() {
  const { id } = useParams();
  const [clientId, setClientId] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleShare = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const result = await shareProject(id, clientId);
      setMessage(result.message || 'Project shared successfully');
    } catch (error) {
      setError('Failed to share project: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div>
      <h2>Share Project</h2>
      <form onSubmit={handleShare}>
        <input
          type="text"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          placeholder="Enter Client ID"
          required
        />
        <button type="submit">Share</button>
      </form>
      {message && <p style={{color: 'green'}}>{message}</p>}
      {error && <p style={{color: 'red'}}>{error}</p>}
    </div>
  );
}

export default ShareProject;
