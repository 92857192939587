import React, { useState, useEffect } from 'react';
import { getAdminDashboard } from '../services/api';
import styles from './AdminDashboard.module.scss';
import { motion } from 'framer-motion';
import AdminDashInvoices from './AdminDashInvoices';

function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await getAdminDashboard();
        setDashboardData(response.data);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        setError('Failed to load dashboard data. Please try again later.');
      }
    };

    fetchDashboardData();
  }, []);

  if (error) return <div className={styles.error}>Error: {error}</div>;
  if (!dashboardData) return <div className={styles.loading}>Loading...</div>;

  return (
    <div className={styles.dashboard}>
      <motion.div 
        className={styles.focusedCard}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <AdminDashInvoices />
      </motion.div>
      
      <style jsx>{`
        .form-group {
          margin-bottom: 1.5rem;
          position: relative;
        }
        
        .form-group label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: bold;
        }
        
        .form-group input,
        .form-group textarea,
        .form-group select {
          width: 100%;
          padding: 0.75rem 0.5rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .form-group input:focus,
        .form-group textarea:focus,
        .form-group select:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 2px rgba(0,123,255,.25);
        }

        .form-group .error-message {
          color: #dc3545;
          font-size: 0.875rem;
          margin-top: 0.25rem;
        }
      `}</style>
    </div>
  );
}

export default AdminDashboard;
