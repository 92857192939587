import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchClientProjectDetails, getProjectEvents, getProjectPhotos, getProjectDetails } from '../services/api';
import styles from './ClientProjectDetails.module.scss';
import { Tabs, Tab, Box, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Paper, Tooltip, TextField, InputAdornment, IconButton, Menu, MenuItem, Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import PhotoIcon from '@mui/icons-material/Photo';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProjectStatusChart from './ProjectStatusChart';
import ProjectAgenda from './ProjectAgenda';
import HistoryIcon from '@mui/icons-material/History';
import { CSSTransition } from 'react-transition-group';

const avatarColors = ['#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b', '#0288d1', '#00796b', '#fbc02d', '#f57c00', '#455a64'];

const dummyDocuments = [
  { id: 1, name: 'Project Proposal', type: 'pdf', size: '2.5 MB', lastModified: '2023-05-15' },
  { id: 2, name: 'Contract', type: 'docx', size: '1.8 MB', lastModified: '2023-05-20' },
  { id: 3, name: 'Budget Spreadsheet', type: 'xlsx', size: '3.2 MB', lastModified: '2023-05-22' },
  { id: 4, name: 'Site Photos', type: 'jpg', size: '5.7 MB', lastModified: '2023-05-25' },
  { id: 5, name: 'Progress Report', type: 'pdf', size: '1.5 MB', lastModified: '2023-05-28' },
];

const ClientProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [projectPhotos, setProjectPhotos] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [documentSearchTerm, setDocumentSearchTerm] = useState('');
  const [documentSortBy, setDocumentSortBy] = useState('name');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProjectDetails = async () => {
      try {
        const projectData = await getProjectDetails(projectId);
        console.log('API Response for project details:', projectData.data); // Add this line
        setProject(projectData.data);
        const eventsData = await getProjectEvents(projectId);
        setEvents(eventsData);
        setLoading(false);
      } catch (err) {
        console.error('Error loading project details:', err);
        setError('Failed to load project details');
        setLoading(false);
      }
    };
    loadProjectDetails();
  }, [projectId]);

  useEffect(() => {
    const loadProjectPhotos = async () => {
      if (activeTab === 2) {
        console.log('Fetching project photos for project ID:', projectId);
        try {
          const photosData = await getProjectPhotos(projectId);
          console.log('Received project photos:', photosData);
          setProjectPhotos(photosData.photos);
        } catch (err) {
          console.error('Error loading project photos:', err);
        }
      }
    };
    loadProjectPhotos();
  }, [projectId, activeTab]);

  useEffect(() => {
    // Trigger fade-in effect when component mounts
    setFadeIn(true);
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    return moment(dateString).format('MMMM D, YYYY h:mm A');
  };

  const handleTabChange = (event, newValue) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveTab(newValue);
      setFadeIn(true);
    }, 500); // Increased delay to match the longer fade duration
  };

  const openLightbox = (index) => {
    setCurrentPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextPhoto = () => {
    setIsChanging(true);
    setTimeout(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % projectPhotos.length);
      setIsChanging(false);
    }, 150);
  };

  const prevPhoto = () => {
    setIsChanging(true);
    setTimeout(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + projectPhotos.length) % projectPhotos.length);
      setIsChanging(false);
    }, 150);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortSelect = (sortOption) => {
    setDocumentSortBy(sortOption);
    handleSortClose();
  };

  const handleViewDocument = (documentUrl) => {
    window.open(documentUrl, '_blank');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!project) return <div>No project data available</div>;

  return (
    <div className={styles.projectDetails}>
      {loading ? (
        <Typography>Loading project details...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <button onClick={handleGoBack} className={styles.backButton}>
            <ArrowBackIcon className={styles.backIcon} />
            Back
          </button>
          <Typography variant="h4" gutterBottom className={styles.projectTitle}>
            <FolderIcon className={styles.projectTitleIcon} />
            {project.name}
          </Typography>
          <Tabs value={activeTab} onChange={handleTabChange} className={styles.tabs}>
            <Tab label="Details" icon={<DescriptionIcon />} />
            <Tab label="Agenda" icon={<EventIcon />} />
            <Tab label="Pictures" icon={<PhotoIcon />} />
            <Tab label="Documents" icon={<FolderIcon />} />
          </Tabs>
          <CSSTransition
            in={fadeIn}
            timeout={500} // Increased timeout to 500ms
            classNames={{
              enter: styles.fadeEnter,
              enterActive: styles.fadeEnterActive,
              exit: styles.fadeExit,
              exitActive: styles.fadeExitActive,
            }}
          >
            <div>
              {activeTab === 0 && (
                <Card className={styles.detailsCard}>
                  <CardContent>
                    <div className={styles.detailsContainer}>
                      <div className={styles.detailItem}>
                        <div className={`${styles.detailIconWrapper} ${styles.descriptionIcon}`}>
                          <DescriptionIcon className={styles.detailIcon} />
                        </div>
                        <div className={styles.detailContent}>
                          <Typography variant="subtitle2" className={styles.detailLabel}>Description</Typography>
                          <Typography variant="body2" className={styles.detailValue}>{project.description || 'No description available'}</Typography>
                        </div>
                      </div>
                      <div className={styles.detailItem}>
                        <div className={`${styles.detailIconWrapper} ${styles.calendarIcon}`}>
                          <CalendarTodayIcon className={styles.detailIcon} />
                        </div>
                        <div className={styles.detailContent}>
                          <Typography variant="subtitle2" className={styles.detailLabel}>Start Date</Typography>
                          <Typography variant="body2" className={styles.detailValue}>{formatDate(project.start_time)}</Typography>
                        </div>
                      </div>
                      <div className={styles.detailItem}>
                        <div className={`${styles.detailIconWrapper} ${styles.eventIcon}`}>
                          <EventIcon className={styles.detailIcon} />
                        </div>
                        <div className={styles.detailContent}>
                          <Typography variant="subtitle2" className={styles.detailLabel}>End Date</Typography>
                          <Typography variant="body2" className={styles.detailValue}>{formatDate(project.end_time)}</Typography>
                        </div>
                      </div>
                      <div className={styles.detailItem}>
                        <div className={`${styles.detailIconWrapper} ${styles.valueIcon}`}>
                          <AttachMoneyIcon className={styles.detailIcon} />
                        </div>
                        <div className={styles.detailContent}>
                          <Typography variant="subtitle2" className={styles.detailLabel}>Project Value</Typography>
                          <Typography variant="body2" className={styles.detailValue}>
                            ${project.total_value ? project.total_value.toLocaleString() : 'N/A'}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}

              {activeTab === 1 && (
                <div className={styles.eventsTab}>
                  <Typography variant="h5" gutterBottom className={styles.eventsHeader}>
                    <HistoryIcon className={styles.historyIcon} />
                    Agenda
                  </Typography>
                  <div className={styles.eventListContainer}>
                    {events.length > 0 ? (
                      <List className={styles.eventList}>
                        {events.map(event => (
                          <Paper key={event.id} className={styles.eventItem} elevation={2}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar className={styles.eventAvatar}>{event.emoji}</Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h6" className={styles.eventTitle}>
                                    {event.title}
                                  </Typography>
                                }
                                secondary={
                                  <React.Fragment>
                                    <div className={styles.eventDetailsRow}>
                                      <div className={styles.eventDetail}>
                                        <AccessTimeIcon className={`${styles.eventIcon} ${styles.timeIcon}`} />
                                        <Typography variant="body2">
                                          {formatDate(event.time_slots[0].start_time)} - {moment(event.time_slots[0].end_time).format('h:mm A')}
                                        </Typography>
                                      </div>
                                      <div className={styles.eventDetail}>
                                        <LocationOnIcon className={`${styles.eventIcon} ${styles.locationIcon}`} />
                                        <Typography variant="body2">
                                          {event.addresses[0]}
                                        </Typography>
                                      </div>
                                      <div className={styles.eventDetail}>
                                        <AttachMoneyIcon className={`${styles.eventIcon} ${styles.valueIcon}`} />
                                        <Typography variant="body2">
                                          Value: {event.value ? `$${event.value.toLocaleString()}` : 'N/A'}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className={styles.employeesContainer}>
                                      <div className={styles.avatarGroup}>
                                        {event.employees.map((employee, index) => (
                                          <Tooltip key={employee.id} title={employee.name} arrow>
                                            <Avatar 
                                              className={styles.employeeAvatar}
                                              style={{ 
                                                backgroundColor: avatarColors[index % avatarColors.length]
                                              }}
                                            >
                                              {employee.name.charAt(0)}
                                            </Avatar>
                                          </Tooltip>
                                        ))}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          </Paper>
                        ))}
                      </List>
                    ) : (
                      <Typography>No events found for this project.</Typography>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className={styles.picturesTab}>
                  <Typography variant="h5" gutterBottom className={styles.picturesHeader}>
                    <PhotoIcon className={styles.picturesIcon} />
                    Project Pictures
                  </Typography>
                  {projectPhotos && projectPhotos.length > 0 ? (
                    <div className={styles.pictureGrid}>
                      {projectPhotos.map((photo, index) => (
                        <div key={photo.id || index} className={styles.projectPicture}>
                          <img 
                            src={photo.url}
                            alt={`Project picture ${index + 1}`}
                            onClick={() => openLightbox(index)}
                          />
                          <div className={styles.photoTimestamp}>
                            {moment(photo.uploaded_at).format('MMM D, YYYY')}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Typography>No pictures available for this project</Typography>
                  )}
                  {lightboxOpen && (
                    <div className={`${styles.lightbox} ${styles.fadeIn}`} onClick={closeLightbox}>
                      <div 
                        className={`${styles.lightboxContent} ${isZoomed ? styles.zoomed : ''} ${isChanging ? styles.changing : ''}`} 
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button className={styles.lightboxClose} onClick={closeLightbox}>
                          <CloseIcon />
                        </button>
                        <button className={styles.lightboxPrev} onClick={(e) => { e.stopPropagation(); prevPhoto(); }}>
                          <ArrowBackIcon />
                        </button>
                        <button className={styles.lightboxNext} onClick={(e) => { e.stopPropagation(); nextPhoto(); }}>
                          <ArrowForwardIcon />
                        </button>
                        <img 
                          src={projectPhotos[currentPhotoIndex].url} 
                          alt={`Project photo ${currentPhotoIndex + 1}`} 
                          onClick={toggleZoom}
                        />
                        <div className={styles.lightboxCaption}>
                          <div className={styles.projectInfo}>
                            <FolderIcon />
                            <p>{project.name}</p>
                          </div>
                          <div className={styles.imageInfo}>
                            <ImageIcon />
                            <p>{projectPhotos[currentPhotoIndex].filename}</p>
                          </div>
                          <div className={styles.timestampInfo}>
                            <AccessTimeIcon />
                            <p>{new Date(projectPhotos[currentPhotoIndex].uploaded_at).toLocaleString()}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 3 && (
                <div className={styles.documentsTab}>
                  <Typography variant="h5" gutterBottom className={styles.documentsHeader}>
                    <DescriptionIcon className={styles.documentsIcon} />
                    Project Documents
                  </Typography>
                  <div className={styles.documentControls}>
                    <div 
                      className={styles.searchContainer}
                      onMouseEnter={() => setIsSearchExpanded(true)}
                      onMouseLeave={() => {
                        if (!documentSearchTerm) {
                          setIsSearchExpanded(false);
                        }
                      }}
                    >
                      <input
                        type="text"
                        placeholder={isSearchExpanded ? "Search documents..." : ""}
                        value={documentSearchTerm}
                        onChange={(e) => setDocumentSearchTerm(e.target.value)}
                        className={styles.documentSearch}
                      />
                      <SearchIcon className={styles.searchIcon} />
                    </div>
                    <IconButton onClick={handleSortClick}>
                      <SortIcon />
                    </IconButton>
                    <Menu
                      anchorEl={sortAnchorEl}
                      open={Boolean(sortAnchorEl)}
                      onClose={handleSortClose}
                    >
                      <MenuItem onClick={() => handleSortSelect('name')}>Name</MenuItem>
                      <MenuItem onClick={() => handleSortSelect('type')}>Type</MenuItem>
                      <MenuItem onClick={() => handleSortSelect('lastModified')}>Last Modified</MenuItem>
                    </Menu>
                  </div>
                  {dummyDocuments.length > 0 ? (
                    <List className={styles.documentList}>
                      {dummyDocuments
                        .filter(doc => doc.name.toLowerCase().includes(documentSearchTerm.toLowerCase()))
                        .sort((a, b) => {
                          switch (documentSortBy) {
                            case 'name':
                              return a.name.localeCompare(b.name);
                            case 'type':
                              return a.type.localeCompare(b.type);
                            case 'lastModified':
                              return new Date(b.lastModified) - new Date(a.lastModified);
                            default:
                              return 0;
                          }
                        })
                        .map((doc) => (
                          <ListItem key={doc.id} className={styles.documentItem}>
                            <ListItemAvatar>
                              <Avatar className={styles.documentAvatar}>
                                {doc.type === 'pdf' ? (
                                  <PictureAsPdfIcon className={styles.pdfIcon} />
                                ) : doc.type === 'docx' ? (
                                  <DescriptionIcon className={styles.docIcon} />
                                ) : doc.type === 'xlsx' ? (
                                  <InsertDriveFileIcon className={styles.xlsIcon} />
                                ) : (
                                  <InsertDriveFileIcon className={styles.fileIcon} />
                                )}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={doc.name}
                              secondary={
                                <React.Fragment>
                                  <Typography component="span" variant="body2" className={styles.documentInfo}>
                                    {doc.type.toUpperCase()} • {doc.size}
                                  </Typography>
                                  <Typography component="span" variant="body2" className={styles.documentDate}>
                                    Last modified: {new Date(doc.lastModified).toLocaleDateString()}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                            <IconButton onClick={() => handleViewDocument(doc.url)} className={styles.viewButton}>
                              <VisibilityIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                    </List>
                  ) : (
                    <Typography>No documents available for this project</Typography>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </>
      )}
    </div>
  );
};

export default ClientProjectDetails;
