import React from 'react';
import { Typography, Paper, Box, Switch, FormControlLabel } from '@mui/material';
import { useTheme } from '../ThemeContext';

const ClientSettings = () => {
  const { darkMode, toggleDarkMode } = useTheme();

  return (
    <Box sx={{ padding: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Client Settings
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={toggleDarkMode}
              name="darkMode"
              color="primary"
            />
          }
          label="Dark Mode"
        />
      </Paper>
    </Box>
  );
};

export default ClientSettings;



