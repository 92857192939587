import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import hardcodedLogo from './Updated logo (12).png';

const DefaultTemplate = ({ estimate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  console.log('Estimate received in DefaultTemplate:', estimate);
  console.log('Items received:', estimate?.items);

  if (!estimate) {
    return <div>Loading...</div>;
  }

  // Ensure items array exists and has valid data
  const safeItems = (estimate.items || []).map(item => {
    console.log('Processing item:', item); // Debug log
    return {
      description: item.description || '',
      quantity: Number(item.quantity) || 0,
      unitPrice: Number(item.unit_price || item.unitPrice) || 0,
      tax: Number(item.tax) || 0
    };
  });

  console.log('Safe items after processing:', safeItems); // Debug log

  const formatDate = (date) => {
    if (!date) return 'N/A';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'N/A';
  };

  const calculateSubtotal = () => {
    return safeItems.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTax = () => {
    return safeItems.reduce((sum, item) => 
      sum + (item.quantity * item.unitPrice * item.tax / 100), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  const formatTimestamp = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  // Helper function to format address
  const formatAddress = (details) => {
    if (!details) return null;
    
    // Handle both customer_details and customerDetails formats
    const name = details.name || details.customer_name || '';
    const company = details.company || details.customer_company || '';
    const address = details.address || details.customer_address || '';
    const city = details.city || details.customer_city || '';
    const state = details.state || details.customer_state || '';
    const zip = details.zipCode || details.zip_code || details.customer_zipCode || '';
    const phone = details.phone || details.customer_phone || '';
    const email = details.email || details.customer_email || '';
    
    return (
      <>
        {name && <p>{name}</p>}
        {company && <p>{company}</p>}
        {address && <p>{address}</p>}
        {city && <p>{city}</p>}
        {state && <p>{state}</p>}
        {zip && <p>{zip}</p>}
        {phone && <p>Phone: {phone}</p>}
        {email && <p>Email: {email}</p>}
      </>
    );
  };

  // Create array of image URLs
  const imageUrls = estimate?.projectImages?.map(image => ({
    src: typeof image === 'object'
      ? `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`
      : `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image}`
  })) || [];

  return (
    <div 
      className="default-template" 
      style={{ 
        fontFamily: 'Arial, sans-serif', 
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto', // Centers the container
        width: '100%', // Ensures full width up to max-width
        boxSizing: 'border-box' // Includes padding in width calculation
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div>
          <h1>Estimate</h1>
          <p>Estimate Number: {estimate?.estimate_number || 'N/A'}</p>
          <p>Issue Date: {formatDate(estimate?.issue_date)}</p>
          <p>Valid Until: {formatDate(estimate?.expiration_date)}</p>
        </div>
        <img 
          src={hardcodedLogo}
          alt="Company Logo" 
          style={{ 
            maxWidth: '150px', 
            maxHeight: '150px',
            objectFit: 'contain',
            marginLeft: 'auto' // Ensures logo stays right-aligned
          }} 
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <h3>From:</h3>
          {estimate?.businessDetails && (
            <>
              <p>{estimate.businessDetails.company_name}</p>
              <p>{estimate.businessDetails.address}</p>
              <p>
                {[
                  estimate.businessDetails.city,
                  estimate.businessDetails.state,
                  estimate.businessDetails.zip_code
                ].filter(Boolean).join(', ')}
              </p>
              <p>Phone: {estimate.businessDetails.phone}</p>
              <p>Email: {estimate.businessDetails.email}</p>
            </>
          )}
        </div>
        <div>
          <h3>To:</h3>
          {formatAddress(estimate?.customerDetails || estimate?.customer_details)}
        </div>
      </div>

      <h3>Items</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Description</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Quantity</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Unit Price</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Tax (%)</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {safeItems.map((item, index) => {
            const itemUnitPrice = Number(item.unitPrice) || 0;
            const itemQuantity = Number(item.quantity) || 0;
            const itemTax = Number(item.tax) || 0;
            const total = itemQuantity * itemUnitPrice * (1 + itemTax / 100);

            return (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                  {itemQuantity}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                  ${itemUnitPrice.toFixed(2)}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                  {itemTax}%
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                  ${total.toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <p><strong>Subtotal:</strong> ${calculateSubtotal().toFixed(2)}</p>
        <p><strong>Tax:</strong> ${calculateTax().toFixed(2)}</p>
        <p><strong>Total:</strong> ${calculateTotal().toFixed(2)}</p>
      </div>

      {estimate.showAdditionalInfo && (
        <>
          {estimate.scopeOfWork && (
            <div style={{ marginTop: '20px' }}>
              <h3>Scope of Work</h3>
              <div dangerouslySetInnerHTML={{ __html: estimate.scopeOfWork }} />
            </div>
          )}
          {estimate.notes && (
            <div style={{ marginTop: '20px' }}>
              <h3>Notes</h3>
              <div dangerouslySetInnerHTML={{ __html: estimate.notes }} />
            </div>
          )}
        </>
      )}

      <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '35%', borderTop: '2px solid #000', paddingTop: '10px' }}>
          {estimate?.client_signature ? (
            <>
              <p>Customer Signature:</p>
              <img 
                src={estimate.client_signature} 
                alt="Client Signature" 
                style={{ maxWidth: '200px', marginBottom: '10px' }} 
              />
              <p>Signed: {formatTimestamp(estimate.client_signed_at)}</p>
            </>
          ) : (
            <>
              <p>Customer Signature: </p>
              <p>Date: </p>
            </>
          )}
        </div>
      </div>

      {/* Project Photos Section */}
      {estimate?.projectImages && estimate.projectImages.length > 0 && (
        <div style={{ 
          marginTop: '3rem', 
          pageBreakBefore: 'always',
          borderTop: '2px solid #eee',
          paddingTop: '2rem'
        }}>
          <h3>Project Photos</h3>
          <div style={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 400px))',
            gap: '1.5rem',
            marginTop: '1.5rem',
            justifyContent: 'center',
            '@media (max-width: 768px)': {
              gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns on mobile
              gap: '1rem',
              padding: '0 10px'
            },
            '@media (max-width: 480px)': {
              gridTemplateColumns: 'repeat(2, 1fr)', // maintain 2 columns on smaller devices
              gap: '0.5rem',
              padding: '0 5px'
            }
          }}>
            {estimate.projectImages.map((image, index) => {
              const imageUrl = typeof image === 'object' 
                ? `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`
                : `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image}`;

              return (
                <div 
                  key={index} 
                  style={{ 
                    border: '1px solid #ddd', 
                    padding: '0.75rem',
                    borderRadius: '8px',
                    maxWidth: '400px',
                    maxHeight: '400px',
                    overflow: 'hidden',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                    '@media (max-width: 768px)': {
                      maxWidth: '100%',
                      maxHeight: '300px',
                      padding: '0.5rem'
                    },
                    '@media (max-width: 480px)': {
                      maxHeight: '200px',
                      padding: '0.25rem'
                    }
                  }}
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                >
                  <img 
                    src={imageUrl}
                    alt={`Project Photo ${index + 1}`}
                    style={{ 
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                      display: 'block',
                      '@media (max-width: 768px)': {
                        height: '200px'
                      },
                      '@media (max-width: 480px)': {
                        height: '150px'
                      }
                    }} 
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Lightbox */}
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        index={photoIndex}
        slides={imageUrls}
        plugins={[Zoom]}
        carousel={{
          finite: imageUrls.length <= 1
        }}
        render={{
          buttonPrev: imageUrls.length <= 1 ? () => null : undefined,
          buttonNext: imageUrls.length <= 1 ? () => null : undefined
        }}
      />
    </div>
  );
};

export default DefaultTemplate;
