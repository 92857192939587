import React, { useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Container, useTheme } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../components/AuthContext'; // Make sure this path is correct

const drawerWidthClosed = 80;
const drawerWidthOpen = 240;

const menuItems = [
  { text: 'Dashboard', icon: <DashboardIcon sx={{ color: '#4CAF50' }} />, path: '/client/dashboard' },
  { text: 'Projects', icon: <FolderIcon sx={{ color: '#2196F3' }} />, path: '/client/projects' },
  { text: 'Payments', icon: <PaymentIcon sx={{ color: '#FF5722' }} />, path: '/client/payments' },
  { text: 'Documents', icon: <DescriptionIcon sx={{ color: '#795548' }} />, path: '/client/documents' },
  { text: 'Profile', icon: <PersonIcon sx={{ color: '#607D8B' }} />, path: '/client/profile' },
];

const ClientLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', fontFamily: 'Urbanist, sans-serif' }}>
      <Box
        component="nav"
        sx={{
          width: isOpen ? drawerWidthOpen : drawerWidthClosed,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          transition: 'width 0.3s ease',
          '&:hover': {
            width: drawerWidthOpen,
          },
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1200,
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <List sx={{ flexGrow: 0 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                color: theme.palette.text.primary,
                '&.Mui-selected': {
                  backgroundColor: theme.palette.action.selected,
                },
                px: 2.5,
                py: 1.5,
              }}
            >
              <ListItemIcon sx={{ 
                minWidth: 0, 
                mr: isOpen ? 2 : 'auto', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.text.primary,
              }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.action.hover,
                    borderRadius: '50%',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  opacity: isOpen ? 1 : 0,
                  transition: 'opacity 0.3s',
                  color: theme.palette.text.primary,
                }}
                primaryTypographyProps={{
                  fontFamily: 'Urbanist, sans-serif',
                  fontWeight: 500,
                }}
              />
            </ListItem>
          ))}
          {/* Settings ListItem */}
          <ListItem
            button
            component={Link}
            to="/client/settings"
            sx={{
              color: theme.palette.text.primary,
              px: 2.5,
              py: 1.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0, 
              mr: isOpen ? 2 : 'auto', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '50%',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon sx={{ color: '#9E9E9E' }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              sx={{
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
              primaryTypographyProps={{
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 500,
              }}
            />
          </ListItem>
          {/* Logout ListItem */}
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              color: theme.palette.text.primary,
              px: 2.5,
              py: 1.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0, 
              mr: isOpen ? 2 : 'auto', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '50%',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon sx={{ color: '#F44336' }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 0.3s',
              }}
              primaryTypographyProps={{
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 500,
              }}
            />
          </ListItem>
        </List>
      </Box>
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          ml: isOpen ? `${drawerWidthOpen}px` : `${drawerWidthClosed}px`, 
          transition: 'margin-left 0.3s ease',
          width: `calc(100% - ${isOpen ? drawerWidthOpen : drawerWidthClosed}px)`,
          maxWidth: 'none',
          height: '100vh',
          overflow: 'auto',
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default ClientLayout;
