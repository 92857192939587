import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { createEvent, getEmployees } from '../services/api'; // Assume you have these API functions

const CreateEventForm = () => {
  const navigate = useNavigate();
  const { projectId } = useParams(); // Get projectId from URL
  const [event, setEvent] = useState({
    title: '',
    description: '',
    start_time: null,
    end_time: null,
    project_id: projectId,
    employees: []
  });
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getEmployees();
        setEmployees(response.data);
      } catch (error) {
        console.error('Failed to fetch employees:', error);
      }
    };
    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: value
    }));
  };

  const handleDateChange = (name) => (date) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: date
    }));
  };

  const handleEmployeeChange = (e) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      employees: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedEvent = {
        ...event,
        start_time: event.start_time.toISOString(),
        end_time: event.end_time.toISOString(),
      };
      await createEvent(formattedEvent);
      navigate(`/projects/${projectId}`); // Redirect to project details after event creation
    } catch (error) {
      console.error('Failed to create event:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        Create New Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Event Title"
              name="title"
              value={event.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={event.description}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Time"
                value={event.start_time}
                onChange={handleDateChange('start_time')}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End Time"
                value={event.end_time}
                onChange={handleDateChange('end_time')}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Assign Employees</InputLabel>
              <Select
                multiple
                value={event.employees}
                onChange={handleEmployeeChange}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={employees.find(emp => emp.id === value)?.name} />
                    ))}
                  </div>
                )}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Create Event
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateEventForm;
