import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography, TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem,
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Collapse,
  Checkbox, Snackbar, CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add as AddIcon, Delete as DeleteIcon, CloudUpload as CloudUploadIcon, Edit as EditIcon, Person as PersonIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { 
  getBusinessDetails, 
  getClients, 
  getItems, 
  getLogos, 
  uploadLogo, 
  deleteLogo, 
  createItem, 
  deleteItem 
} from '../services/api';
import LogoUploader from './LogoUploader';
import ClientSelector from './ClientSelector';
import SavedItemsManager from './SavedItemsManager';

import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createInvoice } from '../services/api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const templateOptions = [
  { value: 'default', label: 'Default', component: DefaultInvoiceTemplate },
  { value: 'modern', label: 'Modern', component: ModernInvoiceTemplate },
  { value: 'classic', label: 'Classic', component: ClassicInvoiceTemplate },
];

const AdminCreateInvoice = () => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({
    invoiceNumber: '',
    issueDate: new Date(),
    dueDate: new Date(new Date().setDate(new Date().getDate() + 30)),
    items: [],
    notes: '',
    termsAndConditions: '',
    template: 'default',
    logo: '',
    logoSize: 100,
    businessDetails: {},
    customerDetails: {
      name: '',
      company: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      phone: ''
    },
    includeNotes: false,
    includeTerms: false,
  });
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [openClientModal, setOpenClientModal] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [openSavedItemsModal, setOpenSavedItemsModal] = useState(false);
  const [savedItems, setSavedItems] = useState([]);
  const [openClientSelector, setOpenClientSelector] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [businessData, clientsData, itemsData] = await Promise.all([
          getBusinessDetails(),
          getClients(),
          getItems()
        ]);
        setInvoice(prevInvoice => ({
          ...prevInvoice,
          businessDetails: businessData,
          invoiceNumber: generateInvoiceNumber()
        }));
        setClients(clientsData);
        setSavedItems(itemsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const generateInvoiceNumber = () => {
    const date = new Date();
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `INV-${year}${month}${day}-${random}`;
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      customerDetails: {
        name: client.name,
        company: client.company,
        address: client.address,
        email: client.email,
        phone: client.phone
      }
    }));
    handleCloseClientSelector();
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      template: event.target.value
    }));
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...invoice.items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setInvoice({ ...invoice, items: updatedItems });
  };

  const addItem = () => {
    setInvoice({
      ...invoice,
      items: [...invoice.items, { description: '', quantity: 1, unitPrice: 0, tax: 0 }]
    });
  };

  const removeItem = (index) => {
    setInvoice({
      ...invoice,
      items: invoice.items.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await createInvoice(invoice);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/admin/invoices');
      }, 2000);
    } catch (error) {
      console.error('Error creating invoice:', error);
      // Handle error (e.g., show error message)
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenLogoModal = () => setOpenLogoModal(true);
  const handleCloseLogoModal = () => setOpenLogoModal(false);
  const handleOpenClientModal = () => setOpenClientModal(true);
  const handleCloseClientModal = () => setOpenClientModal(false);
  const handleOpenSavedItemsModal = () => setOpenSavedItemsModal(true);
  const handleCloseSavedItemsModal = () => setOpenSavedItemsModal(false);

  const handleLogoSelect = (logo) => {
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      logo: `/static/project_files/${logo.filename}`
    }));
    handleCloseLogoModal();
  };

  const handleCustomerDetailsChange = (field, value) => {
    setInvoice(prev => ({
      ...prev,
      customerDetails: {
        ...prev.customerDetails,
        [field]: value
      }
    }));
  };

  const calculateItemTotal = (item) => {
    const subtotal = item.quantity * item.unitPrice;
    const tax = subtotal * (item.tax / 100);
    return (subtotal + tax).toFixed(2);
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((total, item) => total + (item.quantity * item.unitPrice), 0).toFixed(2);
  };

  const calculateTotalTax = () => {
    return invoice.items.reduce((total, item) => {
      const subtotal = item.quantity * item.unitPrice;
      return total + (subtotal * (item.tax / 100));
    }, 0).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const tax = parseFloat(calculateTotalTax());
    return (subtotal + tax).toFixed(2);
  };

  const handleAddSavedItems = (selectedItems) => {
    setInvoice(prev => ({
      ...prev,
      items: [...prev.items, ...selectedItems.map(item => ({
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        tax: item.tax
      }))]
    }));
    handleCloseSavedItemsModal();
  };

  const generatePreview = useCallback(() => {
    const SelectedTemplate = templateOptions.find(t => t.value === selectedTemplate)?.component || DefaultInvoiceTemplate;
    const previewHtml = ReactDOMServer.renderToString(
      <SelectedTemplate 
        invoice={{
          ...invoice,
          logo: invoice.logo ? `${window.location.origin}${invoice.logo}` : null
        }} 
      />
    );
    setPreviewContent(previewHtml);
  }, [invoice, selectedTemplate]);

  useEffect(() => {
    generatePreview();
  }, [invoice, selectedTemplate]);

  const handleOpenClientSelector = () => setOpenClientSelector(true);
  const handleCloseClientSelector = () => setOpenClientSelector(false);

  const toggleClientDetails = () => {
    setShowClientDetails(!showClientDetails);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>Create Invoice</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenLogoModal}
                      startIcon={<CloudUploadIcon />}
                    >
                      {invoice.logo ? 'Change Logo' : 'Select Logo'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={handleOpenClientSelector}
                      startIcon={<PersonIcon />}
                    >
                      {selectedClient ? 'Change Client' : 'Select Client'}
                    </Button>
                  </Grid>
                </Grid>

                {invoice.logo && (
                  <Box sx={{ mb: 3, textAlign: 'center' }}>
                    <img 
                      src={invoice.logo} 
                      alt="Selected Logo" 
                      style={{ maxWidth: '200px', maxHeight: '100px' }} 
                    />
                  </Box>
                )}

                <Box sx={{ mb: 3 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={toggleClientDetails}
                    startIcon={<EditIcon />}
                    disabled={!selectedClient}
                  >
                    {showClientDetails ? 'Hide Client Details' : 'Edit Client Details'}
                  </Button>
                </Box>

                <Collapse in={showClientDetails}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>Client Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Client Name"
                          value={invoice.customerDetails.name || ''}
                          onChange={(e) => handleCustomerDetailsChange('name', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Company"
                          value={invoice.customerDetails.company || ''}
                          onChange={(e) => handleCustomerDetailsChange('company', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address"
                          value={invoice.customerDetails.address || ''}
                          onChange={(e) => handleCustomerDetailsChange('address', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="City"
                          value={invoice.customerDetails.city || ''}
                          onChange={(e) => handleCustomerDetailsChange('city', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="State"
                          value={invoice.customerDetails.state || ''}
                          onChange={(e) => handleCustomerDetailsChange('state', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Zip Code"
                          value={invoice.customerDetails.zipCode || ''}
                          onChange={(e) => handleCustomerDetailsChange('zipCode', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={invoice.customerDetails.email || ''}
                          onChange={(e) => handleCustomerDetailsChange('email', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          value={invoice.customerDetails.phone || ''}
                          onChange={(e) => handleCustomerDetailsChange('phone', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Invoice Number"
                      value={invoice.invoiceNumber}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Template</InputLabel>
                      <Select
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        label="Template"
                      >
                        {templateOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Issue Date"
                      value={invoice.issueDate}
                      onChange={(newValue) => setInvoice({ ...invoice, issueDate: newValue })}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Due Date"
                      value={invoice.dueDate}
                      onChange={(newValue) => setInvoice({ ...invoice, dueDate: newValue })}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Invoice Items</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Tax (%)</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice.items.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={item.description}
                                onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                type="number"
                                value={item.unitPrice}
                                onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                type="number"
                                value={item.tax}
                                onChange={(e) => handleItemChange(index, 'tax', e.target.value)}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {calculateItemTotal(item)}
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => removeItem(index)} color="secondary">
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={addItem}
                    >
                      Add Item
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleOpenSavedItemsModal}
                    >
                      Select Saved Items
                    </Button>
                  </Box>
                </Box>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Summary</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography>Subtotal: ${calculateSubtotal()}</Typography>
                      <Typography>Tax: ${calculateTotalTax()}</Typography>
                      <Typography variant="h6">Total: ${calculateTotal()}</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Additional Information</Typography>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Button
                      variant="outlined"
                      color={invoice.includeNotes ? "secondary" : "primary"}
                      onClick={() => setInvoice({ ...invoice, includeNotes: !invoice.includeNotes, notes: invoice.includeNotes ? '' : invoice.notes })}
                    >
                      {invoice.includeNotes ? "Remove Notes" : "Add Notes"}
                    </Button>
                    <Button
                      variant="outlined"
                      color={invoice.includeTerms ? "secondary" : "primary"}
                      onClick={() => setInvoice({ ...invoice, includeTerms: !invoice.includeTerms, termsAndConditions: invoice.includeTerms ? '' : invoice.termsAndConditions })}
                    >
                      {invoice.includeTerms ? "Remove Terms" : "Add Terms"}
                    </Button>
                  </Box>
                  {invoice.includeNotes && (
                    <ReactQuill
                      value={invoice.notes}
                      onChange={(value) => setInvoice({ ...invoice, notes: value })}
                      placeholder="Add notes..."
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                  {invoice.includeTerms && (
                    <ReactQuill
                      value={invoice.termsAndConditions}
                      onChange={(value) => setInvoice({ ...invoice, termsAndConditions: value })}
                      placeholder="Add terms and conditions..."
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    {isLoading ? 'Saving...' : 'Save Invoice'}
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Typography variant="h6" gutterBottom>Preview</Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                <div dangerouslySetInnerHTML={{ __html: previewContent }} />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <LogoUploader
          open={openLogoModal}
          onClose={handleCloseLogoModal}
          onSelectLogo={handleLogoSelect}
        />
        <SavedItemsManager
          open={openSavedItemsModal}
          onClose={handleCloseSavedItemsModal}
          onAddToInvoice={handleAddSavedItems}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
        />
        <ClientSelector
          open={openClientSelector}
          onClose={handleCloseClientSelector}
          onClientSelect={handleClientSelect}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSuccess}
          autoHideDuration={2000}
          onClose={() => setShowSuccess(false)}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'success.main',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '4px',
            }}
          >
            <CheckCircleOutlineIcon sx={{ marginRight: 1 }} />
            <Typography>Invoice created successfully!</Typography>
          </Box>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default AdminCreateInvoice;
