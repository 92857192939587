import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Fade,
  Button,
  CircularProgress
} from '@mui/material';
import { Estimate, BusinessDetails } from './types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PictureAsPdf as PdfIcon, Print as PrintIcon } from '@mui/icons-material';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ModernTemplate from '../../templates/ModernTemplate';
import ClassicTemplate from '../../templates/ClassicTemplate';
import { getBusinessDetails } from '../../services/api';

interface Props {
  styles: {
    paper: string;
    sectionTitle: string;
    reviewSection: string;
    imageContainer: string;
  };
  estimate: Estimate;
}

const EstimateReview: React.FC<Props> = ({ styles, estimate }) => {
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setIsLoading(true);
        const data = await getBusinessDetails();
        setBusinessDetails(data);
      } catch (err) {
        console.error('Error fetching business details:', err);
        setError('Failed to load business details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBusinessDetails();
  }, []);

  const getTemplateComponent = () => {
    switch (estimate.template) {
      case 'modern':
        return ModernTemplate;
      case 'classic':
        return ClassicTemplate;
      case 'default':
      default:
        return DefaultTemplate;
    }
  };

  const exportToPDF = async () => {
    const element = document.getElementById('estimate-content');
    if (!element) return;

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        logging: false,
        useCORS: true
      });

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      const pdf = new jsPDF({
        orientation: imgHeight > imgWidth ? 'portrait' : 'landscape',
        unit: 'mm',
        format: 'a4'
      });

      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`Estimate-${estimate.estimateNumber}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const SelectedTemplate = getTemplateComponent();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const estimateWithBusinessDetails = {
    ...estimate,
    businessDetails: businessDetails || estimate.businessDetails
  };

  return (
    <Fade in={true}>
      <Paper className={styles.paper}>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={styles.sectionTitle}>
            Review Estimate
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToPDF}
              startIcon={<PdfIcon />}
              style={{ marginRight: '10px' }}
            >
              Export PDF
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.print()}
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          </Box>
        </Box>

        <div id="estimate-content">
          <SelectedTemplate estimate={estimateWithBusinessDetails} />
        </div>
      </Paper>
    </Fade>
  );
};

export default EstimateReview;
