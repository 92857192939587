import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getEmployeeDashboard } from '../services/api';
import { Typography, Paper, CircularProgress, Tooltip } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import styles from './EmployeeSchedule.module.scss';
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const EmployeeSchedule = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const data = await getEmployeeDashboard();
      const formattedEvents = data.assignedEvents.map((event) => ({
        id: event.id,
        title: event.title,
        start: new Date(event.time_slots[0].start_time),
        end: new Date(event.time_slots[0].end_time),
        allDay: false,
      }));
      setEvents(formattedEvents);
      setError(null);
    } catch (err) {
      setError('Failed to fetch schedule. Please try again later.');
    } finally {
      setLoading(false);
      setTimeout(() => setIsLoaded(true), 100);
    }
  };

  const handleSelectEvent = (event) => {
    navigate(`/employee/events/${event.id}`);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = isSelected ? '#1565c0' : '#1976d2';
    const style = {
      backgroundColor,
      borderRadius: '4px',
      border: 'none',
      color: 'white',
      padding: '2px 4px',
      display: 'block',
      margin: '1px 0',
      fontFamily: 'Urbanist, sans-serif',
      height: 'auto',
    };
    return { style };
  };

  const EventComponent = ({ event }) => (
    <Tooltip title={event.title} arrow placement="top">
      <div className={styles.eventContainer}>
        <Typography variant="caption" className={styles.eventTitle} component="span">
          {event.title}
        </Typography>
        <WorkIcon className={styles.eventIcon} fontSize="small" />
      </div>
    </Tooltip>
  );

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  return (
    <Paper elevation={3} className={`${styles.scheduleContainer} ${isLoaded ? styles.fadeIn : ''}`}>
      <Typography variant="h4" className={styles.scheduleTitle}>
        <WorkIcon className={styles.scheduleIcon} />
        My Schedule
      </Typography>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        className={styles.calendar}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        components={{
          event: EventComponent,
        }}
        dayPropGetter={(date) => ({
          style: {
            backgroundColor: date.getDay() === 0 || date.getDay() === 6 ? '#f5f5f5' : 'white',
          },
        })}
      />
    </Paper>
  );
};

export default EmployeeSchedule;
