import React from 'react';

const ModernInvoiceTemplate = ({ invoice }) => {
  const formatDate = (date) => {
    return date instanceof Date ? date.toLocaleDateString() : 'N/A';
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTax = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div className="modern-template" style={{ fontFamily: 'Roboto, Arial, sans-serif', color: '#333', padding: '30px', backgroundColor: '#f9f9f9', maxWidth: '800px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px', backgroundColor: '#fff', padding: '30px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '8px' }}>
        <div>
          <h1 style={{ margin: 0, color: '#2196f3', fontSize: '36px' }}>Invoice</h1>
          <p style={{ margin: '5px 0', fontSize: '18px' }}>#{invoice.invoiceNumber || 'N/A'}</p>
        </div>
        {invoice.logo && (
          <img src={invoice.logo} alt="Company Logo" style={{ maxWidth: '150px', maxHeight: '150px' }} />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <div style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', flex: 1, marginRight: '15px' }}>
          <h3 style={{ color: '#2196f3', marginBottom: '15px' }}>From:</h3>
          <p style={{ margin: '5px 0', fontSize: '16px' }}><strong>{invoice.businessDetails.company_name}</strong></p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.businessDetails.owner_name}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.businessDetails.address}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.businessDetails.city}, {invoice.businessDetails.state} {invoice.businessDetails.zip_code}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Phone: {invoice.businessDetails.phone}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Email: {invoice.businessDetails.email}</p>
        </div>
        <div style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', flex: 1, marginLeft: '15px' }}>
          <h3 style={{ color: '#2196f3', marginBottom: '15px' }}>To:</h3>
          <p style={{ margin: '5px 0', fontSize: '16px' }}><strong>{invoice.customerDetails.name}</strong></p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.customerDetails.address}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>{invoice.customerDetails.city}, {invoice.customerDetails.state} {invoice.customerDetails.zipCode}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Phone: {invoice.customerDetails.phone}</p>
          <p style={{ margin: '5px 0', fontSize: '14px' }}>Email: {invoice.customerDetails.email}</p>
        </div>
      </div>

      <div style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', marginBottom: '30px' }}>
        <h3 style={{ color: '#2196f3', marginBottom: '15px' }}>Invoice Details</h3>
        <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ padding: '12px', textAlign: 'left', borderRadius: '4px 0 0 4px' }}>Description</th>
              <th style={{ padding: '12px', textAlign: 'right' }}>Quantity</th>
              <th style={{ padding: '12px', textAlign: 'right' }}>Unit Price</th>
              <th style={{ padding: '12px', textAlign: 'right' }}>Tax (%)</th>
              <th style={{ padding: '12px', textAlign: 'right', borderRadius: '0 4px 4px 0' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoice.items.map((item, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                <td style={{ padding: '12px', borderRadius: '4px 0 0 4px' }}>{item.description}</td>
                <td style={{ padding: '12px', textAlign: 'right' }}>{item.quantity}</td>
                <td style={{ padding: '12px', textAlign: 'right' }}>${item.unitPrice.toFixed(2)}</td>
                <td style={{ padding: '12px', textAlign: 'right' }}>{item.tax}%</td>
                <td style={{ padding: '12px', textAlign: 'right', borderRadius: '0 4px 4px 0' }}>${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ marginTop: '30px', textAlign: 'right', borderTop: '2px solid #f2f2f2', paddingTop: '20px' }}>
          <p style={{ margin: '10px 0', fontSize: '16px' }}><strong>Subtotal:</strong> ${calculateSubtotal().toFixed(2)}</p>
          <p style={{ margin: '10px 0', fontSize: '16px' }}><strong>Tax:</strong> ${calculateTax().toFixed(2)}</p>
          <p style={{ fontSize: '20px', fontWeight: 'bold', color: '#2196f3', marginTop: '15px' }}><strong>Total:</strong> ${calculateTotal().toFixed(2)}</p>
        </div>
      </div>

      {invoice.includeNotes && (
        <div style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', marginBottom: '30px' }}>
          <h3 style={{ color: '#2196f3', marginBottom: '15px' }}>Notes</h3>
          <p style={{ fontSize: '14px', lineHeight: '1.6' }}>{invoice.notes || 'No additional notes.'}</p>
        </div>
      )}

      {invoice.includeTerms && (
        <div style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
          <h3 style={{ color: '#2196f3', marginBottom: '15px' }}>Terms and Conditions</h3>
          <p style={{ fontSize: '14px', lineHeight: '1.6' }}>{invoice.termsAndConditions || 'No terms and conditions specified.'}</p>
        </div>
      )}

      <div style={{ marginTop: '30px', textAlign: 'center', fontSize: '14px', color: '#888' }}>
        <p>Thank you for your business!</p>
        <p>Invoice generated on {formatDate(new Date())}</p>
      </div>
    </div>
  );
};

export default ModernInvoiceTemplate;
