import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { useParams, useNavigate } from 'react-router-dom';
import DefaultTemplate from '../templates/DefaultTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SignatureText from './SignatureText';

const AdminEstimateDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    fetchEstimate();
  }, [id]);

  useEffect(() => {
    if (estimate) {
      console.log('Current estimate data:', {
        businessDetails: estimate.businessDetails,
        projectImages: estimate.projectImages
      });
    }
  }, [estimate]);

  useEffect(() => {
    // Force load the Great Vibes font
    const loadFont = async () => {
      try {
        await document.fonts.load('400 48px "Great Vibes"');
        console.log('Font loaded successfully');
      } catch (err) {
        console.error('Font loading failed:', err);
      }
    };
    loadFont();
  }, []);

  useEffect(() => {
    // Preload the font
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    const style = document.createElement('style');
    style.textContent = `
      @font-face {
        font-family: 'Great Vibes';
        src: url('https://fonts.gstatic.com/s/greatvibes/v14/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2') format('woff2');
        font-weight: 400;
        font-style: normal;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
    };
  }, []);

  const fetchEstimate = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/estimates/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      
      console.log('Raw estimate data:', data);
      
      // Format the project images
      const formattedProjectImages = data.project_images 
        ? (Array.isArray(data.project_images) 
            ? data.project_images.map(image => {
                if (typeof image === 'string') {
                  return {
                    filename: image,
                    url: `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image}`
                  };
                }
                return {
                  filename: image.filename,
                  url: `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`
                };
              })
            : [])
        : [];

      // Format the customer details properly
      const customerDetails = {
        name: data.customer_details?.name || '',
        company: data.customer_details?.company || '',
        email: data.customer_details?.email || '',
        phone: data.customer_details?.phone || '',
        address: data.customer_details?.address || '',
        city: data.customer_details?.city || '',
        state: data.customer_details?.state || '',
        zipCode: data.customer_details?.zipCode || '',
        notes: data.customer_details?.notes || '',
        isNewClient: data.customer_details?.isNewClient || false
      };

      // Format the business details properly
      const businessDetails = {
        company_name: data.business_details?.company_name || '',
        owner_name: data.business_details?.owner_name || '',
        address: data.business_details?.address || '',
        city: data.business_details?.city || '',
        state: data.business_details?.state || '',
        zip_code: data.business_details?.zip_code || '',
        phone: data.business_details?.phone || '',
        email: data.business_details?.email || '',
        website: data.business_details?.website || '',
        tax_id: data.business_details?.tax_id || ''
      };

      // Format the complete estimate
      const formattedEstimate = {
        ...data,
        projectImages: formattedProjectImages,
        customerDetails: customerDetails,
        businessDetails: businessDetails,
        items: data.items?.map(item => ({
          description: item.description || '',
          quantity: Number(item.quantity) || 0,
          unitPrice: Number(item.unit_price) || 0,
          tax: Number(item.tax) || 0
        })) || [],
        scopeOfWork: data.scope_of_work || '',
        notes: data.notes || '',
        terms: data.terms || '',
        template: data.template || 'default'
      };
      
      console.log('Items after formatting:', formattedEstimate.items);
      console.log('Formatted estimate:', formattedEstimate);
      setEstimate(formattedEstimate);
    } catch (err) {
      console.error('Error fetching estimate:', err);
      setError('Failed to load estimate');
    } finally {
      setLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (!estimate.public_uuid) {
        const response = await fetch(`/api/estimates/${id}/generate-link`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) throw new Error('Failed to generate sharing link');
        const data = await response.json();
        setEstimate({ ...estimate, public_uuid: data.public_uuid });
      }

      const publicUrl = `${window.location.origin}/public/estimate/${estimate.public_uuid}`;
      await navigator.clipboard.writeText(publicUrl);
      setSuccessMessage('Public link copied to clipboard!');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error sharing estimate:', error);
      setError('Failed to generate sharing link');
    }
  };

  const handleDownloadPDF = async () => {
    const element = document.querySelector('.default-template');
    if (!element) return;

    try {
      setLoading(true);
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false
      });
      
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      const pdf = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait'
      });
      
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      
      // Generate filename using estimate number or default to current date
      const filename = estimate?.estimate_number 
        ? `estimate_${estimate.estimate_number}.pdf`
        : `estimate_${new Date().toISOString().split('T')[0]}.pdf`;
      
      pdf.save(filename);
      setSuccessMessage('PDF downloaded successfully!');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!estimate) return <Typography>No estimate found</Typography>;

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Estimate Details</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {
              // Clean up and normalize the data structure
              const formattedEstimate = {
                id: estimate.id,
                estimate_number: estimate.estimate_number,
                issue_date: estimate.issue_date,
                expiration_date: estimate.expiration_date,
                status: estimate.status,
                template: estimate.template || 'default',
                
                // Normalize business details
                business_details: estimate.businessDetails || estimate.business_details,
                
                // Normalize customer details
                customer_details: estimate.customerDetails || estimate.customer_details,
                
                // Normalize items
                items: (estimate.items || []).map(item => ({
                  description: item.description || '',
                  quantity: Number(item.quantity) || 0,
                  unit_price: Number(item.unitPrice || item.unit_price) || 0,
                  tax: Number(item.tax) || 0
                })),
                
                // Normalize project images
                project_images: estimate.projectImages || estimate.project_images || [],
                
                // Additional fields
                scope_of_work: estimate.scopeOfWork || estimate.scope_of_work || '',
                notes: estimate.notes || '',
                terms: estimate.terms || '',
                logo: estimate.logo || '',
                logo_size: estimate.logo_size || 100,
                show_additional_info: estimate.showAdditionalInfo || estimate.show_additional_info || false,
                
                // Optional fields
                public_uuid: estimate.public_uuid,
                client_signature: estimate.client_signature,
                client_signed_at: estimate.client_signed_at
              };

              console.log('Navigating with cleaned estimate:', formattedEstimate);

              navigate('/admin/estimates/edit/' + id, { 
                state: { 
                  estimate: formattedEstimate
                },
                replace: true
              });
            }}
            style={{ marginRight: '10px' }}
          >
            Edit Estimate
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleShare}
            style={{ marginRight: '10px' }}
          >
            Share with Client
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadPDF}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Generating PDF...' : 'Download PDF'}
          </Button>
        </Box>
      </Box>

      <DefaultTemplate 
        estimate={estimate} // Pass the entire estimate object directly
      />

      {/* Success Snackbar */}
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminEstimateDetails;
