import React, { useState, useEffect } from 'react';
import styles from './Calendar.module.scss';
import { 
  Typography, 
  IconButton, 
  Tooltip,
  Paper
} from '@mui/material';
import { 
  ArrowBackIos as ArrowBackIosIcon, 
  ArrowForwardIos as ArrowForwardIosIcon 
} from '@mui/icons-material';
import { getProjects, getProjectEvents } from '../services/api';
import EventDetails from './EventDetails'; // Import the EventDetails component

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);

  useEffect(() => {
    console.log('Calendar component mounted or currentDate changed');
    const fetchEvents = async () => {
      try {
        console.log('Fetching events...');
        setLoading(true);
        const projectsResponse = await getProjects();
        console.log('Projects response:', projectsResponse);
        const projects = projectsResponse.data.projects;
        console.log('Projects:', projects);
        
        let allEvents = [];
        for (const project of projects) {
          console.log(`Fetching events for project ${project.id}`);
          const projectEvents = await getProjectEvents(project.id);
          console.log(`Events for project ${project.id}:`, projectEvents);
          const eventsWithProject = projectEvents.map(event => ({
            ...event,
            project: project.name
          }));
          allEvents = [...allEvents, ...eventsWithProject];
        }
        
        console.log('All events:', allEvents);
        setEvents(allEvents);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to fetch events. Please try again.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentDate]);

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const firstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const navigateMonth = (direction) => {
    console.log(`Navigating month: ${direction}`);
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  const handleEventClick = (eventId) => {
    setSelectedEventId(eventId);
    setIsEventDetailsOpen(true);
  };

  const handleCloseEventDetails = () => {
    setIsEventDetailsOpen(false);
    setSelectedEventId(null);
  };

  const renderCalendarDays = () => {
    const days = [];
    const totalDays = daysInMonth(currentDate);
    const firstDay = firstDayOfMonth(currentDate);
    const today = new Date();

    let currentRow = [];
    let rowIndex = 0;

    for (let i = 0; i < firstDay; i++) {
      currentRow.push(<div key={`empty-${i}`} className={styles.emptyDay} />);
    }

    for (let i = 1; i <= totalDays; i++) {
      const currentDateForDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const dayEvents = events.filter(event => 
        event.time_slots.some(slot => {
          const slotDate = new Date(slot.start_time);
          return slotDate.getDate() === currentDateForDay.getDate() && 
                 slotDate.getMonth() === currentDateForDay.getMonth() && 
                 slotDate.getFullYear() === currentDateForDay.getFullYear();
        })
      );
      console.log(`Events for day ${i}:`, dayEvents);
      const isToday = i === today.getDate() && 
                      currentDate.getMonth() === today.getMonth() && 
                      currentDate.getFullYear() === today.getFullYear();
      
      currentRow.push(
        <div 
          key={`day-${i}`} 
          className={`${styles.calendarDay} ${isToday ? styles.today : ''}`}
        >
          <span className={styles.dayNumber}>{i}</span>
          <div className={styles.eventContainer}>
            {dayEvents.slice(0, 3).map((event, index) => (
              <Tooltip 
                key={event.id} 
                title={
                  <React.Fragment>
                    <Typography color="inherit">{event.title}</Typography>
                    <Typography variant="body2">{event.project}</Typography>
                    <Typography variant="body2">
                      {new Date(event.time_slots[0].start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - 
                      {new Date(event.time_slots[0].end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </Typography>
                  </React.Fragment>
                } 
                arrow
              >
                <Paper 
                  elevation={2} 
                  className={`${styles.eventCard} ${styles[`project${event.project_id}`]}`}
                  onClick={() => handleEventClick(event.id)}
                  sx={{
                    backgroundColor: `hsl(${(event.project_id * 100) % 360}, 70%, 80%)`,
                  }}
                >
                  <span className={styles.eventEmoji}>{event.emoji}</span>
                  <div className={styles.eventDetails}>
                    <span className={styles.eventTitle}>{event.title}</span>
                    <span className={styles.eventProject}>{event.project}</span>
                  </div>
                </Paper>
              </Tooltip>
            ))}
            {dayEvents.length > 3 && (
              <div className={styles.moreEvents}>+{dayEvents.length - 3} more</div>
            )}
          </div>
        </div>
      );

      if (currentRow.length === 7 || i === totalDays) {
        days.push(
          <div key={`row-${rowIndex}`} className={`${styles.calendarRow} ${styles.fadeInElement}`} style={{'--row-index': rowIndex}}>
            {currentRow}
          </div>
        );
        currentRow = [];
        rowIndex++;
      }
    }

    return days;
  };

  console.log('Rendering Calendar component');
  console.log('Current date:', currentDate);
  console.log('Events:', events);
  console.log('Loading:', loading);
  console.log('Error:', error);

  if (loading) return <div>Loading events...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.calendarContainer}>
      <div className={`${styles.calendarHeader} ${styles.fadeInElement}`}>
        <IconButton onClick={() => navigateMonth(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h4">
          {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </Typography>
        <IconButton onClick={() => navigateMonth(1)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <div className={`${styles.calendarGrid} ${styles.fadeInElement}`}>
        <div className={`${styles.weekdayRow} ${styles.fadeInElement}`}>
          {DAYS_OF_WEEK.map(day => (
            <div key={day} className={styles.weekday}>{day}</div>
          ))}
        </div>
        {renderCalendarDays()}
      </div>
      <EventDetails
        eventId={selectedEventId}
        open={isEventDetailsOpen}
        onClose={handleCloseEventDetails}
      />
    </div>
  );
};

export default Calendar;
