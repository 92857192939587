import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { createEvent, updateEvent } from '../services/api';
import InputAdornment from '@mui/material/InputAdornment';

const EventForm = ({ open, onClose, projectId, onEventCreated, event }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [emoji, setEmoji] = useState('📅');
  const [address, setAddress] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [eventData, setEventData] = useState({ value: 0 });

  useEffect(() => {
    if (event) {
      setTitle(event.title || '');
      setDescription(event.description || '');
      setEmoji(event.emoji || '📅');
      setAddress(event.addresses?.[0] || '');
      setStartTime(event.time_slots?.[0]?.start_time || '');
      setEndTime(event.time_slots?.[0]?.end_time || '');
      setEventData({ value: event.value || 0 });
    } else {
      // Reset form for new event
      setTitle('');
      setDescription('');
      setEmoji('📅');
      setAddress('');
      setStartTime('');
      setEndTime('');
      setEventData({ value: 0 });
    }
  }, [event]);

  const handleSubmit = async () => {
    try {
      const newEventData = {
        title,
        description,
        emoji,
        addresses: [address],
        time_slots: [{ start_time: startTime, end_time: endTime }],
        project_id: projectId,
        value: eventData.value
      };

      let resultEvent;
      if (event) {
        resultEvent = await updateEvent(event.id, newEventData);
        console.log('Event updated:', resultEvent);
      } else {
        resultEvent = await createEvent(newEventData);
        console.log('Event created:', resultEvent);
      }

      if (typeof onEventCreated === 'function') {
        onEventCreated(resultEvent);
      }
      onClose();
    } catch (error) {
      console.error('Error saving event:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{event ? 'Edit Event' : 'Create New Event'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            margin="dense"
          />
          <TextField
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="dense"
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', marginTop: '16px' }}>
            <TextField
              label="Emoji"
              value={emoji}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <InsertEmoticonIcon />
                  </IconButton>
                ),
              }}
              style={{ width: '100%' }}
            />
          </div>
          {showEmojiPicker && (
            <div style={{ marginBottom: '16px' }}>
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
          <TextField
            label="Address"
            type="text"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            margin="dense"
          />
          <TextField
            label="Start Time"
            type="datetime-local"
            fullWidth
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="dense"
          />
          <TextField
            label="End Time"
            type="datetime-local"
            fullWidth
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
            margin="dense"
          />
          <TextField
            label="Event Value"
            type="number"
            value={eventData.value || ''}
            onChange={(e) => setEventData({ ...eventData, value: parseFloat(e.target.value) })}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {event ? 'Update Event' : 'Create Event'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventForm;