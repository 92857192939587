import React from 'react';
import { styled } from '@mui/material/styles';

const StyledSignature = styled('div')({
  fontFamily: '"Dancing Script", cursive !important',
  fontSize: '48px !important',
  fontWeight: 700,
  lineHeight: 1.2,
  textAlign: 'center',
  width: '100%'
});

const SignatureText = ({ text }) => {
  return <StyledSignature>{text}</StyledSignature>;
};

export default SignatureText;
