import React from 'react';

const formatTimestamp = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

const ClassicTemplate = ({ estimate }) => {
  const formatDate = (date) => {
    if (!date) return 'N/A';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'N/A';
  };

  const calculateSubtotal = () => {
    return estimate?.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0) || 0;
  };

  const calculateTax = () => {
    return estimate?.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0) || 0;
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div className="classic-template" style={{ fontFamily: 'Times New Roman, serif', color: '#000', padding: '20px', border: '2px solid #000' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom: '2px solid #000', paddingBottom: '20px' }}>
        <div>
          <h1 style={{ margin: 0, fontStyle: 'italic' }}>Estimate</h1>
          <p style={{ margin: '5px 0' }}>Estimate Number: {estimate?.estimate_number || 'N/A'}</p>
          <p style={{ margin: '5px 0' }}>Issue Date: {formatDate(estimate?.issue_date)}</p>
          <p style={{ margin: '5px 0' }}>Valid Until: {formatDate(estimate?.expiration_date)}</p>
        </div>
        {estimate?.logo && (
          <img src={`/static/project_files/${estimate.logo}`} alt="Company Logo" style={{ maxWidth: '150px', maxHeight: '150px' }} />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ flex: 1, marginRight: '10px', borderRight: '1px solid #000', paddingRight: '10px' }}>
          <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>From:</h3>
          <p>{estimate?.businessDetails?.company_name}</p>
          <p>{estimate?.businessDetails?.name}</p>
          <p>{estimate?.businessDetails?.address}</p>
          <p>{estimate?.businessDetails?.city}, {estimate?.businessDetails?.state} {estimate?.businessDetails?.zipCode}</p>
          <p>Phone: {estimate?.businessDetails?.phone}</p>
          <p>Email: {estimate?.businessDetails?.email}</p>
        </div>
        <div style={{ flex: 1, marginLeft: '10px' }}>
          <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>To:</h3>
          <p>{estimate?.customerDetails?.companyName}</p>
          <p>{estimate?.customerDetails?.customerName}</p>
          <p>{estimate?.customerDetails?.address}</p>
          <p>{estimate?.customerDetails?.city}, {estimate?.customerDetails?.state} {estimate?.customerDetails?.zipCode}</p>
          <p>Phone: {estimate?.customerDetails?.phone}</p>
          <p>Email: {estimate?.customerDetails?.email}</p>
        </div>
      </div>

      <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>Items</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Description</th>
            <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>Quantity</th>
            <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>Unit Price</th>
            <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>Tax (%)</th>
            <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {estimate?.items?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px' }}>{item.description}</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>{item.quantity}</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>${item.unitPrice.toFixed(2)}</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>{item.tax}%</td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'right' }}>${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: '20px', textAlign: 'right', borderTop: '1px solid #000', paddingTop: '10px' }}>
        <p><strong>Subtotal:</strong> ${calculateSubtotal().toFixed(2)}</p>
        <p><strong>Tax:</strong> ${calculateTax().toFixed(2)}</p>
        <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}><strong>Total:</strong> ${calculateTotal().toFixed(2)}</p>
      </div>

      {estimate?.show_additional_info && estimate?.scope_of_work && (
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>Scope of Work</h3>
          <div dangerouslySetInnerHTML={{ __html: estimate.scope_of_work }} />
        </div>
      )}

      {estimate?.show_notes && (
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>Notes</h3>
          <p>{estimate?.notes || 'No additional notes.'}</p>
        </div>
      )}

      {estimate?.project_images && estimate.project_images.length > 0 && (
        <div style={{ 
          marginTop: '2rem',
          pageBreakBefore: 'always',
          borderTop: '1px solid #000',
          paddingTop: '20px'
        }}>
          <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>Project Photos</h3>
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '1rem', 
            marginTop: '1rem' 
          }}>
            {estimate.project_images.map((image, index) => (
              <div key={index} style={{ 
                border: '1px solid #000',
                padding: '5px'
              }}>
                <img 
                  src={image.url} 
                  alt={`${index + 1}`}
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    objectFit: 'cover',
                    display: 'block'
                  }} 
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '45%', borderTop: '2px solid #000', paddingTop: '10px' }}>
          {estimate?.client_signature ? (
            <>
              <p>Customer Signature:</p>
              <img 
                src={estimate.client_signature} 
                alt="Client Signature" 
                style={{ maxWidth: '200px', marginBottom: '10px' }} 
              />
              <p>Signed: {formatTimestamp(estimate.client_signed_at)}</p>
            </>
          ) : (
            <>
              <p style={{ fontFamily: 'Dancing Script', fontSize: '24px', fontWeight: 700 }}>
                Customer Signature: _______________________
              </p>
              <p>Date: _______________</p>
            </>
          )}
        </div>
        <div style={{ width: '45%', borderTop: '2px solid #000', paddingTop: '10px' }}>
          {estimate?.admin_signature ? (
            <>
              <p>Company Representative:</p>
              <img 
                src={estimate.admin_signature} 
                alt="Admin Signature" 
                style={{ maxWidth: '200px', marginBottom: '10px' }} 
              />
              <p>Signed: {formatTimestamp(estimate.admin_signed_at)}</p>
            </>
          ) : (
            <>
              <p style={{ fontFamily: 'Dancing Script', fontSize: '24px', fontWeight: 700 }}>
                Company Representative: _______________________
              </p>
              <p>Date: _______________</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassicTemplate;
