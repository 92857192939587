import React, { useState } from 'react';
import { Typography, Paper, Avatar, Button, TextField, IconButton } from '@mui/material';
import { Edit, Save, Cancel, Email, Phone, Business, LocationOn } from '@mui/icons-material';
import styles from './ClientProfile.module.scss';

const initialProfile = {
  id: 2,
  name: 'Ian Doe',
  email: 'ian2@ian.com',
  phone: '123-456-7890',
  address: '123 Elm Street, Anytown, USA',
  companyName: 'Doe Enterprises',
  avatar: 'https://via.placeholder.com/150',
};

const infoFields = [
  { name: 'companyName', label: 'Company', icon: Business },
  { name: 'email', label: 'Email', icon: Email },
  { name: 'phone', label: 'Phone', icon: Phone },
  { name: 'address', label: 'Address', icon: LocationOn },
];

const ClientProfile = () => {
  const [profile, setProfile] = useState(initialProfile);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(initialProfile);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    setProfile(editedProfile);
    setIsEditing(false);
  };
  const handleCancel = () => {
    setEditedProfile(profile);
    setIsEditing(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const renderInfoItem = ({ name, label, icon: Icon }, index) => (
    <div key={name} className={styles.fadeUpItem} style={{ animationDelay: `${index * 0.1}s` }}>
      {isEditing ? (
        <TextField
          fullWidth
          label={label}
          name={name}
          value={editedProfile[name]}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          InputProps={{ startAdornment: <Icon className={styles[`${name}Icon`]} /> }}
        />
      ) : (
        <div className={styles.infoItem}>
          <Icon className={styles[`${name}Icon`]} />
          <Typography variant="body1">{profile[name]}</Typography>
        </div>
      )}
    </div>
  );

  return (
    <Paper elevation={3} className={styles.profilePaper}>
      <div className={`${styles.header} ${styles.fadeUpItem}`}>
        <Typography variant="h4" className={styles.title}>Client Profile</Typography>
        {!isEditing && (
          <IconButton onClick={handleEdit} className={styles.editButton}>
            <Edit />
          </IconButton>
        )}
      </div>
      <div className={`${styles.avatarSection} ${styles.fadeUpItem}`} style={{ animationDelay: '0.1s' }}>
        <Avatar alt={profile.name} src={profile.avatar} className={styles.avatar} />
        <Typography variant="h5" className={styles.name}>{profile.name}</Typography>
      </div>
      <div className={styles.infoSection}>
        {infoFields.map(renderInfoItem)}
      </div>
      {isEditing && (
        <div className={`${styles.actionButtons} ${styles.fadeUpItem}`} style={{ animationDelay: '0.5s' }}>
          <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default ClientProfile;
