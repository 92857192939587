import React, { useState } from 'react';
import { Grid, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Chip, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import VisaIcon from '@mui/icons-material/CreditCard';
import MastercardIcon from '@mui/icons-material/CreditCard';
import AmexIcon from '@mui/icons-material/CreditCard';
import DiscoverIcon from '@mui/icons-material/CreditCard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryIcon from '@mui/icons-material/History';
import styles from './AdminSettingsPayment.module.scss';

const CARD_ICONS = {
  visa: <VisaIcon className={styles.visaIcon} />,
  mastercard: <MastercardIcon className={styles.mastercardIcon} />,
  amex: <AmexIcon className={styles.amexIcon} />,
  discover: <DiscoverIcon className={styles.discoverIcon} />,
};

const dummyPayments = [
  { id: 1, date: '2023-05-01', amount: 1000, status: 'Paid', description: 'Monthly subscription', card: { brand: 'visa', last4: '4242' } },
  { id: 2, date: '2023-05-15', amount: 1500, status: 'Pending', description: 'Additional services', card: { brand: 'mastercard', last4: '5555' } },
  { id: 3, date: '2023-05-30', amount: 800, status: 'Overdue', description: 'Custom feature', card: { brand: 'amex', last4: '0005' } },
];

const dummySavedCards = [
  { id: 1, brand: 'visa', last4: '4242', cardHolder: 'John Doe', expiryDate: '05/24' },
  { id: 2, brand: 'mastercard', last4: '5555', cardHolder: 'Jane Smith', expiryDate: '09/25' },
  { id: 3, brand: 'amex', last4: '0005', cardHolder: 'Alice Johnson', expiryDate: '12/23' },
];

function AdminSettingsPayment() {
  const [payments] = useState(dummyPayments);
  const [savedCards, setSavedCards] = useState(dummySavedCards);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [newCard, setNewCard] = useState({
    cardNumber: '',
    cardHolder: '',
    expiryDate: '',
    cvv: ''
  });
  const [openSupportChat, setOpenSupportChat] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedCard, setSelectedCard] = useState(savedCards[0].id);

  // Fixed next payment due to 28 days
  const daysUntilNextPayment = 28;

  const handleOpenAddCardModal = () => {
    setOpenAddCardModal(true);
  };

  const handleCloseAddCardModal = () => {
    setOpenAddCardModal(false);
    setNewCard({
      cardNumber: '',
      cardHolder: '',
      expiryDate: '',
      cvv: ''
    });
  };

  const handleAddCard = () => {
    // Here you would typically send this data to your backend
    // For this example, we'll just add it to the local state
    const brand = getCardBrand(newCard.cardNumber);
    const last4 = newCard.cardNumber.slice(-4);
    const newSavedCard = {
      id: savedCards.length + 1,
      brand,
      last4,
      cardHolder: newCard.cardHolder,
      expiryDate: newCard.expiryDate
    };
    setSavedCards([...savedCards, newSavedCard]);
    handleCloseAddCardModal();
  };

  const handleCardInputChange = (event) => {
    const { name, value } = event.target;
    setNewCard({ ...newCard, [name]: value });
  };

  const getCardBrand = (cardNumber) => {
    // This is a simple check. In a real app, you'd want more robust validation
    if (cardNumber.startsWith('4')) return 'visa';
    if (cardNumber.startsWith('5')) return 'mastercard';
    if (cardNumber.startsWith('3')) return 'amex';
    if (cardNumber.startsWith('6')) return 'discover';
    return 'unknown';
  };

  const handleOpenSupportChat = () => {
    setOpenSupportChat(true);
    setChatMessages([{ sender: 'Support', message: 'Hello! How can I assist you today?' }]);
  };

  const handleCloseSupportChat = () => {
    setOpenSupportChat(false);
    setChatMessages([]);
  };

  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      setChatMessages([...chatMessages, { sender: 'You', message: currentMessage }]);
      setCurrentMessage('');
      // Simulate a response from support after a short delay
      setTimeout(() => {
        setChatMessages(prev => [...prev, { sender: 'Support', message: 'Thank you for your message. A support agent will be with you shortly.' }]);
      }, 1000);
    }
  };

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
    // Move the selected card to the top of the array
    const updatedCards = [
      savedCards.find(card => card.id === cardId),
      ...savedCards.filter(card => card.id !== cardId)
    ];
    setSavedCards(updatedCards);
  };

  return (
    <div className={styles.paymentSettings}>
      <Typography variant="h5" className={styles.sectionTitle}>Payment Management</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper className={styles.summaryContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className={styles.summaryCard}>
                  <div className={`${styles.iconWrapper} ${styles.calendarIcon}`}>
                    <CalendarTodayIcon className={styles.summaryIcon} />
                  </div>
                  <Typography variant="subtitle1">Next Payment Due</Typography>
                  <Typography variant="h4">{daysUntilNextPayment} days</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.summaryCard}>
                  <div className={`${styles.iconWrapper} ${styles.moneyIcon}`}>
                    <AttachMoneyIcon className={styles.summaryIcon} />
                  </div>
                  <Typography variant="subtitle1">Last Payment</Typography>
                  <Typography variant="h4">{payments[0].date}</Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddCardModal}
              className={styles.actionButton}
            >
              Add New Card
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SupportAgentIcon />}
              onClick={handleOpenSupportChat}
              className={styles.actionButton}
            >
              Contact Payment Support
            </Button>
          </Box>
          <Paper className={styles.tableContainer}>
            <Typography variant="h6" className={styles.tableTitle}>
              <div className={`${styles.iconWrapper} ${styles.historyIcon}`}>
                <HistoryIcon className={styles.titleIcon} />
              </div>
              Payment History
            </Typography>
            <TableContainer>
              <Table className={styles.table}>
                <TableHead>
                  <TableRow>
                    {['Date', 'Amount', 'Status', 'Description', 'Card Used'].map(header => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>{payment.date}</TableCell>
                      <TableCell>${payment.amount.toFixed(2)}</TableCell>
                      <TableCell>
                        <Chip 
                          label={payment.status} 
                          className={`${styles.statusChip} ${styles[payment.status.toLowerCase()]}`}
                        />
                      </TableCell>
                      <TableCell>{payment.description}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {CARD_ICONS[payment.card.brand]}
                          <span className={styles.cardInfo}>
                            {payment.card.brand.charAt(0).toUpperCase() + payment.card.brand.slice(1)} **** {payment.card.last4}
                          </span>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={styles.savedCardsContainer}>
            <Typography variant="h6" className={styles.savedCardsTitle}>
              <div className={`${styles.iconWrapper} ${styles.creditCardIcon}`}>
                <CreditCardIcon className={styles.titleIcon} />
              </div>
              Saved Cards
            </Typography>
            <Box className={styles.walletContainer}>
              {savedCards.map((card, index) => (
                <div 
                  key={card.id} 
                  className={`${styles.creditCard} ${styles[card.brand.toLowerCase()]} ${selectedCard === card.id ? styles.selectedCard : ''}`}
                  style={{ 
                    top: `${index * 20}px`, 
                    zIndex: savedCards.length - index,
                    transform: selectedCard === card.id ? 'translateY(-20px)' : 'none'
                  }}
                  onClick={() => handleCardSelect(card.id)}
                >
                  <div className={styles.cardHeader}>
                    <div className={styles.cardChip}></div>
                    {CARD_ICONS[card.brand]}
                  </div>
                  <Typography variant="h6" className={styles.cardNumber}>**** **** **** {card.last4}</Typography>
                  <div className={styles.cardBottom}>
                    <div className={styles.cardInfo}>
                      <Typography variant="caption" className={styles.cardInfoLabel}>Card Holder</Typography>
                      <Typography variant="body2" className={styles.cardInfoValue}>{card.cardHolder}</Typography>
                    </div>
                    <div className={styles.cardInfo}>
                      <Typography variant="caption" className={styles.cardInfoLabel}>Expires</Typography>
                      <Typography variant="body2" className={styles.cardInfoValue}>{card.expiryDate}</Typography>
                    </div>
                  </div>
                  {selectedCard === card.id && (
                    <CheckCircleIcon className={styles.defaultCardIcon} />
                  )}
                </div>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Add Card Modal */}
      <Dialog open={openAddCardModal} onClose={handleCloseAddCardModal}>
        <DialogTitle>Add New Card</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="cardNumber"
            label="Card Number"
            type="text"
            fullWidth
            value={newCard.cardNumber}
            onChange={handleCardInputChange}
          />
          <TextField
            margin="dense"
            name="cardHolder"
            label="Card Holder Name"
            type="text"
            fullWidth
            value={newCard.cardHolder}
            onChange={handleCardInputChange}
          />
          <TextField
            margin="dense"
            name="expiryDate"
            label="Expiry Date (MM/YY)"
            type="text"
            fullWidth
            value={newCard.expiryDate}
            onChange={handleCardInputChange}
          />
          <TextField
            margin="dense"
            name="cvv"
            label="CVV"
            type="text"
            fullWidth
            value={newCard.cvv}
            onChange={handleCardInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddCardModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCard} color="primary">
            Add Card
          </Button>
        </DialogActions>
      </Dialog>

      {/* Support Chat Modal */}
      <Dialog open={openSupportChat} onClose={handleCloseSupportChat} maxWidth="sm" fullWidth>
        <DialogTitle>Payment Support Chat</DialogTitle>
        <DialogContent>
          <Box className={styles.chatContainer}>
            {chatMessages.map((msg, index) => (
              <Box key={index} className={`${styles.chatMessage} ${msg.sender === 'You' ? styles.userMessage : styles.supportMessage}`}>
                <Typography variant="subtitle2">{msg.sender}</Typography>
                <Typography>{msg.message}</Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex" mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message here..."
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <IconButton color="primary" onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSupportChat} color="primary">
            Close Chat
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminSettingsPayment;
