import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  IconButton,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { 
  Add as AddIcon,
  Delete as DeleteIcon 
} from '@mui/icons-material';
import { getAllProjectPhotos } from '../services/api';
import styles from './ProjectImages.module.scss';

const ProjectImages = ({ open, onClose, onSelectImages, selectedImages = [] }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      fetchImages();
    }
  }, [open]);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await getAllProjectPhotos();
      console.log('Fetched project photos:', response.data);
      setImages(response.data.photos);
    } catch (err) {
      console.error('Error fetching project photos:', err);
      setError('Failed to load project photos');
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelect = (image) => {
    const formattedImage = {
      id: image.id,
      filename: image.filename,
      url: `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`
    };
    
    console.log('Formatted image:', formattedImage);
    onSelectImages(formattedImage);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        style: {
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Project Photos</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        )}
        
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}

        <Grid container spacing={2}>
          {images.map((image) => (
            <Grid item xs={12} sm={6} md={4} key={image.id}>
              <Box className={styles.imageContainer}>
                <img
                  src={`${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`}
                  alt={image.filename}
                  className={styles.image}
                />
                <Box className={styles.imageOverlay}>
                  <IconButton
                    onClick={() => handleImageSelect(image)}
                    className={styles.addButton}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectImages;
