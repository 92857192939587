import React, { useState, useEffect } from 'react';
import { Typography, Paper, CircularProgress, Grid, Avatar, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Person, Email, Work, Phone, Home, LockReset } from '@mui/icons-material';
import { getEmployeeProfile, resetPassword } from '../services/api';
import styles from './EmployeeProfile.module.scss';

const EmployeeProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetError, setResetError] = useState('');
  const [showResetSuccess, setShowResetSuccess] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const data = await getEmployeeProfile();
      setProfile(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching profile:', err);
      setError('Failed to fetch profile. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setResetError('Passwords do not match');
      return;
    }
    try {
      await resetPassword(newPassword);
      setOpenResetPassword(false);
      setNewPassword('');
      setConfirmPassword('');
      setResetError('');
      setShowResetSuccess(true);
      setTimeout(() => setShowResetSuccess(false), 3000);
    } catch (error) {
      setResetError(error.message || 'Failed to reset password. Please try again.');
    }
  };

  return (
    <Paper elevation={3} className={styles.profileContainer}>
      {profile && (
        <>
          <div className={styles.profileHeader}>
            <div className={styles.avatar}>
              {profile.name.split(' ').map(n => n[0]).join('').toUpperCase()}
            </div>
            <Typography variant="h4" className={styles.profileName}>
              {profile.name}
            </Typography>
            <Typography variant="subtitle1" className={styles.profilePosition}>
              {profile.position}
            </Typography>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.profileDetails}>
            <ProfileItem icon={<Email />} label="Email" value={profile.email} color="#4285F4" />
            <ProfileItem icon={<Work />} label="Position" value={profile.position} color="#0F9D58" />
            <ProfileItem icon={<Phone />} label="Phone" value={profile.phone} color="#F4B400" />
            <ProfileItem icon={<Home />} label="Address" value={profile.address} color="#DB4437" />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenResetPassword(true)}
            className={styles.resetPasswordButton}
            startIcon={<LockReset />}
          >
            Reset Password
          </Button>
          <Dialog open={openResetPassword} onClose={() => setOpenResetPassword(false)}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
                sx={{ marginBottom: 2, backgroundColor: '#f5f5f5' }}
              />
              <TextField
                margin="dense"
                label="Confirm New Password"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                sx={{ marginBottom: 2, backgroundColor: '#f5f5f5' }}
              />
              {resetError && <p className={styles.errorMessage}>{resetError}</p>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenResetPassword(false)}>Cancel</Button>
              <Button onClick={handleResetPassword} variant="contained" color="primary">
                Reset Password
              </Button>
            </DialogActions>
          </Dialog>
          {showResetSuccess && (
            <div className={`${styles.resetSuccessOverlay} ${styles.show}`}>
              <div className={styles.resetSuccessContent}>
                <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <Typography variant="h6" className={styles.resetSuccessMessage}>
                  Password Reset Successfully!
                </Typography>
              </div>
            </div>
          )}
        </>
      )}
    </Paper>
  );
};

const ProfileItem = ({ icon, label, value, color }) => (
  <div className={styles.profileItem}>
    <div className={styles.iconWrapper} style={{ backgroundColor: '#f0f0f0', borderRadius: '50%' }}>
      {React.cloneElement(icon, { style: { color: color } })}
    </div>
    <div className={styles.itemContent}>
      <Typography variant="subtitle2" className={styles.itemLabel}>
        {label}
      </Typography>
      <Typography variant="body1" className={styles.itemValue}>
        {value}
      </Typography>
    </div>
  </div>
);

export default EmployeeProfile;
