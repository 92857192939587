import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Box, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaymentsIcon from '@mui/icons-material/Payments';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';

const Navbar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const theme = useTheme();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navItems = [
    { icon: <HomeIcon />, text: 'Home', path: '/admin', color: '#3F51B5' },         // Indigo
    { icon: <CalendarMonthIcon />, text: 'Calendar', path: '/admin/calendar', color: '#1E88E5' },  // Blue
    { icon: <AccessTimeIcon />, text: 'Timesheets', path: '/admin/timesheets', color: '#FFA000' }, // Amber
    { icon: <PeopleIcon />, text: 'Employees', path: '/admin/employees', color: '#E91E63' },       // Pink
    { icon: <AccountTreeIcon />, text: 'Projects', path: '/admin/projects', color: '#9C27B0' },    // Purple
    { icon: <PaymentsIcon />, text: 'Reports', path: '/admin/payroll', color: '#4CAF50' },         // Green
    { icon: <BusinessIcon />, text: 'Clients', path: '/admin/clients', color: '#FF5722' },         // Deep Orange
    { icon: <RequestQuoteIcon />, text: 'Estimates', path: '/admin/estimates', color: '#00BCD4' },
    { icon: <ReceiptIcon />, text: 'Invoices', path: '/admin/invoices', color: '#795548' },
    { icon: <SettingsIcon />, text: 'Settings', path: '/admin/settings', color: '#607D8B' },
  ];

  return (
    <Box
      component="nav"
      sx={{
        width: 240,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 1,
        fontFamily: 'Urbanist, sans-serif',
      }}
    >
      <List>
        {navItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              py: 1.5,
            }}
          >
            <ListItemIcon 
              sx={{ 
                minWidth: 0, 
                mr: 2, 
                justifyContent: 'center',
                color: item.color,
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.mode === 'light' 
                    ? theme.palette.grey[100] 
                    : theme.palette.grey[800],
                  borderRadius: '50%',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 500,
              }}
            />
          </ListItem>
        ))}
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            py: 1.5,
          }}
        >
          <ListItemIcon 
            sx={{ 
              minWidth: 0, 
              mr: 2, 
              justifyContent: 'center',
              color: theme.palette.error.main,
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.mode === 'light' 
                  ? theme.palette.grey[200] 
                  : theme.palette.grey[800],
                borderRadius: '50%',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LogoutIcon />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 500,
            }}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default Navbar;
