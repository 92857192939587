import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMaterialCost, updateMaterialCost, deleteMaterialCost } from '../services/api';
import styles from './MaterialCostDetails.module.scss';

const MaterialCostDetails = () => {
  const { projectId, costId } = useParams();
  const navigate = useNavigate();
  const [cost, setCost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCost, setEditedCost] = useState({ item: '', cost: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCostDetails();
  }, [projectId, costId]);

  const fetchCostDetails = async () => {
    try {
      const data = await getMaterialCost(projectId, costId);
      setCost(data);
      setEditedCost({ item: data.item, cost: data.cost });
    } catch (error) {
      console.error('Error fetching material cost details:', error);
      setError(error.message);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedCost({ item: cost.item, cost: cost.cost });
  };

  const handleSave = async () => {
    try {
      await updateMaterialCost(projectId, costId, editedCost);
      setIsEditing(false);
      fetchCostDetails();
    } catch (error) {
      console.error('Error updating material cost:', error);
      setError(error.message);
    }
  };

  const handleBack = () => {
    navigate(`/projects/${projectId}`);
  };

  if (error) return <div>Error: {error}</div>;
  if (!cost) return <div>Loading...</div>;

  return (
    <div className={styles.materialCostDetails}>
      <h2>Material Cost Details</h2>
      {isEditing ? (
        <div className={styles.editForm}>
          <input
            type="text"
            value={editedCost.item}
            onChange={(e) => setEditedCost({ ...editedCost, item: e.target.value })}
          />
          <input
            type="number"
            value={editedCost.cost}
            onChange={(e) => setEditedCost({ ...editedCost, cost: e.target.value })}
          />
          <div className={styles.buttonGroup}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      ) : (
        <div className={styles.costInfo}>
          <p><strong>Item:</strong> {cost.item}</p>
          <p><strong>Cost:</strong> ${cost.cost.toFixed(2)}</p>
          <button onClick={handleEdit} className={styles.editButton}>Edit</button>
        </div>
      )}
      <button onClick={handleBack} className={styles.backButton}>Back to Project</button>
    </div>
  );
};

export default MaterialCostDetails;
