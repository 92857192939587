import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Typography, Paper, CircularProgress, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import BeachAccessIcon from '@mui/icons-material/BeachAccess'; // Import the "gone fishing" icon
import { getEmployeeAvailability, updateEmployeeAvailability } from '../services/api';
import styles from './EmployeeAvailability.module.scss';
import { styled } from '@mui/material/styles';

// Create styled components for the icons
const StyledPersonIcon = styled(PersonIcon)({
  fontSize: '50px',
  width: '100%',
  height: '100%',
});

const EmployeeAvailability = () => {
  const [weeklyAvailability, setWeeklyAvailability] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));

  useEffect(() => {
    fetchAvailability();
  }, [currentWeekStart]);

  const fetchAvailability = async () => {
    try {
      setLoading(true);
      const data = await getEmployeeAvailability(
        currentWeekStart.toDate(),
        currentWeekStart.clone().endOf('week').toDate()
      );
      initializeWeeklyAvailability(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching availability:', err);
      setError('Failed to fetch availability. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const initializeWeeklyAvailability = (data) => {
    const weekly = {};
    for (let i = 0; i < 7; i++) {
      const day = currentWeekStart.clone().add(i, 'days').format('YYYY-MM-DD');
      weekly[day] = data.some((slot) => moment(slot.start_time).isSame(day, 'day'));
    }
    setWeeklyAvailability(weekly);
  };

  const handleAvailabilityToggle = async (day) => {
    try {
      const newAvailability = !weeklyAvailability[day];
      const response = await updateEmployeeAvailability({
        date: day,
        available: newAvailability,
      });

      if (response.message === 'Availability updated successfully') {
        setWeeklyAvailability((prev) => ({
          ...prev,
          [day]: newAvailability,
        }));
      } else {
        throw new Error('Failed to update availability');
      }
    } catch (err) {
      console.error('Error updating availability:', err);
      setError('Failed to update availability. Please try again.');
    }
  };

  const handlePreviousWeek = () => {
    setCurrentWeekStart((prev) => prev.clone().subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    setCurrentWeekStart((prev) => prev.clone().add(1, 'week'));
  };

  const iconStyle = {
    fontSize: '100px',  // Adjust this value to change the icon size
    width: '100%',
    height: '100%',
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  return (
    <Paper elevation={3} className={styles.availabilityContainer}>
      <Typography variant="h4" className={styles.availabilityTitle}>
        My Availability
      </Typography>
      <div className={styles.weekNavigation}>
        <IconButton onClick={handlePreviousWeek}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">
          {currentWeekStart.format('MMMM D')} -{' '}
          {currentWeekStart.clone().endOf('week').format('MMMM D, YYYY')}
        </Typography>
        <IconButton onClick={handleNextWeek}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <Grid container spacing={2} className={styles.weeklyGrid} justifyContent="center">
        {Object.keys(weeklyAvailability).map((day) => (
          <Grid item key={day} className={styles.gridItem}>
            <button
              className={styles.dayColumn}
              onClick={() => handleAvailabilityToggle(day)}
            >
              <Typography variant="subtitle1" className={styles.dayHeader}>
                {moment(day).format('ddd D')}
              </Typography>
              <div className={styles.iconContainer}>
                <div className={`${styles.iconWrapper} ${weeklyAvailability[day] ? styles.available : styles.unavailable}`}>
                  <PersonIcon className={`${styles.icon} ${styles.personIcon}`} style={iconStyle} />
                  <BeachAccessIcon className={`${styles.icon} ${styles.fishingIcon}`} style={iconStyle} />
                  <span className={styles.waveEmoji}>🌊</span>
                </div>
              </div>
              <div>
                {weeklyAvailability[day] ? 'Available' : 'Unavailable'}
              </div>
            </button>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default EmployeeAvailability;
