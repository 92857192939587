import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Fade,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { debounce } from 'lodash';
import ClientEstimateForm from './estimate/ClientEstimateForm.tsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './AdminCreateEstimate.module.scss';
import ProjectImages from './ProjectImages';
import DeleteIcon from '@mui/icons-material/Delete';
import EstimateReview from './estimate/EstimateReview.tsx';
import { createEstimate, createClient, getBusinessDetails, getClients } from '../services/api';
import SavedItemsManager from './SavedItemsManager';

const steps = [
  'Client Details',
  'Items & Pricing',
  'Project Photos',
  'Additional Details',
  'Review'
];

const initialEstimateState = {
  estimate_number: '',
  issue_date: new Date().toISOString().split('T')[0],
  expiration_date: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0],
  items: [],
  scope_of_work: '',
  notes: '',
  terms: '',
  template: 'default',
  logo: '',
  project_images: [],
  logo_size: null,
  business_details: {
    company_name: '',
    owner_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    email: '',
    website: '',
    tax_id: ''
  },
  customer_details: {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    notes: '',
    isNewClient: true
  },
  status: 'draft',
  admin_signature: null,
  admin_signed_at: null,
  admin_signed_name: null,
  admin_signature_type: null,
  client_signature: null,
  client_signed_at: null,
  client_signed_name: null,
  client_signature_type: null,
  show_additional_info: false
};

const inputStyle = {
  '& .MuiInputBase-root': {
    '&:before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.42) !important'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87) !important'
    },
    '&.Mui-focused:after': {
      borderBottom: '2px solid #1976d2 !important'
    }
  }
};

const underlineStyle = {
  '& .MuiInput-underline:before': { borderBottom: '1px solid rgba(0, 0, 0, 0.42)' },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: '2px solid rgba(0, 0, 0, 0.87)' },
  '& .MuiInput-underline:after': { borderBottom: '2px solid #1976d2' }
};

const AdminCreateEstimate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  
  console.log('AdminCreateEstimate initializing with state:', state);

  const [estimate, setEstimate] = useState(() => {
    if (state?.estimate) {
      const formattedEstimate = {
        ...initialEstimateState,
        ...state.estimate,
        items: state.estimate.items.map(item => ({
          description: item.description || '',
          quantity: Number(item.quantity) || 0,
          unitPrice: Number(item.unit_price || item.unitPrice) || 0,
          tax: Number(item.tax) || 0
        })),
        customer_details: {
          ...initialEstimateState.customer_details,
          ...state.estimate.customer_details,
          isNewClient: false
        },
        business_details: {
          ...initialEstimateState.business_details,
          ...state.estimate.business_details
        },
        project_images: state.estimate.project_images || [],
        scope_of_work: state.estimate.scope_of_work || '',
        notes: state.estimate.notes || '',
        terms: state.estimate.terms || '',
        template: state.estimate.template || 'default'
      };
      
      console.log('Formatted estimate for editing:', formattedEstimate);
      return formattedEstimate;
    }
    return initialEstimateState;
  });

  // Initialize selected client if editing
  const [selectedClient, setSelectedClient] = useState(
    state?.estimate?.customer_details 
      ? {
          id: state.estimate.customer_details.id,
          ...state.estimate.customer_details
        }
      : null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [openSavedItemsModal, setOpenSavedItemsModal] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [clients, setClients] = useState([]);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [nextEstimateNumber, setNextEstimateNumber] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [openPhotosModal, setOpenPhotosModal] = useState(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [photoError, setPhotoError] = useState('');
  const [savedItems, setSavedItems] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    console.log('Received state:', state);
    console.log('Received estimate:', state?.estimate);
  }, [state]);

  useEffect(() => {
    if (state?.estimate?.customer_details) {
      setSelectedClient({
        id: state.estimate.customer_details.id,
        ...state.estimate.customer_details
      });
    }
  }, [state]);

  const handleNext = async () => {
    // If we're on the client details step (step 0)
    if (activeStep === 0) {
      try {
        // Add console.log to debug
        console.log('Current customer details:', estimate.customer_details);
        
        // Check if we have client details to save - add more specific validation
        if (!estimate.customer_details.name || !estimate.customer_details.email || !estimate.customer_details.phone) {
          setErrorMessage('Please fill in required client details (name, email, and phone)');
          setShowError(true);
          return;
        }

        setIsLoading(true);
        
        // If it's marked as a new client, create them
        if (estimate.customer_details.isNewClient) {
          const clientData = {
            name: estimate.customer_details.name,
            email: estimate.customer_details.email,
            phone: estimate.customer_details.phone,
            address: estimate.customer_details.address,
            city: estimate.customer_details.city,
            state: estimate.customer_details.state,
            zipCode: estimate.customer_details.zipCode,
            company: estimate.customer_details.company || '',
            notes: estimate.customer_details.notes || ''
          };

          console.log('Sending client data:', clientData);

          const clientResponse = await createClient(clientData);

          if (clientResponse && clientResponse.data) {
            // Update the estimate with the new client ID
            setEstimate(prev => ({
              ...prev,
              customer_details: {
                ...prev.customer_details,
                id: clientResponse.data.id,
                isNewClient: false
              }
            }));
            
            // Update clients list
            setClients(prev => [...prev, clientResponse.data]);
            setSelectedClient(clientResponse.data);
          }
        }
        
        // Proceed to next step
        setActiveStep((prevStep) => prevStep + 1);
        
      } catch (error) {
        console.error('Error creating client:', error);
        setErrorMessage('Failed to create client. Please try again.');
        setShowError(true);
        return;
      } finally {
        setIsLoading(false);
      }
    } else {
      // For other steps, just move forward
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCustomerDetailsChange = (field, value) => {
    console.log('Updating customer details:', field, value);
    setEstimate(prev => ({
      ...prev,
      customer_details: {
        ...prev.customer_details,
        [field]: value,
        isNewClient: true // Ensure this is set when manually entering details
      }
    }));
  };

  const handleInputChange = (field, value) => {
    setEstimate(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const addItem = () => {
    setEstimate(prev => ({
      ...prev,
      items: [...prev.items, {
        description: '',
        quantity: 1,
        unitPrice: 0,
        tax: 0
      }]
    }));
  };

  const removeItem = (index) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const calculateSubtotal = estimate.items.reduce((sum, item) => 
    sum + (Number(item.quantity) * Number(item.unitPrice)), 0
  );

  const calculateTax = estimate.items.reduce((sum, item) => 
    sum + (Number(item.quantity) * Number(item.unitPrice) * Number(item.tax) / 100), 0
  );

  const calculateTotal = calculateSubtotal + calculateTax;

  // Separate button handler from form submit
  const handleCreateEstimateClick = () => {
    if (canMoveToStep(steps.length - 1)) {
      setShowConfirmModal(true);
    }
  };

  // Remove or simplify handleSubmit since we're using the button handler
  const handleSubmit = (e) => {
    e.preventDefault(); // Just prevent default form submission
  };

  const handleConfirmedSubmit = async () => {
    try {
      setIsLoading(true);
      
      let clientId;
      if (estimate.customer_details.isNewClient) {
        const clientResponse = await createClient(estimate.customer_details);
        clientId = clientResponse.data.id;
      } else {
        clientId = selectedClient.id;
      }

      const formattedItems = estimate.items.map(item => ({
        description: item.description,
        quantity: Number(item.quantity),
        unit_price: Number(item.unitPrice || item.unit_price),
        tax: Number(item.tax)
      }));

      const estimateData = {
        client_id: clientId,
        estimate_number: nextEstimateNumber,
        issue_date: estimate.issue_date,
        expiration_date: estimate.expiration_date,
        items: formattedItems,
        notes: estimate.notes,
        terms: estimate.terms,
        template: estimate.template,
        project_images: estimate.project_images.map(img => ({
          filename: typeof img === 'object' ? img.filename : img
        })),
        business_details: estimate.business_details,
        customer_details: estimate.customer_details,
        status: 'draft'
      };

      const response = await createEstimate(estimateData);
      setShowSuccess(true);
      setShowConfirmModal(false);
      
      setTimeout(() => {
        navigate(`/admin/estimates/${response.data.id}`);
      }, 2000);
    } catch (error) {
      console.error('Failed to create estimate:', error);
      setErrorMessage('Failed to create estimate. Please try again.');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [businessData, fetchedClients] = await Promise.all([
          getBusinessDetails(),
          getClients()
        ]);

        // Ensure clients is an array and properly formatted
        const formattedClients = Array.isArray(fetchedClients) 
          ? fetchedClients.map(client => ({
              id: client.id,
              name: client.name,
              company: client.company || '',
              email: client.email || '',
              phone: client.phone || '',
              address: client.address || '',
              city: client.city || '',
              state: client.state || '',
              zipCode: client.zipCode || '',
              notes: client.notes || '',
              created_at: client.created_at
            }))
          : [];

        console.log('Formatted clients:', formattedClients);
        setClients(formattedClients);
        setBusinessDetails(businessData);

        // Generate estimate number
        const nextNumber = 'EST-' + new Date().getTime();
        setNextEstimateNumber(nextNumber);

        setEstimate(prev => ({
          ...prev,
          estimate_number: nextNumber,
          business_details: businessData
        }));

      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data');
        setShowError(true);
        // Initialize with empty array on error
        setClients([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Handle client creation/selection
  const handleClientSubmit = async (clientData) => {
    try {
      if (estimate.customer_details.isNewClient) {
        const newClient = await createClient({
          name: clientData.name,
          email: clientData.email,
          phone: clientData.phone,
          address: clientData.address,
          city: clientData.city,
          state: clientData.state,
          zipCode: clientData.zipCode,
          company: clientData.company || '',
          notes: clientData.notes || ''
        });
        
        setEstimate(prev => ({
          ...prev,
          customer_details: {
            ...prev.customer_details,
            id: newClient.id,
            isNewClient: false
          }
        }));
      }
    } catch (error) {
      console.error('Error handling client:', error);
      setErrorMessage('Failed to create/update client');
      setShowError(true);
    }
  };

  const searchAddress = async (query) => {
    if (!query || query.length < 3) return;
    
    setIsLoadingAddress(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5&addressdetails=1&countrycodes=us`,
        {
          headers: {
            'Accept-Language': 'en-US',
            'User-Agent': 'EverProCleaning_EstimateSystem/1.0',
            'Referer': 'https://teams.everprocleaning.com'
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Raw address data:', data);
      
      // Format the suggestions with proper data extraction
      const formattedSuggestions = data.map(item => {
        console.log('Processing item:', item); // Debug log
        
        return {
          display_name: item.display_name,
          formatted_address: {
            full_address: item.display_name,
            street_number: item.address?.house_number || '',
            street_name: item.address?.road || item.address?.street || '',
            city: item.address?.city || 
                  item.address?.town || 
                  item.address?.village || 
                  item.address?.municipality || '',
            state: item.address?.state || '',
            zip: item.address?.postcode || '',
            county: item.address?.county || '',
            country: item.address?.country || 'United States'
          }
        };
      });
      
      console.log('Formatted suggestions:', formattedSuggestions);
      setAddressSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      setAddressSuggestions([]);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  // Increase debounce time to avoid rate limiting
  const debouncedSearchAddress = debounce(searchAddress, 1000);

  // Update the handleAddressSelect function to properly handle the data
  const handleAddressSelect = (selectedAddress) => {
    console.log('Selected address for processing:', selectedAddress);
    
    if (!selectedAddress?.formatted_address) {
      console.log('Invalid address format received');
      return;
    }
    
    const addr = selectedAddress.formatted_address;
    const fullStreetAddress = [addr.street_number, addr.street_name]
      .filter(Boolean)
      .join(' ');
    
    console.log('Setting address fields:', {
      address: fullStreetAddress,
      city: addr.city,
      state: addr.state,
      zipCode: addr.zip
    });

    setEstimate(prev => ({
      ...prev,
      customer_details: {
        ...prev.customer_details,
        address: fullStreetAddress,
        city: addr.city,
        state: addr.state,
        zipCode: addr.zip
      }
    }));
  };

  const renderClientDetails = () => (
    <Fade in={activeStep === 0}>
      <Paper className={styles.paper}>
        <ClientEstimateForm 
          onClientSelect={handleClientSelect}
          onClientCreate={handleNewClient}
          styles={styles}
          selectedClient={selectedClient}
          initialData={estimate.customer_details}
        />
      </Paper>
    </Fade>
  );

  const renderItemsAndPricing = () => (
    <Fade in={activeStep === 1}>
      <Paper className={styles.paper}>
        <Typography className={styles.sectionTitle}>
          Items & Pricing
        </Typography>

        <div className={styles.addItemButtons}>
          <Button
            variant="outlined"
            onClick={addItem}
            className={styles.addItemButton}
          >
            Add Item
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenSavedItemsModal(true)}
          >
            Use Saved Items
          </Button>
        </div>

        <div className={styles.itemsTable}>
          {estimate.items.map((item, index) => (
            <div key={index} className={styles.itemRow}>
              <TextField
                fullWidth
                variant="standard"
                label="Description"
                value={item.description}
                onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                sx={inputStyle}
              />
              <TextField
                type="number"
                variant="standard"
                label="Quantity"
                value={item.quantity}
                onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                sx={inputStyle}
              />
              <TextField
                type="number"
                variant="standard"
                label="Price"
                value={item.unitPrice}
                onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                sx={inputStyle}
              />
              <TextField
                type="number"
                variant="standard"
                label="Tax %"
                value={item.tax}
                onChange={(e) => handleItemChange(index, 'tax', e.target.value)}
                sx={inputStyle}
              />
              <Button
                color="error"
                onClick={() => removeItem(index)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>

        <div className={styles.totalSection}>
          <Typography>Subtotal: ${calculateSubtotal}</Typography>
          <Typography>Tax: ${calculateTax}</Typography>
          <Typography variant="h6">Total: ${calculateTotal}</Typography>
        </div>

        <SavedItemsManager
          open={openSavedItemsModal}
          onClose={() => setOpenSavedItemsModal(false)}
          onAddToEstimate={(items) => {
            setEstimate(prev => ({
              ...prev,
              items: [...prev.items, ...items]
            }));
            setOpenSavedItemsModal(false);
          }}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
        />
      </Paper>
    </Fade>
  );

  const renderAdditionalDetails = () => (
    <Fade in={activeStep === 3}>
      <Paper className={styles.paper}>
        <Typography className={styles.sectionTitle}>
          Additional Details
        </Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Scope of Work
            </Typography>
            <ReactQuill
              value={estimate.scope_of_work}
              onChange={(value) => handleInputChange('scope_of_work', value)}
              placeholder="Enter scope of work..."
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Additional Notes
            </Typography>
            <ReactQuill
              value={estimate.notes}
              onChange={(value) => handleInputChange('notes', value)}
              placeholder="Enter additional notes..."
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Terms & Conditions
            </Typography>
            <ReactQuill
              value={estimate.terms}
              onChange={(value) => handleInputChange('terms', value)}
              placeholder="Enter terms and conditions..."
            />
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );

  const renderReview = () => (
    <Fade in={activeStep === 4}>
      <Paper className={styles.paper}>
        <EstimateReview 
          estimate={estimate}
          styles={styles}
        />
      </Paper>
    </Fade>
  );

  const renderProjectPhotos = () => (
    <Fade in={activeStep === 2}>
      <Paper className={styles.paper}>
        <Typography className={styles.sectionTitle}>
          Project Photos
        </Typography>
        
        <Box className={styles.photoSection}>
          <Grid container spacing={2}>
            {estimate.project_images.map((photo, index) => (
              <Grid item xs={12} sm={6} md={4} key={photo.id}>
                <Box className={styles.imageContainer}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${photo.filename}`}
                    alt={photo.filename}
                    className={styles.image}
                  />
                  <IconButton
                    className={styles.deleteButton}
                    onClick={() => removePhoto(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
          
          <Button
            variant="outlined"
            onClick={() => setOpenPhotosModal(true)}
            className={styles.addPhotoButton}
          >
            Add Project Photos
          </Button>
        </Box>

        <ProjectImages
          open={openPhotosModal}
          onClose={() => setOpenPhotosModal(false)}
          onSelectImages={handlePhotoSelect}
          selectedImages={estimate.project_images}
          isLoading={isLoadingPhotos}
          error={photoError}
        />
      </Paper>
    </Fade>
  );

  const removePhoto = (index) => {
    setEstimate(prev => ({
      ...prev,
      project_images: prev.project_images.filter((_, i) => i !== index)
    }));
  };

  const handlePhotoSelect = async (image) => {
    try {
      setIsLoadingPhotos(true);
      setPhotoError('');
      
      setEstimate(prev => ({
        ...prev,
        project_images: [...prev.project_images, {
          id: image.id,
          filename: image.filename,
          url: `${process.env.REACT_APP_API_URL}/static/uploads/project_photos/${image.filename}`
        }]
      }));
    } catch (error) {
      console.error('Error adding photo:', error);
      setPhotoError('Failed to add photo. Please try again.');
    } finally {
      setIsLoadingPhotos(false);
      setOpenPhotosModal(false);
    }
  };

  const validateStep = () => {
    if (activeStep === steps.length - 1) {
      return canMoveToStep(activeStep);
    }
    return true;
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setEstimate(prev => ({
      ...prev,
      customer_details: {
        id: client.id,
        name: client.name || '',
        company: client.company || '',
        email: client.email || '',
        phone: client.phone || '',
        address: client.address || '',
        city: client.city || '',
        state: client.state || '',
        zipCode: client.zipCode || '',
        notes: client.notes || '',
        isNewClient: false
      }
    }));
  };

  const handleNewClient = () => {
    setSelectedClient(null);
    setEstimate(prev => ({
      ...prev,
      customer_details: {
        ...initialEstimateState.customer_details,
        isNewClient: true
      }
    }));
  };

  const canMoveToStep = (stepIndex) => {
    // Allow free movement between all steps except Review
    if (stepIndex !== steps.length - 1) {
      return true;
    }

    // Validate all required fields before allowing access to Review step
    const isValid = 
      estimate.customer_details.name && // Client name required
      estimate.items.length > 0 && // At least one item required
      estimate.items.every(item => 
        item.description && 
        Number(item.quantity) > 0 && 
        Number(item.unitPrice) > 0
      ); // All items must be complete

    if (!isValid) {
      setErrorMessage('Please complete all required fields before reviewing');
      setShowError(true);
      return false;
    }

    return true;
  };

  const handleStepClick = (index) => {
    if (index === steps.length - 1) {
      if (canMoveToStep(index)) {
        setActiveStep(index);
      }
    } else {
      setActiveStep(index);
    }
  };

  return (
    <Box className={styles.container}>
      {/* Log the current estimate state */}
      {console.log('Current estimate in render:', estimate)}
      
      <Box className={styles.documentHeader}>
        <Typography className={styles.title}>
          {state?.estimate ? 'Edit Estimate' : 'Create Estimate'}
        </Typography>
      </Box>

      <Stepper activeStep={activeStep} className={styles.stepper}>
        {steps.map((label, index) => (
          <Step 
            key={label}
            onClick={() => handleStepClick(index)}
            style={{ cursor: 'pointer' }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box component="form" onSubmit={handleSubmit}>
        <div className={styles.fadeContainer}>
          {activeStep === 0 && renderClientDetails()}
          {activeStep === 1 && renderItemsAndPricing()}
          {activeStep === 2 && renderProjectPhotos()}
          {activeStep === 3 && renderAdditionalDetails()}
          {activeStep === 4 && renderReview()}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            type="button"
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateEstimateClick}  // Changed to onClick instead of type="submit"
              type="button"
              disabled={isLoading}
            >
              Create Estimate
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              type="button"
            >
              Next
            </Button>
          )}
        </div>
      </Box>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">Estimate created successfully!</Alert>
      </Snackbar>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirm Estimate Creation
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to create this estimate?
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle2">Summary:</Typography>
            <Typography>Client: {estimate.customer_details.name}</Typography>
            <Typography>Items: {estimate.items.length}</Typography>
            <Typography>Total: ${calculateTotal.toFixed(2)}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowConfirmModal(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmedSubmit}
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Confirm & Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminCreateEstimate;



