import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, Button, Chip, TextField, Alert, Link, IconButton } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AdminSettings.module.scss';

function AdminSettingsCompliance() {
  const [showBanner, setShowBanner] = useState(true);

  const [workersComp, setWorkersComp] = useState({
    status: 'Active',
    expirationDate: '2023-12-31',
    coverageAmount: 1000000,
  });

  const [generalLiability, setGeneralLiability] = useState({
    status: 'Pending',
    expirationDate: '2023-12-31',
    coverageAmount: 2000000,
  });

  const handleUploadDocument = (type) => {
    console.log(`Uploading ${type} document`);
    // Implement document upload logic here
  };

  const handleViewDetails = (type) => {
    console.log(`Viewing ${type} details`);
    // Implement view details logic here
  };

  const handleInputChange = (type, field, value) => {
    if (type === 'Workers Comp') {
      setWorkersComp({ ...workersComp, [field]: value });
    } else {
      setGeneralLiability({ ...generalLiability, [field]: value });
    }
  };

  const handleApplyNow = (type) => {
    console.log(`Applying for ${type} insurance`);
    // Implement apply now logic here
  };

  const InsuranceDetails = ({ type, data, onInputChange }) => (
    <Box className={styles.insuranceDetails}>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="subtitle1" mr={2}>Status:</Typography>
        <Chip 
          label={data.status} 
          color={data.status === 'Active' ? 'success' : 'warning'} 
          size="small" 
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <CalendarTodayIcon className={styles.inlineIcon} />
        <TextField
          label="Expiration Date"
          type="date"
          value={data.expirationDate}
          onChange={(e) => onInputChange(type, 'expirationDate', e.target.value)}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <AttachMoneyIcon className={styles.inlineIcon} />
        <TextField
          label="Coverage Amount"
          type="number"
          value={data.coverageAmount}
          onChange={(e) => onInputChange(type, 'coverageAmount', e.target.value)}
          InputProps={{ startAdornment: '$' }}
          size="small"
        />
      </Box>
    </Box>
  );

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <Paper className={styles.tabPanel}>
      {showBanner && (
        <Alert 
          icon={<span role="img" aria-label="celebration" className={styles.celebrationEmoji}>🎉</span>}
          severity="info" 
          className={styles.banner}
          action={
            <Box display="flex" alignItems="center">
              <Link href="#" color="inherit" underline="always" className={styles.seeMoreLink}>
                See More
              </Link>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseBanner}
                className={styles.closeButton}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          }
        >
          See how ServTracker can help you with your business compliance today!
        </Alert>
      )}
      <Box display="flex" alignItems="center" mb={3} mt={3}>
        <Typography variant="h5" component="h2" className={styles.sectionTitle}>
          <GavelIcon className={styles.headerIcon} />
          Compliance
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className={styles.complianceSection}>
            <Typography variant="h6" gutterBottom className={styles.complianceHeader}>
              <WorkIcon className={styles.sectionIcon} />
              Workers' Compensation Insurance
            </Typography>
            <Typography variant="body2" paragraph>
              Workers' Compensation Insurance provides coverage for work-related injuries and illnesses. It's required by law in most states for businesses with employees.
            </Typography>
            <InsuranceDetails 
              type="Workers Comp"
              data={workersComp}
              onInputChange={handleInputChange}
            />
            <Box className={styles.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleApplyNow('Workers Comp')}
                className={styles.applyButton}
              >
                Apply Now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => handleViewDetails('Workers Comp')}
                className={styles.complianceButton}
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.complianceSection}>
            <Typography variant="h6" gutterBottom className={styles.complianceHeader}>
              <SecurityIcon className={styles.sectionIcon} />
              General Liability Insurance
            </Typography>
            <Typography variant="body2" paragraph>
              General Liability Insurance protects your business from financial loss should you be liable for property damage or personal and advertising injury caused by your services, business operations or employees.
            </Typography>
            <InsuranceDetails 
              type="General Liability"
              data={generalLiability}
              onInputChange={handleInputChange}
            />
            <Box className={styles.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleApplyNow('General Liability')}
                className={styles.applyButton}
              >
                Apply Now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => handleViewDetails('General Liability')}
                className={styles.complianceButton}
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AdminSettingsCompliance;
