import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, List, ListItem, ListItemText, CircularProgress,
  Typography, Avatar, InputAdornment, IconButton, Divider, Box
} from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { getClients } from '../services/api';

const ClientSelector = ({ open, onClose, onClientSelect }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        const response = await getClients();
        setClients(response.clients || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to load clients');
        setLoading(false);
      }
    };

    if (open) {
      fetchClients();
    }
  }, [open]);

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.company.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">Select Client</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search clients"
          type="text"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">{error}</Typography>
        ) : (
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {filteredClients.map((client, index) => (
              <React.Fragment key={client.id}>
                <ListItem 
                  button 
                  onClick={() => onClientSelect(client)}
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: (theme) => theme.palette.action.hover 
                    }
                  }}
                >
                  <Avatar sx={{ mr: 2, bgcolor: (theme) => theme.palette.primary.main }}>
                    {client.name.charAt(0)}
                  </Avatar>
                  <ListItemText 
                    primary={
                      <Typography variant="subtitle1" component="span">
                        {client.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {client.company}
                      </Typography>
                    }
                  />
                </ListItem>
                {index < filteredClients.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            ))}
            {filteredClients.length === 0 && (
              <Typography align="center" color="text.secondary" sx={{ py: 2 }}>
                No clients found
              </Typography>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientSelector;
