import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, CircularProgress, Button, TextField, Chip, Avatar, AvatarGroup, Box, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { fetchClientDashboard } from '../services/api';
import styles from './ClientDashboard.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import FolderIcon from '@mui/icons-material/Folder';
import LaunchIcon from '@mui/icons-material/Launch';

const ClientDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showEmoji, setShowEmoji] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const loadDashboard = async () => {
      try {
        const data = await fetchClientDashboard();
        // Sort events by start_time (most recent first)
        if (data.events) {
          data.events.sort((a, b) => {
            const aTime = new Date(a.time_slots[0]?.start_time || 0);
            const bTime = new Date(b.time_slots[0]?.start_time || 0);
            return bTime - aTime; // Descending order (most recent first)
          });
        }
        setDashboardData(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };
    loadDashboard();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowEmoji(false);
    }, 4000); // Match this to the animation duration

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setShowEmoji(true);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterProjects = (projects) => {
    return projects.filter(project =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  // Mock data for employees (replace with actual data later)
  const employees = [
    { name: 'John Doe', avatar: '' },
    { name: 'Jane Smith', avatar: '' },
    { name: 'Bob Johnson', avatar: '' },
    { name: 'Alice Brown', avatar: '' },
  ];

  // Function to generate color based on name
  const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  // Function to generate avatar props
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  const handleEventClick = (eventId) => {
    navigate(`/client/events/${eventId}`);
  };

  return (
    <Box className={styles.dashboard}>
      <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
        Welcome, {dashboardData?.client?.name || 'Client'} 
        {showEmoji && <span className={styles.wavingHand}>👋</span>}
      </Typography>
      <Box className={styles.columnsContainer}>
        <Box className={`${styles.column} ${styles.projectsColumn}`}>
          <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
            <EventIcon sx={{ color: theme.palette.primary.main }} />
            Projects
          </Typography>
          <Paper className={styles.paper} sx={{ bgcolor: theme.palette.background.paper }}>
            <div className={styles.projectsContainer}>
              <div className={styles.projectsScrollable}>
                {dashboardData?.client ? (
                  <div className={styles.projectsGrid}>
                    {filterProjects(dashboardData.projects || []).map(project => (
                      <Link to={`/client/projects/${project.id}`} key={project.id} className={styles.projectLink}>
                        <Paper className={styles.projectCard}>
                          <Typography variant="h6" className={styles.projectName}>
                            <FolderIcon className={styles.folderIcon} />
                            {project.name}
                          </Typography>
                          <Typography variant="body2" className={styles.projectDescription}>
                            {project.description || 'No description available'}
                          </Typography>
                          <div className={styles.projectDetails}>
                            <div className={styles.detailItem}>
                              <CalendarTodayIcon className={`${styles.detailIcon} ${styles.startDateIcon}`} />
                              <Typography variant="body2">
                                {project.start_time ? new Date(project.start_time).toLocaleDateString() : 'N/A'}
                              </Typography>
                            </div>
                            <div className={styles.detailItem}>
                              <EventIcon className={`${styles.detailIcon} ${styles.endDateIcon}`} />
                              <Typography variant="body2">
                                {project.end_time ? new Date(project.end_time).toLocaleDateString() : 'N/A'}
                              </Typography>
                            </div>
                            <div className={styles.detailItem}>
                              <LocationOnIcon className={`${styles.detailIcon} ${styles.locationIcon}`} />
                              <Typography variant="body2">
                                {project.address || 'N/A'}
                              </Typography>
                            </div>
                            <div className={styles.detailItem}>
                              <AttachMoneyIcon className={`${styles.detailIcon} ${styles.moneyIcon}`} />
                              <Typography variant="body2">
                                ${project.total_value ? project.total_value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}
                              </Typography>
                            </div>
                          </div>
                          <Chip label={project.status || 'Active'} className={styles.statusChip} />
                        </Paper>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Typography className={styles.noData}>No projects available.</Typography>
                )}
              </div>
            </div>
          </Paper>
        </Box>
        <Box className={`${styles.column} ${styles.eventsColumn}`}>
          <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
            <EventIcon sx={{ color: theme.palette.primary.main }} />
            Upcoming Events
          </Typography>
          <Paper className={styles.paper} sx={{ bgcolor: theme.palette.background.paper }}>
            <div className={styles.eventsContainer}>
              <div className={styles.eventsScrollable}>
                {dashboardData.events.map(event => (
                  <div 
                    key={event.id} 
                    className={styles.eventCard}
                    onClick={() => handleEventClick(event.id)}
                  >
                    <Typography variant="h6" className={styles.eventTitle}>
                      <span className={styles.eventEmoji}>{event.emoji}</span>
                      {event.title}
                    </Typography>
                    <div className={styles.eventDetails}>
                      {event.time_slots && event.time_slots.length > 0 && (
                        <>
                          <div className={styles.eventDetail}>
                            <CalendarTodayIcon className={`${styles.eventIcon} ${styles.calendarIcon}`} />
                            <Typography variant="body2">
                              {new Date(event.time_slots[0].start_time).toLocaleDateString()}
                            </Typography>
                          </div>
                          <div className={styles.eventDetail}>
                            <AccessTimeIcon className={`${styles.eventIcon} ${styles.timeIcon}`} />
                            <Typography variant="body2">
                              {new Date(event.time_slots[0].start_time).toLocaleTimeString()} - 
                              {new Date(event.time_slots[0].end_time).toLocaleTimeString()}
                            </Typography>
                          </div>
                        </>
                      )}
                      {event.addresses && event.addresses.length > 0 && (
                        <div className={styles.eventDetail}>
                          <LocationOnIcon className={`${styles.eventIcon} ${styles.locationIcon}`} />
                          <Typography variant="body2">{event.addresses[0]}</Typography>
                        </div>
                      )}
                    </div>
                    <Typography variant="body2" className={styles.eventDescription}>
                      {event.description}
                    </Typography>
                    <AvatarGroup max={4} className={styles.avatarGroup}>
                      {event.employees.map(employee => (
                        <Avatar 
                          key={employee.id} 
                          alt={employee.name} 
                          src={`/path/to/employee/avatar/${employee.id}`} 
                          className={styles.avatar}
                        />
                      ))}
                    </AvatarGroup>
                  </div>
                ))}
              </div>
            </div>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientDashboard;
