import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, TextField, InputAdornment, Paper, Box, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import FolderIcon from '@mui/icons-material/Folder';
import styles from './ClientDocuments.module.scss';

const mockDocuments = [
  { 
    id: 1, 
    name: 'Project Proposal', 
    type: 'pdf', 
    size: '2.5 MB', 
    lastModified: '2023-05-15', 
    content: 'This comprehensive project proposal outlines our strategy for implementing a new customer relationship management system. It includes a detailed timeline, budget breakdown, and expected outcomes.'
  },
  { 
    id: 2, 
    name: 'Client Contract', 
    type: 'docx', 
    size: '1.8 MB', 
    lastModified: '2023-05-20', 
    content: 'This legal document defines the terms and conditions of our agreement with the client. It covers project scope, deliverables, payment terms, and intellectual property rights.'
  },
  { 
    id: 3, 
    name: 'Financial Report Q2', 
    type: 'xlsx', 
    size: '3.2 MB', 
    lastModified: '2023-05-22', 
    content: 'This spreadsheet contains a detailed financial analysis for Q2, including revenue breakdowns, expense reports, and projections for the upcoming quarter.'
  },
  { 
    id: 4, 
    name: 'Product Mockups', 
    type: 'jpg', 
    size: '5.7 MB', 
    lastModified: '2023-05-25', 
    content: 'These high-resolution images showcase the latest designs for our upcoming product line. They include various color options and feature highlights.'
  },
  { 
    id: 5, 
    name: 'Team Performance Review', 
    type: 'pdf', 
    size: '1.5 MB', 
    lastModified: '2023-05-28', 
    content: 'This report provides a comprehensive overview of our teams performance over the last quarter. It includes individual and group metrics, achievements, and areas for improvement.'
  },
];

const fileTypes = {
  pdf: { icon: <PictureAsPdfIcon />, color: '#F40F02' },
  docx: { icon: <DescriptionIcon />, color: '#4285F4' },
  xlsx: { icon: <InsertDriveFileIcon />, color: '#0F9D58' },
  jpg: { icon: <ImageIcon />, color: '#F7B529' },
  default: { icon: <FolderIcon />, color: '#6F7EAA' }
};

const getFileIcon = (type) => {
  return fileTypes[type] || fileTypes.default;
};

const ClientDocuments = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);

  const filteredDocuments = mockDocuments.filter(doc =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewDocument = (document) => {
    setSelectedDocument(document);
  };

  return (
    <div className={styles.documentsContainer}>
      <Box display="flex" alignItems="center" mb={3} className={styles.fadeUpAnimation}>
        <FolderIcon fontSize="large" className={styles.titleIcon} />
        <Typography variant="h4" className={styles.title}>Documents</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={`${styles.fadeUpAnimation}`} style={{animationDelay: '0.1s'}}>
          <Paper className={styles.contentPaper}>
            <TextField
              className={styles.searchInput}
              variant="outlined"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <List className={styles.documentList}>
              {filteredDocuments.map((doc) => {
                const { icon, color } = getFileIcon(doc.type);
                return (
                  <ListItem 
                    key={doc.id} 
                    className={`${styles.documentItem} ${selectedDocument && selectedDocument.id === doc.id ? styles.selectedItem : ''}`}
                    onClick={() => handleViewDocument(doc)}
                  >
                    <ListItemAvatar>
                      <Avatar className={styles.documentAvatar}>
                        <span style={{ color: color }}>{icon}</span>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={doc.name}
                      secondary={`${doc.type.toUpperCase()} • ${doc.size} • Last modified: ${doc.lastModified}`}
                    />
                    <IconButton className={styles.viewButton}>
                      <VisibilityIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className={`${styles.fadeUpAnimation}`} style={{animationDelay: '0.2s'}}>
          <Paper className={`${styles.contentPaper} ${styles.previewPaper}`}>
            <Typography variant="h6" className={styles.previewTitle}>
              {selectedDocument ? selectedDocument.name : 'Document Preview'}
            </Typography>
            <Box className={styles.previewContent}>
              {selectedDocument ? (
                <>
                  <Box className={styles.previewHeader}>
                    <Box className={styles.previewIcon}>
                      <span style={{ color: getFileIcon(selectedDocument.type).color }}>
                        {getFileIcon(selectedDocument.type).icon}
                      </span>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1">{selectedDocument.type.toUpperCase()} File</Typography>
                      <Typography variant="body2">Size: {selectedDocument.size}</Typography>
                      <Typography variant="body2">Last Modified: {selectedDocument.lastModified}</Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" className={styles.previewText}>{selectedDocument.content}</Typography>
                </>
              ) : (
                <Typography>Select a document to preview</Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientDocuments;
