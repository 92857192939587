import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import styles from './AdminSettings.module.scss';
import { getBusinessDetails, updateBusinessDetails } from '../services/api';

function AdminSettingsBusinessDetails() {
  const [businessProfile, setBusinessProfile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBusinessProfile();
  }, []);

  const fetchBusinessProfile = async () => {
    try {
      setLoading(true);
      const data = await getBusinessDetails();
      console.log('Fetched business profile:', data);
      if (data === null) {
        console.log('No business profile found, opening dialog');
        setOpenDialog(true);
      } else {
        setBusinessProfile(data);
      }
    } catch (error) {
      console.error('Error fetching business profile:', error);
      setError('Failed to fetch business profile');
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessProfileChange = (event) => {
    const { name, value } = event.target;
    setBusinessProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const updatedProfile = await updateBusinessDetails(businessProfile);
      setBusinessProfile(updatedProfile);
      alert('Business profile updated successfully');
    } catch (error) {
      console.error('Error updating business profile:', error);
      alert('Error updating business profile');
    }
  };

  const handleCreateProfile = () => {
    console.log('Creating new business profile');
    setBusinessProfile({
      company_name: '',
      owner_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      phone: '',
      email: '',
    });
    setOpenDialog(false);
  };

  const getFieldLabel = (key) => {
    const labels = {
      company_name: 'Company Name',
      owner_name: 'Owner Name',
      address: 'Address',
      city: 'City',
      state: 'State',
      zip_code: 'ZIP Code',
      phone: 'Phone',
      email: 'Email'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={3} className={styles.tabPanel}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create Business Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No business profile found. Would you like to create one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateProfile} color="primary">Create Profile</Button>
        </DialogActions>
      </Dialog>

      {businessProfile ? (
        <>
          {Object.entries(businessProfile).map(([key, value]) => {
            if (key !== 'logo') {
              return (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    label={getFieldLabel(key)}
                    name={key}
                    value={value || ''}
                    onChange={handleBusinessProfileChange}
                    variant="outlined"
                  />
                </Grid>
              );
            }
            return null;
          })}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6">Please create a business profile to get started.</Typography>
          <Button variant="contained" color="primary" onClick={handleCreateProfile}>
            Create Business Profile
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default AdminSettingsBusinessDetails;
