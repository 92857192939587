import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEventDetails } from '../services/api';
import EventDetails from '../components/EventDetails';
import { Button, Typography } from '@mui/material';

const EventDetailsPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventData = await getEventDetails(eventId);
        setEvent(eventData);
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError('Failed to fetch event details. Please try again.');
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleBack = () => {
    navigate(-1);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!event) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <Button onClick={handleBack}>Back to Calendar</Button>
      <EventDetails event={event} />
    </div>
  );
};

export default EventDetailsPage;
