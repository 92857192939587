import React, { useState } from 'react';
import { 
  Fab, 
  SpeedDial, 
  SpeedDialIcon, 
  SpeedDialAction,
  Typography
} from '@mui/material';
import { 
  Add as AddIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Assignment as AssignmentIcon,
  RequestQuote as RequestQuoteIcon // New icon for Estimate
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styles from './CreateButton.module.scss';

const actions = [
  { icon: <AssignmentIcon style={{color: '#4caf50'}} />, name: 'Create Project', route: '/admin/projects/create' },
  { icon: <RequestQuoteIcon style={{color: '#2196f3'}} />, name: 'Create Estimate', route: '/admin/estimates/create' }, // Updated route
  { icon: <PersonIcon style={{color: '#ff9800'}} />, name: 'Create Employee', route: '/admin/create-employee' },
  { icon: <BusinessIcon style={{color: '#f44336'}} />, name: 'Create Client', route: '/admin/create-client' },
];

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAction = (route) => {
    if (route) {
      navigate(route);
    } else {
      // Handle the case for Create Estimate (no route yet)
      console.log('Create Estimate functionality not implemented yet');
      // You can add a placeholder action or notification here
    }
    handleClose();
  };

  return (
    <div className={styles.createButtonContainer}>
      <SpeedDial
        ariaLabel="Create new item"
        className={styles.speedDial}
        icon={<SpeedDialIcon openIcon={<AddIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleAction(action.route)}
            FabProps={{
              style: { 
                width: 'auto', 
                borderRadius: '30px',
                paddingLeft: '16px',
                paddingRight: '16px'
              }
            }}
            TooltipClasses={{ tooltip: styles.hiddenTooltip }}
          >
            <Typography variant="button" style={{ marginLeft: '8px', color: '#000' }}>
              {action.name}
            </Typography>
          </SpeedDialAction>
        ))}
      </SpeedDial>
    </div>
  );
};

export default CreateButton;
