import React, { useRef, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { uploadProjectPhoto } from '../services/api';
import styles from './Camera.module.scss';

const Camera = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [capturedImages, setCapturedImages] = useState([]);

  const startCamera = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  }, []);

  const stopCamera = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
  }, [stream]);

  const captureImage = useCallback(() => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
      const imageDataUrl = canvas.toDataURL('image/jpeg');
      setCapturedImages(prev => [...prev, imageDataUrl]);
    }
  }, []);

  const handleUpload = useCallback(async () => {
    for (const imageDataUrl of capturedImages) {
      try {
        const blob = await fetch(imageDataUrl).then(r => r.blob());
        const file = new File([blob], "camera_capture.jpg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append('photo', file);
        
        await uploadProjectPhoto(projectId, formData);
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
    navigate(`/projects/${projectId}/photos`);
  }, [capturedImages, projectId, navigate]);

  const removeImage = useCallback((index) => {
    setCapturedImages(prev => prev.filter((_, i) => i !== index));
  }, []);

  return (
    <div className={styles.camera}>
      <div className={styles.videoContainer}>
        {stream ? (
          <video ref={videoRef} autoPlay playsInline />
        ) : (
          <button onClick={startCamera}>Start Camera</button>
        )}
      </div>
      <div className={styles.controls}>
        {stream && (
          <>
            <button onClick={captureImage}>Capture</button>
            <button onClick={stopCamera}>Stop Camera</button>
          </>
        )}
      </div>
      <div className={styles.gallery}>
        {capturedImages.map((img, index) => (
          <div key={index} className={styles.imageContainer}>
            <img src={img} alt={`Captured ${index}`} />
            <button onClick={() => removeImage(index)}>Remove</button>
          </div>
        ))}
      </div>
      <button onClick={() => navigate(`/projects/${projectId}/photos`)}>Back to Photos</button>
    </div>
  );
};

export default Camera;
