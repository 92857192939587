import React, { useState } from 'react';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from './AdminSettings.module.scss';

const timeZones = [
  'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00', 'UTC-06:00', 'UTC-05:00', 
  'UTC-04:00', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00', 'UTC+00:00', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 
  'UTC+04:00', 'UTC+05:00', 'UTC+06:00', 'UTC+07:00', 'UTC+08:00', 'UTC+09:00', 'UTC+10:00', 'UTC+11:00', 
  'UTC+12:00', 'UTC+13:00', 'UTC+14:00'
];

function AdminSettingsAppSettings() {
  const [appSettings, setAppSettings] = useState({
    theme: 'light',
    language: 'en',
    timeZone: 'UTC+00:00',
    notifications: true,
    autoUpdate: false,
  });

  const handleAppSettingsChange = (event) => {
    const { name, value, checked } = event.target;
    setAppSettings(prev => ({
      ...prev,
      [name]: value !== undefined ? value : checked,
    }));
  };

  return (
    <div className={styles.tabPanel}>
      <Typography variant="h5" className={styles.sectionTitle}>
        <AppSettingsAltIcon className={styles.sectionIcon} />
        App Settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" className={styles.formGroup}>
            <InputLabel>Theme</InputLabel>
            <Select
              name="theme"
              value={appSettings.theme}
              onChange={handleAppSettingsChange}
              label="Theme"
            >
              <MenuItem value="light">Light</MenuItem>
              <MenuItem value="dark">Dark</MenuItem>
              <MenuItem value="system">System Default</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" className={styles.formGroup}>
            <InputLabel>Language</InputLabel>
            <Select
              name="language"
              value={appSettings.language}
              onChange={handleAppSettingsChange}
              label="Language"
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
              <MenuItem value="fr">Français</MenuItem>
              <MenuItem value="de">Deutsch</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" className={styles.formGroup}>
            <InputLabel>Time Zone</InputLabel>
            <Select
              name="timeZone"
              value={appSettings.timeZone}
              onChange={handleAppSettingsChange}
              label="Time Zone"
              startAdornment={<AccessTimeIcon className={styles.inputAdornment} />}
            >
              {timeZones.map((zone) => (
                <MenuItem key={zone} value={zone}>{zone}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={appSettings.notifications}
                onChange={handleAppSettingsChange}
                name="notifications"
                color="primary"
              />
            }
            label="Enable Notifications"
            className={styles.formGroup}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={appSettings.autoUpdate}
                onChange={handleAppSettingsChange}
                name="autoUpdate"
                color="primary"
              />
            }
            label="Auto Update"
            className={styles.formGroup}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminSettingsAppSettings;