import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployees } from '../services/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, Box, Grid, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './Employees.module.scss';

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const data = await getEmployees();
      console.log('Fetched employees data:', data);
      setEmployees(Array.isArray(data.employees) ? data.employees : []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching employees:', err);
      setError('Failed to fetch employees. Please try again later.');
      setLoading(false);
    }
  };

  const filteredEmployees = Array.isArray(employees) 
    ? employees.filter(employee =>
        employee.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const headerCells = [
    { icon: <PersonIcon />, label: 'Name', color: '#3498db' },
    { icon: <EmailIcon />, label: 'Email', color: '#e74c3c' },
    { icon: <PhoneIcon />, label: 'Phone', color: '#2ecc71' },
    { icon: <WorkIcon />, label: 'Position', color: '#f39c12' },
    { icon: <LocationOnIcon />, label: 'Address', color: '#9b59b6' },
    { icon: <EditIcon />, label: 'Actions', color: '#34495e' },
  ];

  const handleRowClick = (employeeId) => {
    console.log(`Clicking employee with id: ${employeeId}`);
    navigate(`/admin/employees/${employeeId}`);
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.employeesContainer}>
        <Typography variant="h4" className={styles.pageTitle} mb={4}>
          <GroupIcon className={styles.pageTitleIcon} />
          Employees
        </Typography>
        
        <Grid container justifyContent="space-between" alignItems="center" mb={4}>
          <Grid item>
            <Box className={styles.searchContainer}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search Employees"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => navigate('/admin/create-employee')}
              className={styles.addButton}
            >
              Create Employee
            </Button>
          </Grid>
        </Grid>

        {filteredEmployees.length > 0 ? (
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableHeader}>
                  {headerCells.map((cell, index) => (
                    <TableCell key={index} className={styles.tableCell} align="center">
                      <div className={styles.headerContent}>
                        <span className={styles.headerIcon} style={{ color: cell.color }}>
                          {cell.icon}
                        </span>
                        <span className={styles.headerLabel}>{cell.label}</span>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEmployees.map((employee) => (
                  <TableRow 
                    key={employee.id} 
                    className={styles.tableRow}
                    onClick={() => handleRowClick(employee.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell className={styles.tableCell} align="center">{employee.name}</TableCell>
                    <TableCell className={styles.tableCell} align="center">{employee.email}</TableCell>
                    <TableCell className={styles.tableCell} align="center">{employee.phone}</TableCell>
                    <TableCell className={styles.tableCell} align="center">{employee.position}</TableCell>
                    <TableCell className={styles.tableCell} align="center">{employee.address}</TableCell>
                    <TableCell className={styles.tableCell} align="center">
                      <IconButton 
                        className={styles.editButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/admin/employees/${employee.id}/edit`);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No employees found.</Typography>
        )}
      </div>
    </div>
  );
};

export default Employees;
