import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getClients } from '../services/api';
import styles from './Clients.module.scss';
import { TextField, Card, CardContent, Typography, Grid, Button, Container } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';

const Clients = () => {
  console.log("Clients component rendered");
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("useEffect in Clients triggered");
    const fetchClients = async () => {
      try {
        console.log("Fetching clients...");
        const data = await getClients();
        console.log("Received clients data:", data);
        setClients(data.clients || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to fetch clients. Please try again later.');
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateClient = () => {
    navigate('/admin/create-client');
  };

  if (loading) return <div className={styles.message}>Loading...</div>;
  if (error) return <div className={styles.message}>Error: {error}</div>;

  return (
    <Container maxWidth="lg" className={styles.clientsContainer}>
      <h1>Clients</h1>
      <div className={styles.contentContainer}>
        <div className={styles.searchAndFilter}>
          <div className={styles.searchContainer}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search clients..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateClient}
            className={styles.createButton}
          >
            CREATE CLIENT
          </Button>
        </div>
      </div>
      <Grid container spacing={3} className={styles.clientGrid}>
        {filteredClients.map(client => (
          <Grid item xs={12} sm={6} md={4} key={client.id}>
            <Link to={`/admin/clients/${client.id}`} className={styles.clientLink}>
              <Card className={styles.clientCard}>
                <CardContent>
                  <BusinessIcon className={styles.clientIcon} />
                  <Typography variant="h6" component="div">
                    {client.name}
                  </Typography>
                  <Typography color="text.secondary">
                    {client.email}
                  </Typography>
                  <Typography variant="body2">
                    Projects: {client.projects?.length || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Clients;
