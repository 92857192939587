import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Box,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Tooltip,
  Button,
  TablePagination,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { 
  Search as SearchIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachMoney as AttachMoneyIcon,
  Send as SendIcon,
  Payment as PaymentIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './AdminDashInvoices.module.scss';

// Mock data (expanded)
const mockOutstandingInvoices = [
  { id: 1, client: 'Acme Corp', amount: 50000, dueDate: '2023-05-15' },
  { id: 2, client: 'Global Tech', amount: 75000, dueDate: '2023-05-20' },
  { id: 3, client: 'Innovative Solutions', amount: 30000, dueDate: '2023-05-25' },
  { id: 4, client: 'Tech Pioneers', amount: 45000, dueDate: '2023-05-30' },
  { id: 5, client: 'Future Systems', amount: 60000, dueDate: '2023-06-05' },
];

const mockSentInvoices = [
  { id: 1, client: 'Acme Corp', amount: 100000, sentDate: '2023-04-01', status: 'Paid' },
  { id: 2, client: 'Global Tech', amount: 75000, sentDate: '2023-04-05', status: 'Pending' },
  { id: 3, client: 'Innovative Solutions', amount: 50000, sentDate: '2023-04-10', status: 'Paid' },
  { id: 4, client: 'Tech Pioneers', amount: 80000, sentDate: '2023-04-15', status: 'Overdue' },
  { id: 5, client: 'Future Systems', amount: 60000, sentDate: '2023-04-20', status: 'Pending' },
];

const SummaryCard = ({ title, value, icon, color }) => (
  <Card className={styles.summaryCard}>
    <CardContent className={styles.summaryCardContent}>
      <div className={styles.summaryIconWrapper}>
        {React.cloneElement(icon, { className: styles.summaryIcon, style: { color: color } })}
      </div>
      <div className={styles.summaryTextWrapper}>
        <Typography variant="h6" component="div" className={styles.summaryTitle}>
          {title}
        </Typography>
        <Typography variant="h4" component="div" className={styles.summaryValue}>
          {value}
        </Typography>
      </div>
    </CardContent>
  </Card>
);

const InvoiceTable = ({ title, data, columns }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = sortedData.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ) &&
    (startDate === '' || new Date(item.dueDate || item.sentDate) >= new Date(startDate)) &&
    (endDate === '' || new Date(item.dueDate || item.sentDate) <= new Date(endDate))
  );

  return (
    <Box className={styles.tableWrapper}>
      <Typography variant="h6" className={styles.tableTitle}>{title}</Typography>
      <Grid container spacing={2} className={styles.filterContainer}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search invoices..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className={styles.searchField}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>
                  {column}
                  <Tooltip title={`Sort by ${column}`}>
                    <IconButton size="small" onClick={() => handleSort(column.toLowerCase())}>
                      {sortConfig.key === column.toLowerCase() ? (
                        sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon style={{ opacity: 0.3 }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column}>
                      {column.toLowerCase() === 'amount' 
                        ? `$${row[column.toLowerCase()].toLocaleString()}`
                        : column.toLowerCase() === 'status' 
                          ? <Chip label={row[column.toLowerCase()]} color={row[column.toLowerCase()] === 'Paid' ? 'success' : 'warning'} size="small" />
                          : row[column.toLowerCase()]
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

const InvoiceTrendChart = () => {
  const data = [
    { name: 'Jan', sent: 65, paid: 28 },
    { name: 'Feb', sent: 59, paid: 48 },
    { name: 'Mar', sent: 80, paid: 40 },
    { name: 'Apr', sent: 81, paid: 19 },
    { name: 'May', sent: 56, paid: 86 },
    { name: 'Jun', sent: 55, paid: 27 },
    { name: 'Jul', sent: 40, paid: 90 },
  ];

  return (
    <Box className={styles.chartWrapper}>
      <Typography variant="h6" className={styles.chartTitle}>Invoice Trends</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <RechartsTooltip />
          <Legend />
          <Line type="monotone" dataKey="sent" stroke="#8884d8" name="Sent Invoices" />
          <Line type="monotone" dataKey="paid" stroke="#82ca9d" name="Paid Invoices" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

const AdminDashInvoices = () => {
  const totalOutstanding = mockOutstandingInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
  const totalSent = mockSentInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
  const totalPaid = mockSentInvoices.filter(invoice => invoice.status === 'Paid').reduce((sum, invoice) => sum + invoice.amount, 0);
  const paymentRate = 25; // Fixed payment rate of 25%

  return (
    <div className={styles.invoicesContainer}>
      <Typography variant="h4" className={styles.pageTitle}>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3} className={styles.summaryContainer}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard 
            title="Total Outstanding" 
            value={`$${totalOutstanding.toLocaleString()}`} 
            icon={<AttachMoneyIcon />} 
            color="#f44336"  // Red
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard 
            title="Total Sent" 
            value={`$${totalSent.toLocaleString()}`} 
            icon={<SendIcon />} 
            color="#2196f3"  // Blue
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard 
            title="Total Paid" 
            value={`$${totalPaid.toLocaleString()}`} 
            icon={<PaymentIcon />} 
            color="#4caf50"  // Green
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard 
            title="Payment Rate" 
            value={`${paymentRate}%`} 
            icon={<TrendingUpIcon />} 
            color="#ff9800"  // Orange
          />
        </Grid>
      </Grid>
      <InvoiceTrendChart />
      <div className={styles.tablesGrid}>
        <InvoiceTable 
          title="Outstanding Invoices" 
          data={mockOutstandingInvoices} 
          columns={['ID', 'Client', 'Amount', 'Due Date']}
        />
        <InvoiceTable 
          title="Sent Invoices" 
          data={mockSentInvoices} 
          columns={['ID', 'Client', 'Amount', 'Sent Date', 'Status']}
        />
      </div>
    </div>
  );
};

export default AdminDashInvoices;
