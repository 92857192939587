import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Paper, Grid, Button, CircularProgress, Snackbar } from '@mui/material';
import { getInvoiceById } from '../services/api';
import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ProfessionalInvoiceTemplate from '../templates/ProfessionalInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';
import MinimalistInvoiceTemplate from '../templates/MinimalistInvoiceTemplate';
import html2pdf from 'html2pdf.js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AdminInvoiceDetails = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const saveAsPDF = () => {
    const element = document.getElementById('invoice-template');
    const opt = {
      margin: 10,
      filename: `Invoice-${invoice.id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
  
    html2pdf().from(element).set(opt).save();
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        console.log('Fetching invoice with id:', id);
        const fetchedInvoice = await getInvoiceById(id);
        console.log('Fetched invoice:', fetchedInvoice);
        setInvoice(fetchedInvoice);
      } catch (err) {
        console.error('Error fetching invoice:', err);
        setError('Failed to fetch invoice details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const renderInvoiceTemplate = () => {
    console.log('Rendering invoice template. Invoice:', invoice);
    if (!invoice || !invoice.business_details || !invoice.customer_details) {
      console.log('Invalid invoice data');
      return <Typography>Invalid invoice data</Typography>;
    }

    const templateProps = {
      ...invoice,
      businessDetails: invoice.business_details,
      customerDetails: invoice.customer_details,
      invoiceNumber: invoice.invoice_number,
      issueDate: new Date(invoice.issue_date),
      dueDate: new Date(invoice.due_date),
    };

    console.log('Template props:', templateProps);

    switch (invoice.template) {
      case 'modern':
        return <ModernInvoiceTemplate invoice={templateProps} />;
      case 'professional':
        return <ProfessionalInvoiceTemplate invoice={templateProps} />;
      case 'classic':
        return <ClassicInvoiceTemplate invoice={templateProps} />;
      case 'minimalist':
        return <MinimalistInvoiceTemplate invoice={templateProps} />;
      default:
        return <DefaultInvoiceTemplate invoice={templateProps} />;
    }
  };



  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!invoice) return <Typography>Invoice not found</Typography>;

  console.log('Rendering AdminInvoiceDetails component');

  return (
    <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Invoice Details
          </Typography>
        </Grid>
        <Grid item xs={12} id="invoice-template">
          {renderInvoiceTemplate()}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => window.print()} style={{ marginRight: '10px' }}>
            Print Invoice
          </Button>
          <Button variant="contained" color="secondary" onClick={saveAsPDF}>
            Save as PDF
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AdminInvoiceDetails;
