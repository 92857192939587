import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, Button, Divider } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import styles from './AdminSettings.module.scss';

const mockSupportSettings = {
  primarySupportNumber: '1-800-SUPPORT',
  secondarySupportNumber: '1-888-HELP-NOW',
  supportEmail: 'support@acmeinc.com',
  supportHours: 'Monday to Friday, 9 AM - 5 PM EST',
};

function AdminSettingsSupport() {
  const [supportSettings] = useState(mockSupportSettings);

  return (
    <Paper className={styles.tabPanel}>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h5" component="h2" className={styles.sectionTitle}>
          <SettingsIcon className={styles.headerIcon} />
          Support Settings
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className={styles.infoItem}>
            <div className={`${styles.iconWrapper} ${styles.phoneIcon}`}>
              <PhoneIcon className={styles.infoIcon} />
            </div>
            <Box>
              <Typography variant="subtitle2" className={styles.infoLabel}>Primary Support Number</Typography>
              <Typography variant="body1">{supportSettings.primarySupportNumber}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.infoItem}>
            <div className={`${styles.iconWrapper} ${styles.phoneIcon}`}>
              <PhoneIcon className={styles.infoIcon} />
            </div>
            <Box>
              <Typography variant="subtitle2" className={styles.infoLabel}>Secondary Support Number</Typography>
              <Typography variant="body1">{supportSettings.secondarySupportNumber}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.infoItem}>
            <div className={`${styles.iconWrapper} ${styles.emailIcon}`}>
              <EmailIcon className={styles.infoIcon} />
            </div>
            <Box>
              <Typography variant="subtitle2" className={styles.infoLabel}>Support Email</Typography>
              <Typography variant="body1">{supportSettings.supportEmail}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.infoItem}>
            <div className={`${styles.iconWrapper} ${styles.timeIcon}`}>
              <AccessTimeIcon className={styles.infoIcon} />
            </div>
            <Box>
              <Typography variant="subtitle2" className={styles.infoLabel}>Support Hours</Typography>
              <Typography variant="body1">{supportSettings.supportHours}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.divider} />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChatIcon />}
            fullWidth
          >
            Open Support Chat
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AdminSettingsSupport;
