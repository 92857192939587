import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Grid, 
  Typography, 
  Paper, 
  CircularProgress, 
  InputAdornment,
  Box,
  Fade
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createProject } from '../services/api';
import { useNavigate } from 'react-router-dom';
import ProjectIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import styles from './CreateProjectForm.module.scss';

const CreateProjectForm = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState({
    name: '',
    scopeOfWork: '',
    address: '',
    start_date: null,
    end_date: null,
    total_value: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProject(prevProject => ({
      ...prevProject,
      [name]: name === 'total_value' ? parseFloat(value) || '' : value
    }));
  };

  const handleDateChange = (name) => (date) => {
    setProject(prevProject => ({
      ...prevProject,
      [name]: date
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const formattedProject = {
        ...project,
        start_date: project.start_date ? project.start_date.toISOString().slice(0, 16) : null,
        end_date: project.end_date ? project.end_date.toISOString().slice(0, 16) : null,
      };
      await createProject(formattedProject);
      setSuccess(true);
      setTimeout(() => {
        navigate('/admin/projects');
      }, 2000);
    } catch (error) {
      console.error('Failed to create project:', error);
      setError('Failed to create project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const inputStyle = {
    backgroundColor: 'white',
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
      '&:hover, &.Mui-focused': {
        backgroundColor: 'white',
      },
    },
  };

  if (success) {
    return (
      <Fade in={success}>
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className={styles.checkmark__circle} cx="26" cy="26" r="25" fill="none"/>
              <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            <Typography variant="h4" className={styles.successMessage}>
              Project Created Successfully!
            </Typography>
          </div>
        </div>
      </Fade>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: '30px', maxWidth: '600px', margin: '40px auto', backgroundColor: '#f2f2f2' }}>
      <Box display="flex" alignItems="center" mb={3}>
        <ProjectIcon fontSize="large" color="primary" style={{ marginRight: '16px' }} />
        <Typography variant="h4" component="h1">
          Create New Project
        </Typography>
      </Box>
      {error && <Typography color="error" mb={2}>{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              label="Project Name"
              name="name"
              value={project.name}
              onChange={handleChange}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkIcon style={{ color: '#1976d2' }} />
                  </InputAdornment>
                ),
              }}
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              label="Scope of Work"
              name="scopeOfWork"
              value={project.scopeOfWork}
              onChange={handleChange}
              required
              multiline
              rows={4}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon style={{ color: '#4caf50' }} />
                  </InputAdornment>
                ),
              }}
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              label="Address"
              name="address"
              value={project.address}
              onChange={handleChange}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon style={{ color: '#ff9800' }} />
                  </InputAdornment>
                ),
              }}
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              label="Total Value"
              name="total_value"
              type="number"
              value={project.total_value}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon style={{ color: '#9c27b0' }} />
                  </InputAdornment>
                ),
                inputProps: { min: 0, step: 0.01 }
              }}
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Date"
                value={project.start_date}
                onChange={handleDateChange('start_date')}
                renderInput={(params) => <TextField {...params} fullWidth variant="filled" sx={inputStyle} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End Date"
                value={project.end_date}
                onChange={handleDateChange('end_date')}
                renderInput={(params) => <TextField {...params} fullWidth variant="filled" sx={inputStyle} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth
              disabled={isLoading}
              size="large"
            >
              {isLoading ? <CircularProgress size={24} /> : 'Create Project'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateProjectForm;
