import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  Box,
  Button,
  Dialog,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Zoom,
  Fade
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DrawIcon from '@mui/icons-material/Draw';
import DefaultTemplate from '../templates/DefaultTemplate';
import ModernTemplate from '../templates/ModernTemplate';
import ClassicTemplate from '../templates/ClassicTemplate';
import html2pdf from 'html2pdf.js';

const PublicEstimateView = () => {
  const { uuid } = useParams();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSignDialog, setShowSignDialog] = useState(false);
  const [signatureType, setSignatureType] = useState('draw'); // 'draw' or 'type'
  const [typedName, setTypedName] = useState('');
  const signatureRef = useRef();

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        console.log('Fetching public estimate with UUID:', uuid);
        const response = await fetch(`/api/public/estimates/${uuid}`);
        
        if (!response.ok) {
          throw new Error('Estimate not found');
        }
        
        const data = await response.json();
        console.log('Fetched estimate data:', data);

        // Format the estimate data with business details
        const formattedEstimate = {
          ...data,
          businessDetails: {
            company_name: data.business_details?.company_name || '',
            owner_name: data.business_details?.owner_name || '',
            address: data.business_details?.address || '',
            city: data.business_details?.city || '',
            state: data.business_details?.state || '',
            zip_code: data.business_details?.zip_code || '',
            phone: data.business_details?.phone || '',
            email: data.business_details?.email || '',
            website: data.business_details?.website || '',
          },
          // Keep existing data structure
          customer_details: data.customer_details || {},
          items: data.items || [],
          project_images: data.project_images || []
        };
        
        console.log('Formatted estimate:', formattedEstimate);
        setEstimate(formattedEstimate);
      } catch (error) {
        console.error('Error fetching estimate:', error);
        setError('Failed to load estimate. Please check the URL and try again.');
      } finally {
        setLoading(false);
      }
    };

    if (uuid) {
      fetchEstimate();
    }
  }, [uuid]);

  useEffect(() => {
    // Preload the font
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    const style = document.createElement('style');
    style.textContent = `
      @font-face {
        font-family: 'Great Vibes';
        src: url('https://fonts.gstatic.com/s/greatvibes/v14/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2') format('woff2');
        font-weight: 400;
        font-style: normal;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
    };
  }, []);

  const renderEstimateTemplate = () => {
    if (!estimate) return null;

    return <DefaultTemplate estimate={estimate} />;
  };

  const generateTypedSignature = async (name) => {
    // Create a temporary div to verify font loading
    const testDiv = document.createElement('div');
    testDiv.style.fontFamily = 'Great Vibes';
    testDiv.style.opacity = '0';
    testDiv.textContent = name;
    document.body.appendChild(testDiv);

    // Force font loading with multiple weights
    try {
      await Promise.all([
        document.fonts.load('400 48px "Great Vibes"'),
        document.fonts.load('normal 48px "Great Vibes"'),
        document.fonts.load('48px "Great Vibes"')
      ]);
      
      console.log('Font loaded successfully');
    } catch (err) {
      console.error('Font loading error:', err);
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set high DPI for better quality
    const scale = window.devicePixelRatio || 1;
    canvas.width = 500 * scale;
    canvas.height = 200 * scale;
    canvas.style.width = '500px';
    canvas.style.height = '200px';
    
    // Scale for high DPI
    ctx.scale(scale, scale);
    
    // Set white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // Set text properties
    ctx.fillStyle = 'black';
    ctx.font = '48px "Great Vibes"';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    
    // Add shadow for better appearance
    ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
    ctx.shadowBlur = 2;
    ctx.shadowOffsetY = 2;
    
    // Draw the signature
    ctx.fillText(name, canvas.width/(2*scale), canvas.height/(2*scale));
    
    // Clean up
    document.body.removeChild(testDiv);
    
    return canvas.toDataURL('image/png', 1.0);
  };

  const handleSign = async () => {
    try {
      let signatureData;
      let signedName = typedName;
      
      if (signatureType === 'draw') {
        if (!signatureRef.current || signatureRef.current.isEmpty()) {
          alert('Please provide a signature');
          return;
        }
        signatureData = signatureRef.current.toDataURL('image/png');
      } else {
        if (!typedName.trim()) {
          alert('Please type your name');
          return;
        }
        signatureData = await generateTypedSignature(typedName);
      }

      console.log('About to send signature data:', {
        uuid,
        signatureType,
        hasName: Boolean(signedName),
        hasSignature: Boolean(signatureData)
      });

      const response = await fetch(`/api/public/estimates/${uuid}/client-sign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          client_signature: signatureData,
          client_signed_at: new Date().toISOString(),
          client_signed_name: signedName,
          client_signature_type: signatureType
        }),
      });

      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error(errorData.message || 'Failed to save signature');
      }

      const updatedEstimate = await response.json();
      console.log('Received updated estimate:', updatedEstimate);
      
      setEstimate(updatedEstimate);
      setShowSignDialog(false);
      
      const refreshResponse = await fetch(`/api/public/estimates/${uuid}`);
      if (refreshResponse.ok) {
        const refreshedData = await refreshResponse.json();
        setEstimate(refreshedData);
      }
      
      alert('Signature saved successfully!');
      
    } catch (error) {
      console.error('Error signing estimate:', error);
      alert(`Failed to save signature: ${error.message}`);
    }
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('estimate-template');
    const opt = {
      margin: 10,
      filename: `estimate-${estimate?.estimate_number || 'download'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        logging: true
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait' 
      }
    };

    // Show loading state
    setLoading(true);

    html2pdf().from(element).set(opt).save()
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error('PDF generation failed:', error);
        setLoading(false);
        alert('Failed to generate PDF. Please try again.');
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '2rem 1rem',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          overflow: 'hidden',
          marginBottom: '3rem'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#424242',
            color: 'white',
            padding: '1rem 2rem',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
          }}
        >
          <Typography variant="h5">
            Estimate #{estimate?.estimate_number}
          </Typography>
        </Box>

        <Box
          sx={{
            padding: '2rem',
            '@media (max-width: 600px)': {
              padding: '1rem'
            }
          }}
        >
          <div id="estimate-template">
            {renderEstimateTemplate()}
          </div> 
        </Box>

        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '2rem',
            borderTop: '1px solid #eee',
            gap: 2,
            flexWrap: 'wrap'
          }}
        >
          {estimate?.client_signature ? (
            <Fade in={true}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                <Zoom in={true}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      backgroundColor: '#2E7D32',
                      color: 'white',
                      padding: '12px 24px',
                      borderRadius: '50px',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(46, 125, 50, 0.3)'
                      }
                    }}
                  >
                    <CheckCircleOutlineIcon 
                      sx={{ 
                        fontSize: 24,
                        color: 'white',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1)',
                            opacity: 1
                          },
                          '50%': {
                            transform: 'scale(1.1)',
                            opacity: 0.8
                          },
                          '100%': {
                            transform: 'scale(1)',
                            opacity: 1
                          }
                        }
                      }} 
                    />
                    <Typography 
                      variant="subtitle1"
                      sx={{
                        color: 'white',
                        fontWeight: 500,
                        letterSpacing: '0.5px'
                      }}
                    >
                      Estimate has been signed successfully
                    </Typography>
                  </Paper>
                </Zoom>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#394450',
                    borderRadius: '50px',
                    padding: '12px 24px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#616161',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}
                  onClick={handleDownloadPDF}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : <FileDownloadIcon />}
                >
                  {loading ? 'Generating PDF...' : 'Download PDF'}
                </Button>
              </Box>
            </Fade>
          ) : (
            <Zoom in={true}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#424242',
                  borderRadius: '50px',
                  padding: '12px 24px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#616161',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                  }
                }}
                onClick={() => setShowSignDialog(true)}
                startIcon={<DrawIcon />}
              >
                Sign Estimate
              </Button>
            </Zoom>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#f5f5f5',
          padding: '0.5rem',
          textAlign: 'center',
          borderTop: '1px solid #e0e0e0'
        }}
      >
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#666',
            fontSize: '0.875rem',
            '& a': {
              color: '#424242',
              textDecoration: 'none',
              fontWeight: 500,
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }}
        >
          Powered by <a href="https://servtrackr.com" target="_blank" rel="noopener noreferrer">ServTrackr</a>
        </Typography>
      </Box>

      <Dialog 
        open={showSignDialog} 
        onClose={() => setShowSignDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '8px'
          }
        }}
      >
        <Box p={3}>
          <Typography variant="h6" gutterBottom>
            Sign Estimate
          </Typography>
          
          <Tabs
            value={signatureType}
            onChange={(e, newValue) => setSignatureType(newValue)}
            centered
            sx={{ 
              mb: 2,
              '& .MuiTabs-indicator': {
                backgroundColor: '#424242'
              },
              '& .Mui-selected': {
                color: '#424242'
              }
            }}
          >
            <Tab value="draw" label="Draw Signature" />
            <Tab value="type" label="Type Signature" />
          </Tabs>

          {signatureType === 'draw' ? (
            <Paper 
              elevation={3} 
              style={{ 
                border: '1px solid #ccc', 
                marginBottom: 20, 
                background: '#fff',
                width: '100%',
                height: '200px'
              }}
            >
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: 'signature-canvas',
                  style: {
                    width: '100%',
                    height: '200px',
                    border: '1px solid #ccc'
                  }
                }}
                backgroundColor="white"
              />
            </Paper>
          ) : (
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Type your name"
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Paper 
                elevation={3} 
                style={{ 
                  border: '1px solid #ccc', 
                  padding: '20px', 
                  marginTop: '10px',
                  minHeight: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff'
                }}
              >
                <Typography 
                  style={{ 
                    fontFamily: '"Great Vibes", cursive',
                    fontSize: '48px',
                    fontWeight: 400,
                    lineHeight: 1.2,
                    color: '#000'
                  }}
                >
                  {typedName}
                </Typography>
              </Paper>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" mt={2}>
            {signatureType === 'draw' && (
              <Button 
                onClick={() => signatureRef.current.clear()}
                variant="outlined"
                sx={{
                  color: '#424242',
                  borderColor: '#424242',
                  '&:hover': {
                    borderColor: '#616161'
                  }
                }}
              >
                Clear
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#424242',
                '&:hover': {
                  backgroundColor: '#616161'
                }
              }}
              onClick={handleSign}
              disabled={signatureType === 'type' && !typedName.trim()}
            >
              Submit Signature
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PublicEstimateView;
