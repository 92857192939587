import axios from 'axios';

const api = axios.create({
  baseURL: 'https://teams.everprocleaning.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export const login = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    console.log('Login response:', response);
    return response;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('Error request:', error.request);
    }
    throw error;
  }
};
export const getAdminDashboard = () => api.get('/admin_dashboard');
export const getProjects = () => api.get('/projects');
export const createProject = (projectData) => api.post('/create_project', projectData);
export const getProjectDetails = (id) => api.get(`/projects/${id}`);

// Error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API error:', error.response ? error.message : error.message);
    throw error;
  }
);

export const updateProjectSchedule = async (projectId, scheduleData) => {
  try {
    const response = await api.put(`/projects/${projectId}/schedule`, scheduleData);
    return response.data;
  } catch (error) {
    console.error('Error updating project schedule:', error);
    throw error;
  }
};

export const updateProjectScopeOfWork = async (projectId, scopeOfWork) => {
  const response = await api.put(`/projects/${projectId}/scope-of-work`, { scope_of_work: scopeOfWork });
  return response.data;
};

export const getEmployees = async () => {
  try {
    const response = await api.get('/employees');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch employees:', error);
    throw error;
  }
};

export const assignEmployeesToProject = async (projectId, employees) => {
  console.log('Calling assignEmployeesToProject with:', { projectId, employees });
  try {
    const response = await api.post(`/projects/${projectId}/assign_employees`, { employees });
    console.log('Assignment result:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error assigning employees to project:', error.response?.data || error.message);
    throw error;
  }
};

export const getProjectEmployees = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/employees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching project employees:', error);
    throw error;
  }
};

export const assignEmployeesToEvent = async (eventId, employees) => {
  try {
    const response = await api.post(`/events/${eventId}/assign_employees`, { employees });
    return response.data;
  } catch (error) {
    console.error('Error assigning employees to event:', error);
    throw error;
  }
};

export const getEventEmployees = async (eventId) => {
  try {
    const response = await api.get(`/events/${eventId}/employees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event employees:', error);
    throw error;
  }
};

export const getProjectPhotos = async (projectId) => {
  const response = await api.get(`/projects/${projectId}/photos`);
  return response.data;
};

export const uploadProjectPhoto = async (projectId, formData) => {
  try {
    const response = await api.post(`/projects/${projectId}/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading project photo:', error);
    throw error;
  }
};

export const getProjectTimesheets = async () => {
  try {
    const response = await api.get('/employee/timesheets');
    if (response.data && response.data.timesheets) {
      return response.data;
    } else {
      throw new Error('Invalid data structure received from server');
    }
  } catch (error) {
    console.error('Error fetching timesheets:', error);
    throw error;
  }
};

export const getProjectFinancials = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/financials`);
    console.log("Frontend API: Raw response:", response);
    console.log("Frontend API: Financial data received:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching project financials:", error);
    throw error;
  }
};

export const getMaterialCosts = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/material-costs`);
    console.log('Material costs response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching material costs:', error);
    throw error;
  }
};

export const updateProjectMaterialCost = async (projectId, materialCost) => {
  const response = await api.put(`/projects/${projectId}/material-cost`, { material_cost: materialCost });
  return response.data;
};

export const getMaterialCost = async (projectId, costId) => {
  console.log(`Fetching material cost for project ${projectId}, cost ${costId}`);
  try {
    const response = await api.get(`/projects/${projectId}/material-costs/${costId}`);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const updateMaterialCost = async (projectId, costId, updatedCost) => {
  try {
    const response = await api.put(`/projects/${projectId}/material-costs/${costId}`, updatedCost);
    return response.data;
  } catch (error) {
    console.error('Error updating material cost:', error);
    throw error;
  }
};

export const updateProjectDetails = async (projectId, updatedDetails) => {
  try {
    const response = await api.put(`/projects/${projectId}`, updatedDetails);
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error.response?.data || error.message);
    throw error;
  }
};

export const getCoordinatesFromAddress = async (address) => {
  try {
    const response = await api.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`);
    const data = response.data;
    if (data && data.length > 0) {
      return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
    }
    throw new Error('Unable to get coordinates for the address');
  } catch (error) {
    console.error('Error getting coordinates:', error);
    throw error;
  }
};

export const createEvent = async (eventData) => {
  try {
    const response = await api.post('/events', eventData);
    return response.data;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const getProjectEvents = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/events`);
    console.log('Received events:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching project events:', error);
    throw error;
  }
};

export const getServerTime = async () => {
  try {
    const response = await api.get('/server-time');
    return new Date(response.data);
  } catch (error) {
    console.error('Error fetching server time:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await api.put(`/events/${eventId}`, eventData);
    return response.data;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

export const getEventDetails = async (eventId) => {
  try {
    console.log(`Fetching details for event ID: ${eventId}`);
    const response = await api.get(`/events/${eventId}`);
    console.log('Event details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching event details:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const response = await api.delete(`/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

export const createEmployee = async (employeeData) => {
  try {
    const response = await api.post('/employee', employeeData);
    return response.data;
  } catch (error) {
    console.error('Error creating employee:', error);
    throw error;
  }
};

export const getEvents = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/events`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const getPayrollData = async () => {
  try {
    const response = await api.get('/payroll');
    return response.data;
  } catch (error) {
    console.error('Error fetching payroll data:', error);
    throw error;
  }
};

export const getEmployeeDashboard = async () => {
  try {
    const response = await api.get('/employee_dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee dashboard:', error);
    throw error;
  }
};

export const clockInToEvent = async (eventId) => {
  const response = await api.post(`/clock_in/${eventId}`);
  return response.data;
};

export const clockOutFromEvent = async () => {
  const response = await api.post('/clock_out');
  return response.data;
};


export const fetchClientProjects = async () => {
  try {
    console.log('Fetching client projects...');
    const response = await api.get('/client/projects');
    console.log('Client projects response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching client projects:', error);
    throw error;
  }
};

export const fetchClientProjectDetails = async (projectId) => {
  try {
    const response = await api.get(`/client/projects/${projectId}`);
    return response.data.project;
  } catch (error) {
    console.error('Error fetching client project details:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Project not found');
    }
    throw new Error('Failed to fetch project details');
  }
};

export const shareProjectWithClient = async (projectId) => {
  try {
    const response = await api.post(`api/projects/${projectId}/share`);
    return response.data;
  } catch (error) {
    console.error('Error sharing project with client:', error);
    throw error;
  }
};

export const getClients = async (page = 1, perPage = 10, projectId = null) => {
  console.log("Calling getClients");
  try {
    let url = '/clients';
    const params = { page, per_page: perPage };
    if (projectId) {
      params.project_id = projectId;
    }
    const response = await api.get(url, { params });
    console.log("Received clients:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getClients:", error);
    throw error;
  }
};

export const createClient = async (clientData) => {
  try {
    console.log('Sending create client request with data:', clientData);
    const response = await api.post('/clients', clientData);
    console.log('Create client response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in createClient:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const getEmployeeTimesheets = async () => {
  try {
    const response = await api.get('/employee/timesheets');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee timesheets:', error);
    throw error;
  }
};

export const getTimesheetRate = async (timesheetId) => {
  try {
    const response = await api.get(`/timesheet/${timesheetId}/rate`);
    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet rate:', error);
    throw error;
  }
};

export const getEmployeeName = async (employeeId) => {
  try {
    const response = await axios.get(`/api/employees/${employeeId}/name`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee name:', error);
    throw error;
  }
};

// Function to get employee availability
export const getEmployeeAvailability = async () => {
  try {
    const response = await api.get('/employee/availability');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee availability:', error);
    throw error;
  }
};

// Function to update employee availability
export const updateEmployeeAvailability = async (availabilityData) => {
  try {
    const response = await api.post('/employee/availability', availabilityData);
    return response.data;
  } catch (error) {
    console.error('Error updating employee availability:', error);
    throw error;
  }
};

export const getEmployeeProfile = async () => {
  try {
    const response = await api.get('/employee/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee profile:', error);
    throw error;
  }
};

export const resetPassword = async (newPassword) => {
  try {
    const response = await api.post('/employee/reset-password', { newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const getAdminTimesheets = async () => {
  try {
    const response = await api.get('/admin/timesheets');
    return response.data;
  } catch (error) {
    console.error('Error fetching admin timesheets:', error);
    throw error;
  }
};

export const getAdminCalendarEvents = () => api.get('/admin/calendar');

export const getClientDetails = async (id) => {
  console.log("Calling getClientDetails for ID:", id);
  try {
    const response = await api.get(`/clients/${id}`);
    console.log("Received client data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getClientDetails:", error);
    throw error;
  }
};

// Add this function to your existing api.js file
export const updateClient = async (id, clientData) => {
  try {
    const response = await fetch(`/clients/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(clientData),
    });
    if (!response.ok) {
      throw new Error('Failed to update client');
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
};

export const shareProject = async (projectId, clientId) => {
  try {
    const response = await api.post(`/projects/${projectId}/share`, { client_id: clientId });
    return response.data;
  } catch (error) {
    console.error('Error sharing project:', error);
    throw error;
  }
};

export const getEmployeeEventDetails = async (eventId) => {
  try {
    const response = await api.get(`/employee/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee event details:', error);
    throw error;
  }
};

// Add this function to your api.js file
export const addMaterialCost = async (projectId, materialCost) => {
  try {
    const response = await api.post(`/projects/${projectId}/material-costs`, materialCost);
    return response.data; // This should be the newly added cost data
  } catch (error) {
    console.error('Error adding material cost:', error);
    throw error;
  }
};

export const deleteMaterialCost = async (projectId, costId) => {
  try {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
    const response = await axios.delete(`/projects/${projectId}/material-costs/${costId}/`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting material cost:', error);
    throw error;
  }
};

export const fetchClientDashboard = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.get('/client/dashboard', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching client dashboard:', error);
    throw error;
  }
};

export const getEmployeeDetails = async (id) => {
  try {
    console.log(`Fetching employee details for ID: ${id}`);
    const response = await api.get(`/employee/${id}/details`);
    console.log('Employee details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getEmployeeDetails:', error.response?.data || error.message);
    throw error;
  }
};

export const updateEmployeeNotes = async (employeeId, notes) => {
  try {
    const response = await api.put(`/employee/${employeeId}/notes`, { notes });
    return response.data;
  } catch (error) {
    console.error('Error updating employee notes:', error);
    throw error;
  }
};

export const getEstimates = async () => {
  console.log('Fetching estimates...');
  try {
    const response = await api.get('/estimates');
    console.log('Estimates received:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching estimates:', error);
    throw error;
  }
};


export const getItems = async () => {
  try {
    console.log('Fetching all items...');
    const response = await api.get('/items');
    console.log('All items:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const uploadLogo = async (formData) => {
  try {
    const response = await api.post('/upload_logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Upload Logo Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getLogos = async () => {
  const response = await api.get('/logos');
  return response.data;
};

export const deleteLogo = async (logoId) => {
  const response = await api.delete(`/logos/${logoId}`);
  return response.data;
};

// Replace the existing getBusinessDetails function
export const getBusinessDetails = async () => {
  try {
    const response = await api.get('/business_profile');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404 && error.response.data.code === 'PROFILE_NOT_FOUND') {
      console.log('No business profile found');
      return null;
    }
    console.error('Error fetching business details:', error);
    throw error;
  }
};

// Replace the existing updateBusinessDetails function
export const updateBusinessDetails = async (formData) => {
  try {
    const response = await api.put('/business_profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating business details:', error);
    throw error;
  }
};

export const createItem = async (itemData) => {
  try {
    const response = await api.post('/items', itemData);
    console.log('Create item response:', response.data);
    return response.data; // Make sure this is the created item from the backend
  } catch (error) {
    console.error('Error creating item:', error.response?.data || error.message);
    throw error;
  }
};

export const createPackage = async (packageData) => {
  try {
    const response = await api.post('/packages', packageData);
    return response.data;
  } catch (error) {
    console.error('Error creating package:', error);
    throw error;
  }
};

export const getPackages = async () => {
  try {
    const response = await api.get('/packages');
    return response.data;
  } catch (error) {
    console.error('Error fetching packages:', error);
    throw error;
  }
};

export const deleteItem = async (itemId) => {
  try {
    console.log(`Attempting to delete item with ID: ${itemId}`);
    const response = await api.delete(`/items/${itemId}`);
    console.log('Delete item response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting item:', error.response?.data || error.message);
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const response = await api.post('/invoices', invoiceData);
    console.log('Invoice created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating invoice:', error.response?.data || error.message);
    throw error;
  }
};


export const getInvoices = async () => {
  try {
    const response = await api.get('/invoices');
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getInvoices:', error);
    throw error;
  }
};

export const getInvoiceById = async (id) => {
  try {
    const response = await api.get(`/invoices/${id}`);
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getInvoiceById:', error);
    throw error;
  }
};

// Estimates
export const createEstimate = (estimateData) => api.post('/estimates', estimateData);
export const getEstimate = (id) => api.get(`/estimates/${id}`);
export const updateEstimate = (id, estimateData) => api.put(`/estimates/${id}`, estimateData);
export const getAllEstimates = () => api.get('/estimates');
export const deleteEstimate = (id) => api.delete(`/estimates/${id}`);

// Add this new function to your api.js
export const getAllProjectPhotos = () => {
  return axios.get('/api/project-photos', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const getPublicEstimate = (uuid) => {
  return axios.get(`/api/public/estimates/${uuid}`);
};

export const signEstimate = (uuid, signatureData) => {
  return axios.post(`/api/public/estimates/${uuid}/sign`, signatureData);
};

export default api;
