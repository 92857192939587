import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, TextField, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { fetchClientProjects } from '../services/api';
import styles from './ClientProjects.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FolderIcon from '@mui/icons-material/Folder';

const ClientProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const data = await fetchClientProjects();
        setProjects(data);
        setFilteredProjects(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load projects');
        setLoading(false);
      }
    };
    loadProjects();
  }, []);

  useEffect(() => {
    const filtered = projects.filter(project =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchTerm, projects]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div className={styles.projectsPage}>
      <div className={styles.header}>
        <Typography variant="h4" className={styles.welcomeText}>
          Your Projects
        </Typography>
        <div className={styles.searchBarContainer}>
          <SearchIcon className={styles.searchIcon} />
          <TextField
            className={styles.searchInput}
            placeholder="Search projects..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: { paddingLeft: '33px',}
            }}
          />
        </div>
      </div>
      <div className={styles.projectsContainer}>
        <div className={styles.projectsScrollable}>
          {filteredProjects.length > 0 ? (
            filteredProjects.map(project => (
              <Link to={`/client/projects/${project.id}`} key={project.id} className={styles.projectLink}>
                <div className={styles.projectCard}>
                  <Typography variant="h6" className={styles.projectName}>
                    <FolderIcon className={styles.folderIcon} />
                    {project.name}
                  </Typography>
                  <Typography variant="body2" className={styles.projectDescription}>
                    {project.description}
                  </Typography>
                  <div className={styles.projectDetails}>
                    <div className={styles.detailItem}>
                      <CalendarTodayIcon className={`${styles.detailIcon} ${styles.startDateIcon}`} />
                      <Typography variant="body2">
                        {project.start_time ? new Date(project.start_time).toLocaleDateString() : 'N/A'}
                      </Typography>
                    </div>
                    <div className={styles.detailItem}>
                      <EventIcon className={`${styles.detailIcon} ${styles.endDateIcon}`} />
                      <Typography variant="body2">
                        {project.end_time ? new Date(project.end_time).toLocaleDateString() : 'N/A'}
                      </Typography>
                    </div>
                    <div className={styles.detailItem}>
                      <LocationOnIcon className={`${styles.detailIcon} ${styles.locationIcon}`} />
                      <Typography variant="body2">
                        {project.address || 'N/A'}
                      </Typography>
                    </div>
                    <div className={styles.detailItem}>
                      <AttachMoneyIcon className={`${styles.detailIcon} ${styles.moneyIcon}`} />
                      <Typography variant="body2">
                        ${project.total_value ? project.total_value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <Typography className={styles.noData}>No projects found.</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientProjects;
