import React from 'react';

const DefaultInvoiceTemplate = ({ invoice }) => {
  const formatDate = (date) => {
    return date instanceof Date ? date.toLocaleDateString() : 'N/A';
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
  };

  const calculateTax = () => {
    return invoice.items.reduce((sum, item) => sum + (item.quantity * item.unitPrice * item.tax / 100), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  return (
    <div className="invoice-template">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1 style={{ margin: 0 }}>Invoice</h1>
        {invoice.logo && (
          <img src={invoice.logo} alt="Company Logo" style={{ maxWidth: '150px', maxHeight: '80px' }} />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <h3>From:</h3>
          <p><strong>{invoice.businessDetails.company_name}</strong></p>
          <p>{invoice.businessDetails.owner_name}</p>
          <p>{invoice.businessDetails.address}</p>
          <p>{invoice.businessDetails.city}, {invoice.businessDetails.state} {invoice.businessDetails.zip_code}</p>
          <p>Phone: {invoice.businessDetails.phone}</p>
          <p>Email: {invoice.businessDetails.email}</p>
        </div>
        <div>
          <h3>To:</h3>
          <p>{invoice.customerDetails.name}</p>
          <p>{invoice.customerDetails.address}</p>
          <p>{invoice.customerDetails.city}, {invoice.customerDetails.state} {invoice.customerDetails.zipCode}</p>
          <p>Phone: {invoice.customerDetails.phone}</p>
          <p>Email: {invoice.customerDetails.email}</p>
        </div>
      </div>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ padding: '10px', textAlign: 'left' }}>Description</th>
            <th style={{ padding: '10px', textAlign: 'right' }}>Quantity</th>
            <th style={{ padding: '10px', textAlign: 'right' }}>Unit Price</th>
            <th style={{ padding: '10px', textAlign: 'right' }}>Tax (%)</th>
            <th style={{ padding: '10px', textAlign: 'right' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item, index) => (
            <tr key={index}>
              <td style={{ padding: '10px' }}>{item.description}</td>
              <td style={{ padding: '10px', textAlign: 'right' }}>{item.quantity}</td>
              <td style={{ padding: '10px', textAlign: 'right' }}>${item.unitPrice.toFixed(2)}</td>
              <td style={{ padding: '10px', textAlign: 'right' }}>{item.tax}%</td>
              <td style={{ padding: '10px', textAlign: 'right' }}>${(item.quantity * item.unitPrice * (1 + item.tax / 100)).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ textAlign: 'right' }}>
        <p><strong>Subtotal:</strong> ${calculateSubtotal().toFixed(2)}</p>
        <p><strong>Tax:</strong> ${calculateTax().toFixed(2)}</p>
        <p style={{ fontSize: '1.2em' }}><strong>Total:</strong> ${calculateTotal().toFixed(2)}</p>
      </div>

      {invoice.includeNotes && (
        <div style={{ marginTop: '20px' }}>
          <h3>Notes:</h3>
          <p>{invoice.notes || 'No additional notes.'}</p>
        </div>
      )}

      {invoice.includeTerms && (
        <div style={{ marginTop: '20px' }}>
          <h3>Terms and Conditions:</h3>
          <p>{invoice.termsAndConditions || 'No terms and conditions specified.'}</p>
        </div>
      )}
    </div>
  );
};

export default DefaultInvoiceTemplate;
