import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getProjects } from '../services/api';
import styles from './Projects.module.scss';
import { Button, Menu, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getProjects();
        if (response.data && Array.isArray(response.data.projects)) {
          console.log('Project data:', response.data.projects); // Add this line
          setProjects(response.data.projects);
        } else {
          throw new Error('Invalid response format');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects. Please try again later.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const filteredProjects = projects.filter(project =>
    (project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filter === 'all' || (filter === 'active' && project.active) || (filter === 'inactive' && !project.active))
  );

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (value) => {
    if (value) {
      setFilter(value);
    }
    setAnchorEl(null);
  };

  const handleProjectClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  const handleCreateProject = () => {
    navigate('/admin/projects/create');
  };

  if (loading) return <div className={styles.message}>Loading...</div>;
  if (error) return <div className={styles.message}>Error: {error}</div>;

  return (
    <div className={styles.pageBackground}>
      <div className={styles.pageContent}>
        <h1 className={styles.title}>Projects</h1>
        <div className={styles.contentContainer}>
          <div className={styles.searchAndFilter}>
            <div className={styles.searchContainer}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search projects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button
                variant="outlined"
                className={styles.filterButton}
                startIcon={<FilterListIcon />}
                onClick={handleFilterClick}
              >
                {filter === 'all' ? 'ALL PROJECTS' : filter === 'active' ? 'Active Projects' : 'Inactive Projects'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateProject}
                className={styles.createButton}
              >
                CREATE PROJECT
              </Button>
            </div>
          </div>
          <div className={styles.projectListSection}>
            {filteredProjects.length === 0 ? (
              <p className={styles.message}>No projects found.</p>
            ) : (
              <div className={styles.projectList}>
                {filteredProjects.map(project => (
                  <div 
                    key={project.id} 
                    className={styles.projectRow}
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <div className={styles.projectInfo}>
                      <h3 className={styles.projectTitle}>{project.name || 'Unnamed Project'}</h3>
                      <p className={styles.description}>{project.description || 'No description available'}</p>
                    </div>
                    <div className={styles.projectDetails}>
                      <span className={styles.detailItem}>
                        <CalendarTodayIcon fontSize="small" />
                        {formatDate(project.start_time)} - {formatDate(project.end_time)}
                      </span>
                      <span className={styles.detailItem}>
                        <AttachMoneyIcon fontSize="small" />
                        ${project.total_value?.toLocaleString() || 'N/A'}
                      </span>
                      <span className={styles.detailItem}>
                        <LocationOnIcon fontSize="small" />
                        {project.address || 'N/A'}
                      </span>
                    </div>
                    <div className={styles.projectStatus}>
                      <span className={project.active ? styles.activeStatus : styles.inactiveStatus}>
                        {project.active ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleFilterClose()}
      >
        <MenuItem onClick={() => handleFilterClose('all')}>All Projects</MenuItem>
        <MenuItem onClick={() => handleFilterClose('active')}>Active Projects</MenuItem>
        <MenuItem onClick={() => handleFilterClose('inactive')}>Inactive Projects</MenuItem>
      </Menu>
    </div>
  );
};

export default Projects;
