import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, InputLabel, Box, Grid, Card, CardContent
} from '@mui/material';
import { getEmployeeTimesheets } from '../services/api';
import { 
  AccessTime, EventNote, AttachMoney, WorkOutline, 
  CalendarToday, HourglassEmpty, CheckCircleOutline
} from '@mui/icons-material';
import styles from './EmployeeTimesheets.module.scss';

const EmployeeTimesheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const response = await getEmployeeTimesheets();
      if (response.timesheets && Array.isArray(response.timesheets)) {
        setTimesheets(response.timesheets);
        setSummary(response.summary);
        const uniqueProjects = [...new Set(response.timesheets.map(ts => ts.event.project_name))];
        setProjects(['All', ...uniqueProjects]);
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
      setSummary(null);
    } finally {
      setLoading(false);
      // Set isLoaded to true after a short delay to trigger the animation
      setTimeout(() => setIsLoaded(true), 100);
    }
  };

  const filteredTimesheets = filter === 'All' || filter === '' 
    ? timesheets 
    : timesheets.filter(timesheet => timesheet.event.project_name === filter);

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  const headerCells = [
    { icon: <AccessTime />, label: 'Clock In', color: '#3498db' },
    { icon: <EventNote />, label: 'Clock Out', color: '#e74c3c' },
    { icon: <HourglassEmpty />, label: 'Hours Worked', color: '#f39c12' },
    { icon: <WorkOutline />, label: 'Project', color: '#9b59b6' },
    { icon: <AttachMoney />, label: 'Payment Type', color: '#2ecc71' },
    { icon: <CalendarToday />, label: 'Rate/Percentage', color: '#1abc9c' },
    { icon: <CheckCircleOutline />, label: 'Calculated Payment', color: '#2ecc71' },
  ];

  return (
    <div className={`${styles.timesheetsContainer} ${isLoaded ? styles.fadeIn : ''}`}>
  
      
      {summary && (
        <Grid container spacing={3} className={styles.summaryContainer}>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <HourglassEmpty className={styles.summaryIcon} />
                  Total Hours Worked
                </Typography>
                <Typography variant="h4">{summary.total_hours_worked.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <AttachMoney className={styles.summaryIcon} />
                  Total Calculated Payment
                </Typography>
                <Typography variant="h4">${summary.total_calculated_payment.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Box className={styles.filterContainer}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="project-filter-label">Filter by Project</InputLabel>
          <Select
            labelId="project-filter-label"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            label="Filter by Project"
          >
            {projects.map((project) => (
              <MenuItem key={project} value={project}>{project}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow className={styles.tableHeader}>
              {headerCells.map((cell, index) => (
                <TableCell key={index} className={styles.tableCell} align="center">
                  <div className={styles.headerContent}>
                    <span className={styles.headerIcon} style={{ color: cell.color }}>
                      {cell.icon}
                    </span>
                    <span className={styles.headerLabel}>{cell.label}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTimesheets.map((timesheet) => (
              <TableRow key={timesheet.id} className={styles.tableRow}>
                <TableCell className={styles.tableCell} align="center">{new Date(timesheet.clock_in).toLocaleString()}</TableCell>
                <TableCell className={styles.tableCell} align="center">{timesheet.clock_out ? new Date(timesheet.clock_out).toLocaleString() : 'Not clocked out'}</TableCell>
                <TableCell className={styles.tableCell} align="center">{timesheet.hours_worked ? timesheet.hours_worked.toFixed(2) : 'N/A'}</TableCell>
                <TableCell className={styles.tableCell} align="center">{timesheet.event.project_name || 'N/A'}</TableCell>
                <TableCell className={styles.tableCell} align="center">{timesheet.payment_type || 'N/A'}</TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.payment_type === 'percentage' 
                    ? `${timesheet.percentage}%` 
                    : timesheet.rate ? `$${timesheet.rate}${timesheet.payment_type === 'hourly' ? '/hr' : ''}` : 'N/A'
                  }
                </TableCell>
                <TableCell className={styles.tableCell} align="center">${timesheet.calculated_payment ? timesheet.calculated_payment.toFixed(2) : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmployeeTimesheets;
