import React from 'react';
import CreateProjectForm from './CreateProjectForm';

const CreateProjectPage = () => {
  return (
    <div>
      <h1>Create New Project</h1>
      <CreateProjectForm />
    </div>
  );
};

export default CreateProjectPage;
