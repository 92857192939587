import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getProjectEvents } from '../services/api';
import { Typography, Button } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Add as AddIcon, Event as EventIcon } from '@mui/icons-material';
import EventForm from './EventForm';
import EventDetails from './EventDetails';

const localizer = momentLocalizer(moment);

const ScheduleUpdater = ({ projectId, projectName }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const fetchEvents = useCallback(async () => {
    try {
      const fetchedEvents = await getProjectEvents(projectId);
      console.log('Fetched events:', fetchedEvents);
      
      const mappedEvents = fetchedEvents.map(event => {
        const startDate = new Date(event.time_slots[0].start_time);
        let endDate = new Date(event.time_slots[event.time_slots.length - 1].end_time);
        
        // Ensure end date is after start date
        if (endDate <= startDate) {
          endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Set end to 1 hour after start
        }
        
        return {
          id: event.id,
          title: `${event.emoji} ${event.title}`,
          start: startDate,
          end: endDate,
          allDay: false,
          resource: event // Store the original event data
        };
      });
      
      console.log('Mapped events:', mappedEvents);
      setEvents(mappedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchEvents();
    }
  }, [projectId, fetchEvents]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event.resource); // Use the original event data
    setIsDetailsOpen(true);
  };

  const handleSelectSlot = () => {
    setSelectedEvent(null);
    setIsFormOpen(true);
  };

  const handleEventCreated = useCallback(() => {
    fetchEvents();
    setIsFormOpen(false);
  }, [fetchEvents]);

  const handleEventUpdated = useCallback(() => {
    fetchEvents();
    setIsDetailsOpen(false);
  }, [fetchEvents]);

  const handleCloseDetails = () => {
    setIsDetailsOpen(false);
    setSelectedEvent(null);
  };

  const handleEditEvent = (updatedEvent) => {
    setSelectedEvent(updatedEvent);
    setIsDetailsOpen(false);
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Typography variant="h5">{projectName} Schedule</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedEvent(null);
              setIsFormOpen(true);
            }}
            style={{ marginRight: '10px' }}
          >
            Add Event
          </Button>
          <Button
            component={Link}
            to={`/admin/projects/${projectId}/events`}
            variant="outlined"
            color="primary"
            startIcon={<EventIcon />}
          >
            View All Events
          </Button>
        </div>
      </div>
      <div style={{ flexGrow: 1, padding: '10px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          style={{ height: '100%' }}
        />
      </div>
      <EventForm
        open={isFormOpen}
        onClose={handleCloseForm}
        projectId={projectId}
        onEventCreated={handleEventCreated}
        event={selectedEvent}
      />
      <EventDetails
        eventId={selectedEvent?.id}
        open={isDetailsOpen}
        onClose={handleCloseDetails}
        onEdit={handleEditEvent}
        onEventUpdated={handleEventUpdated}
      />
    </div>
  );
};

export default ScheduleUpdater;