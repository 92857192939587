// src/components/ClientDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getClientDetails, updateClient } from '../services/api';
import styles from './ClientDetails.module.scss';
import { TextField, Button, Typography, Paper, Grid, List, ListItem, ListItemText, Avatar, AvatarGroup, Icon, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import NoteIcon from '@mui/icons-material/Note';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SortIcon from '@mui/icons-material/Sort';

const ClientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const [documentSearchTerm, setDocumentSearchTerm] = useState('');
  const [isDocumentSearchExpanded, setIsDocumentSearchExpanded] = useState(false);
  const [documentSortBy, setDocumentSortBy] = useState('name');
  const [isLoaded, setIsLoaded] = useState(false);

  // Dummy data for documents
  const dummyDocuments = [
    { id: 1, name: 'Contract.pdf', type: 'PDF', size: '2.5 MB', lastModified: '2023-04-15' },
    { id: 2, name: 'Invoice_001.docx', type: 'DOCX', size: '1.2 MB', lastModified: '2023-04-20' },
    { id: 3, name: 'Project_Plan.xlsx', type: 'XLSX', size: '3.7 MB', lastModified: '2023-04-22' },
    { id: 4, name: 'Meeting_Notes.txt', type: 'TXT', size: '10 KB', lastModified: '2023-04-25' },
    { id: 5, name: 'Logo.png', type: 'PNG', size: '500 KB', lastModified: '2023-04-18' },
  ];

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const data = await getClientDetails(id);
        setClient(data);
        setEditedClient(data);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch client details:', err);
        setError(err.message || 'Failed to load client details. Please try again later.');
        setClient(null);
      } finally {
        setLoading(false);
      }
    };

    fetchClientDetails();
  }, [id]);

  useEffect(() => {
    // Set isLoaded to true after a short delay to trigger the animation
    const timer = setTimeout(() => setIsLoaded(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const updatedClient = await updateClient(id, editedClient);
      setClient(updatedClient);
      setEditMode(false);
      setError(null);
    } catch (err) {
      console.error('Failed to update client:', err);
      setError(err.message || 'Failed to update client. Please try again.');
    }
  };

  const handleProjectClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderDetailItem = (icon, label, value, colorClass) => (
    <Typography className={`${styles.detailItem} ${styles[colorClass]}`}>
      <Icon component={icon} className={styles.icon} />
      <span className={styles.label}>{label}:</span> 
      <span className={styles.value}>{value || 'N/A'}</span>
    </Typography>
  );

  const getFileIcon = (fileType) => {
    switch (fileType.toLowerCase()) {
      case 'pdf': return <PictureAsPdfIcon />;
      case 'png':
      case 'jpg':
      case 'jpeg': return <ImageIcon />;
      default: return <InsertDriveFileIcon />;
    }
  };

  const filteredProjects = client?.projects?.filter(project =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const sortedProjects = [...filteredProjects].sort((a, b) => {
    switch (sortBy) {
      case 'name':
        return a.name.localeCompare(b.name);
      case 'startDate':
        return new Date(a.start_time) - new Date(b.start_time);
      case 'status':
        return a.status.localeCompare(b.status);
      default:
        return 0;
    }
  });

  const filteredAndSortedDocuments = dummyDocuments
    .filter(doc => doc.name.toLowerCase().includes(documentSearchTerm.toLowerCase()))
    .sort((a, b) => {
      switch (documentSortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'type':
          return a.type.localeCompare(b.type);
        case 'size':
          return parseFloat(a.size) - parseFloat(b.size);
        case 'lastModified':
          return new Date(b.lastModified) - new Date(a.lastModified);
        default:
          return 0;
      }
    });

  if (loading) return <Typography className={styles.message}>Loading...</Typography>;
  if (error) return <Typography className={styles.message} color="error">Error: {error}</Typography>;
  if (!client) return <Typography className={styles.message}>No client data available</Typography>;

  return (
    <div className={`${styles.clientDetails} ${isLoaded ? styles.loaded : ''}`}>
      <Typography variant="h4" component="h1" gutterBottom className={styles.title}>
        <PersonIcon className={styles.titleIcon} />
        Client Details
      </Typography>
      <Paper elevation={3} className={styles.contentPaper}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="client details tabs">
          <Tab label="Details" />
          <Tab label="Projects" />
          <Tab label="Documents" />
        </Tabs>
        <Box p={3}>
          {tabValue === 0 && (
            <>
              {editMode ? (
                <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                  <TextField fullWidth margin="normal" name="name" label="Name" value={editedClient.name} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="email" label="Email" value={editedClient.email} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="phone" label="Phone" value={editedClient.phone || ''} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="address" label="Address" value={editedClient.address || ''} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="company" label="Company" value={editedClient.company || ''} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="notes" label="Notes" multiline rows={4} value={editedClient.notes || ''} onChange={handleInputChange} />
                  <Button type="submit" variant="contained" color="primary" className={styles.button}>Save Changes</Button>
                  <Button variant="outlined" onClick={() => setEditMode(false)} className={styles.button}>Cancel</Button>
                </form>
              ) : (
                <>
                  {renderDetailItem(PersonIcon, "Name", client.name, 'iconBlue')}
                  {renderDetailItem(EmailIcon, "Email", client.email, 'iconGreen')}
                  {renderDetailItem(PhoneIcon, "Phone", client.phone, 'iconPurple')}
                  {renderDetailItem(HomeIcon, "Address", client.address, 'iconOrange')}
                  {renderDetailItem(BusinessIcon, "Company", client.company, 'iconRed')}
                  {renderDetailItem(NoteIcon, "Notes", client.notes, 'iconTeal')}
                  {renderDetailItem(EventIcon, "Created At", new Date(client.created_at).toLocaleString(), 'iconIndigo')}
                  {renderDetailItem(UpdateIcon, "Updated At", new Date(client.updated_at).toLocaleString(), 'iconBrown')}
                  <Button onClick={() => setEditMode(true)} variant="contained" color="primary" className={styles.button}>Edit Client</Button>
                </>
              )}
            </>
          )}
          {tabValue === 1 && (
            <>
              <Typography variant="h6" className={styles.subtitle} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <FolderSharedIcon className={styles.subtitleIcon} style={{ marginRight: '8px' }} />
                Projects
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    transition: 'all 0.3s ease-in-out',
                  }}
                  onMouseEnter={() => setIsSearchExpanded(true)}
                  onMouseLeave={() => {
                    if (!searchTerm) {
                      setIsSearchExpanded(false);
                    }
                  }}
                >
                  <TextField
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                      width: isSearchExpanded ? '200px' : '40px',
                      transition: 'all 0.3s ease-in-out'
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        paddingLeft: isSearchExpanded ? '14px' : '0',
                        transition: 'all 0.3s ease-in-out'
                      }
                    }}
                  />
                </div>
                <FormControl style={{ minWidth: 120 }}>
                  <InputLabel id="sort-by-label">Sort by</InputLabel>
                  <Select
                    labelId="sort-by-label"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SortIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="startDate">Start Date</MenuItem>
                    <MenuItem value="status">Status</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {sortedProjects.length > 0 ? (
                <List className={styles.projectList}>
                  {sortedProjects.map(project => (
                    <ListItem 
                      key={project.id} 
                      button 
                      onClick={() => handleProjectClick(project.id)} 
                      style={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        marginBottom: '12px',
                        padding: '16px',
                        transition: 'background-color 0.3s'
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div>
                          <Typography variant="subtitle1">{project.name}</Typography>
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px', color: '#2196f3' }}>
                              <CalendarTodayIcon style={{ fontSize: '1rem', marginRight: '4px' }} />
                              <span>{project.start_time ? new Date(project.start_time).toLocaleDateString() : 'N/A'}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#4caf50' }}>
                              <AssignmentIcon style={{ fontSize: '1rem', marginRight: '4px' }} />
                              <span>{project.status || 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                        {project.employees && project.employees.length > 0 && (
                          <AvatarGroup max={3} style={{ marginLeft: '16px' }}>
                            {project.employees.map(employee => (
                              <Avatar key={employee.id} alt={employee.name} src={employee.avatar_url} />
                            ))}
                          </AvatarGroup>
                        )}
                      </div>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No matching projects found</Typography>
              )}
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6" gutterBottom className={styles.subtitle}>
                <DescriptionIcon className={styles.subtitleIcon} />
                Client Documents
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    transition: 'all 0.3s ease-in-out',
                  }}
                  onMouseEnter={() => setIsDocumentSearchExpanded(true)}
                  onMouseLeave={() => {
                    if (!documentSearchTerm) {
                      setIsDocumentSearchExpanded(false);
                    }
                  }}
                >
                  <TextField
                    placeholder="Search documents..."
                    value={documentSearchTerm}
                    onChange={(e) => setDocumentSearchTerm(e.target.value)}
                    style={{
                      width: isDocumentSearchExpanded ? '250px' : '40px',
                      transition: 'all 0.3s ease-in-out'
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: isDocumentSearchExpanded ? 'inherit' : '#757575' }} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingLeft: isDocumentSearchExpanded ? '14px' : '0',
                        transition: 'all 0.3s ease-in-out'
                      }
                    }}
                  />
                </div>
                <FormControl style={{ minWidth: 120 }}>
                  <InputLabel id="document-sort-by-label">Sort by</InputLabel>
                  <Select
                    labelId="document-sort-by-label"
                    value={documentSortBy}
                    onChange={(e) => setDocumentSortBy(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SortIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="type">Type</MenuItem>
                    <MenuItem value="size">Size</MenuItem>
                    <MenuItem value="lastModified">Last Modified</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <TableContainer component={Paper}>
                <Table className={styles.table} aria-label="client documents table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Size</TableCell>
                      <TableCell align="right">Last Modified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredAndSortedDocuments.map((doc) => (
                      <TableRow key={doc.id}>
                        <TableCell component="th" scope="row">
                          <div className={styles.fileNameCell}>
                            {getFileIcon(doc.type)}
                            <span>{doc.name}</span>
                          </div>
                        </TableCell>
                        <TableCell align="right">{doc.type}</TableCell>
                        <TableCell align="right">{doc.size}</TableCell>
                        <TableCell align="right">{doc.lastModified}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default ClientDetails;
