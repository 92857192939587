import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography,
  Box
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';

const dummyPayrollData = [
  { id: 1, name: 'John Doe', hours: 40, rate: 25, total: 1000, project: 'Website Redesign', event: 'Sprint 1' },
  { id: 2, name: 'Jane Smith', hours: 35, rate: 30, total: 1050, project: 'Mobile App', event: 'Development Phase' },
  { id: 3, name: 'Bob Johnson', hours: 45, rate: 22, total: 990, project: 'Database Migration', event: 'Planning' },
  { id: 4, name: 'Alice Brown', hours: 38, rate: 28, total: 1064, project: 'UI/UX Design', event: 'Client Meeting' },
];

const Payroll = () => {
  const [payrollData, setPayrollData] = useState([]);

  useEffect(() => {
    // In a real application, you would fetch data from an API here
    setPayrollData(dummyPayrollData);
  }, []);

  const HeaderCell = ({ icon, text, color, align = 'left' }) => (
    <TableCell align={align} style={{ padding: '16px' }}>
      <Box display="flex" alignItems="center" justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}>
        {React.cloneElement(icon, { style: { color, marginRight: 8 } })}
        <Typography variant="subtitle2">{text}</Typography>
      </Box>
    </TableCell>
  );

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Payroll
      </Typography>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <HeaderCell icon={<PersonIcon />} text="Name" color="#1976d2" />
              <HeaderCell icon={<FolderIcon />} text="Project" color="#388e3c" />
              <HeaderCell icon={<EventIcon />} text="Event" color="#d32f2f" />
              <HeaderCell icon={<AccessTimeIcon />} text="Hours" color="#f57c00" align="right" />
              <HeaderCell icon={<AttachMoneyIcon />} text="Rate ($)" color="#7b1fa2" align="right" />
              <HeaderCell icon={<ReceiptIcon />} text="Total ($)" color="#0288d1" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {payrollData.map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ padding: '16px' }}>{row.name}</TableCell>
                <TableCell style={{ padding: '16px' }}>{row.project}</TableCell>
                <TableCell style={{ padding: '16px' }}>{row.event}</TableCell>
                <TableCell align="right" style={{ padding: '16px' }}>{row.hours}</TableCell>
                <TableCell align="right" style={{ padding: '16px' }}>{row.rate}</TableCell>
                <TableCell align="right" style={{ padding: '16px' }}>{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Payroll;
