import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  Description, AttachMoney, Business,
  CalendarToday, CheckCircleOutline, HourglassEmpty,
  Add as AddIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styles from './AdminEstimates.module.scss';
import { getEstimates, getBusinessDetails } from '../services/api';

const AdminEstimates = () => {
  const navigate = useNavigate();

  const [estimates, setEstimates] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Pending', 'Approved', 'Rejected']);
  const [estimate, setEstimate] = useState({
    businessDetails: {},
    customerDetails: {},
    estimateNumber: '',
    issueDate: new Date(),
    validUntil: new Date(new Date().setDate(new Date().getDate() + 30)),
    items: [],
    notes: '',
    logo: null,
    logoSize: 100,
    scopeOfWork: '',
    pictures: [],
    template: 'default',
  });

  const fetchBusinessDetails = useCallback(async () => {
    console.log('Fetching business details...');
    try {
      const details = await getBusinessDetails();
      console.log('Fetched business details:', details);
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: details
      }));
    } catch (error) {
      console.error('Error fetching business details:', error);
      // Fallback to empty object if fetch fails
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: {}
      }));
    }
  }, []);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  const fetchEstimates = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getEstimates();
      console.log('Fetched estimates:', data);
      if (data && Array.isArray(data)) {
        setEstimates(data);
        const uniqueClients = [...new Set(data.map(est => 
          est.client?.company || 
          est.customerDetails?.company || 
          est.customer_details?.company ||
          est.customer?.company
        ).filter(Boolean))];
        setClients(['All', ...uniqueClients]);
      } else {
        console.log('Invalid data structure');
        setEstimates([]);
        setClients(['All']);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching estimates:', err);
      setError('Failed to fetch estimates. Please try again later.');
      setEstimates([]);
      setClients(['All']);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEstimates();
  }, [fetchEstimates]);

  const calculateEstimateTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const unitPrice = Number(item.unit_price || item.unitPrice) || 0;
      const tax = Number(item.tax) || 0;
      
      const itemTotal = quantity * unitPrice * (1 + tax/100);
      return sum + itemTotal;
    }, 0);
  };

  const filteredEstimates = estimates.filter(estimate => {
    const clientMatch = clientFilter === 'All' || 
      estimate.client?.company === clientFilter ||
      estimate.customerDetails?.company === clientFilter;
    const statusMatch = statusFilter === 'All' || estimate.status === statusFilter;
    const dateMatch = (!startDate || new Date(estimate.issue_date) >= startDate) &&
                     (!endDate || new Date(estimate.issue_date) <= endDate);
    return clientMatch && statusMatch && dateMatch;
  });

  const totalEstimateValue = filteredEstimates.reduce((sum, estimate) => 
    sum + calculateEstimateTotal(estimate.items), 0);
    
  const averageEstimateValue = filteredEstimates.length > 0 
    ? totalEstimateValue / filteredEstimates.length 
    : 0;

  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  const getClientName = (estimate) => {
    return estimate.client?.company || 
           estimate.customerDetails?.company ||
           estimate.customer_details?.company ||
           estimate.customer?.company ||
           'N/A';
  };

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  const headerCells = [
    { icon: <Description />, label: 'Estimate ID', color: '#3498db' },
    { icon: <Business />, label: 'Client', color: '#e74c3c' },
    { icon: <AttachMoney />, label: 'Total Value', color: '#f39c12' },
    { icon: <CalendarToday />, label: 'Created At', color: '#16a085' },
    { icon: <CheckCircleOutline />, label: 'Status', color: '#2ecc71' },
  ];

  console.log('Rendered estimates:', estimates); // Keep this log for debugging

  return (
    <div className={styles.estimatesContainer}>
      <h1 className={styles.estimatesTitle}>Estimates</h1>
      {estimates.length > 0 ? (
        <>
          <Grid container spacing={3} className={styles.summaryContainer}>
            <Grid item xs={12} md={4}>
              <Card className={styles.summaryCard}>
                <CardContent>
                  <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                    <HourglassEmpty className={styles.summaryIcon} />
                    Total Estimates
                  </Typography>
                  <Typography variant="h4">{filteredEstimates.length}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={styles.summaryCard}>
                <CardContent>
                  <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                    <AttachMoney className={styles.summaryIcon} />
                    Total Estimate Value
                  </Typography>
                  <Typography variant="h4">${formatCurrency(totalEstimateValue)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={styles.summaryCard}>
                <CardContent>
                  <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                    <AttachMoney className={styles.summaryIcon} />
                    Average Estimate Value
                  </Typography>
                  <Typography variant="h4">${formatCurrency(averageEstimateValue)}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box className={styles.filterAndButtonContainer}>
            <Box className={styles.filterContainer}>
              <Box className={styles.filterWrapper}>
                <Typography variant="subtitle2" className={styles.filterLabel}>Client</Typography>
                <FormControl variant="outlined" className={styles.filterSelect}>
                  <Select
                    value={clientFilter}
                    onChange={(e) => setClientFilter(e.target.value)}
                  >
                    {clients.map((client) => (
                      <MenuItem key={client} value={client}>{client}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className={styles.filterWrapper}>
                <Typography variant="subtitle2" className={styles.filterLabel}>Status</Typography>
                <FormControl variant="outlined" className={styles.filterSelect}>
                  <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box className={styles.filterWrapper}>
                  <Typography variant="subtitle2" className={styles.filterLabel}>Start Date</Typography>
                  <DatePicker
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} className={styles.datePicker} />}
                  />
                </Box>
                <Box className={styles.filterWrapper}>
                  <Typography variant="subtitle2" className={styles.filterLabel}>End Date</Typography>
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} className={styles.datePicker} />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box className={styles.buttonContainer}>
              <Button 
                variant="contained" 
                color="primary" 
                className={styles.createButton}
                startIcon={<AddIcon />}
                onClick={() => navigate('/admin/estimates/create')}
              >
                Create New Estimate
              </Button>
            </Box>
          </Box>

          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableHeader}>
                  {headerCells.map((cell, index) => (
                    <TableCell key={index} className={styles.tableCell} align="center">
                      <div className={styles.headerContent}>
                        <span className={styles.headerIcon} style={{ color: cell.color }}>
                          {cell.icon}
                        </span>
                        <span className={styles.headerLabel}>{cell.label}</span>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEstimates.map((estimate) => {
                  const clientName = getClientName(estimate);
                  console.log('Client data:', { 
                    client: estimate.client,
                    customerDetails: estimate.customerDetails,
                    customer_details: estimate.customer_details,
                    customer: estimate.customer,
                    displayName: clientName
                  });

                  return (
                    <TableRow 
                      key={estimate.id} 
                      className={styles.tableRow} 
                      onClick={() => navigate(`/admin/estimates/${estimate.id}`)}
                    >
                      <TableCell className={styles.tableCell} align="center">
                        {estimate.estimate_number}
                      </TableCell>
                      <TableCell className={styles.tableCell} align="center">
                        {clientName}
                      </TableCell>
                      <TableCell className={styles.tableCell} align="center">
                        ${formatCurrency(calculateEstimateTotal(estimate.items))}
                      </TableCell>
                      <TableCell className={styles.tableCell} align="center">
                        {new Date(estimate.issue_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell className={styles.tableCell} align="center">
                        {estimate.status || 'Pending'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography className={styles.noEstimates}>No estimates found. Create a new estimate to get started.</Typography>
      )}
    </div>
  );
};

export default AdminEstimates;
