import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Paper, Grid, Avatar, Chip, Box, Button, Divider, CircularProgress } from '@mui/material';
import { CalendarToday, AccessTime, LocationOn, ArrowBack, Person, Description, AttachMoney } from '@mui/icons-material';
import styles from './ClientEventDetails.module.scss';
import { blue, green, red, grey, orange } from '@mui/material/colors';
import { getEventDetails } from '../services/api';

const InfoItem = ({ icon, color, text, title }) => (
  <Box>
    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>{title}</Typography>
    <Box display="flex" alignItems="center" mb={2}>
      <Avatar style={{ backgroundColor: grey[200], marginRight: '16px' }}>
        {React.cloneElement(icon, { style: { color } })}
      </Avatar>
      <Typography variant="body1">{text}</Typography>
    </Box>
  </Box>
);

const ClientEventDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        // Extract the event ID from the URL
        const pathParts = location.pathname.split('/');
        const eventId = pathParts[pathParts.length - 1];

        if (!eventId || isNaN(eventId)) {
          throw new Error('Invalid event ID');
        }

        console.log('Fetching event details for ID:', eventId);
        const eventData = await getEventDetails(eventId);
        setEvent(eventData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError(`Failed to fetch event details: ${err.message}`);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [location.pathname]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!event) {
    return <Typography>No event found</Typography>;
  }

  return (
    <div className={styles.eventDetailsContainer}>
      <Button 
        variant="outlined" 
        startIcon={<ArrowBack />} 
        onClick={() => navigate(-1)} 
        className={styles.backButton}
        sx={{ mb: 3 }}
      >
        Back
      </Button>
      <Paper elevation={3} className={styles.eventDetailsPaper} sx={{ p: 4 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>{event.emoji} {event.title}</Typography>
        
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <InfoItem 
              icon={<CalendarToday />} 
              color={blue[500]} 
              text={new Date(event.time_slots[0].start_time).toLocaleDateString()} 
              title="Date"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoItem 
              icon={<AccessTime />} 
              color={green[500]} 
              text={`${new Date(event.time_slots[0].start_time).toLocaleTimeString()} - 
                     ${new Date(event.time_slots[0].end_time).toLocaleTimeString()}`} 
              title="Time"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoItem 
              icon={<LocationOn />} 
              color={red[500]} 
              text={event.addresses[0] || 'No address provided'} 
              title="Address"
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>Description</Typography>
        <InfoItem 
          icon={<Description />} 
          color={orange[500]} 
          text={event.description} 
        />

        <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>Value</Typography>
        <InfoItem 
          icon={<AttachMoney />} 
          color={green[500]} 
          text={event.value ? `$${event.value.toFixed(2)}` : 'N/A'} 
        />

        <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>Employees</Typography>
        <Box>
          {event.employees.map((employee) => (
            <Chip
              key={employee.id}
              avatar={<Avatar><Person /></Avatar>}
              label={employee.name}
              sx={{ mr: 1, mb: 1 }}
            />
          ))}
        </Box>
      </Paper>
    </div>
  );
};

export default ClientEventDetails;
