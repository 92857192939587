import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { login as loginUser } from '../services/api';
import styles from './Login.module.scss';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { useAuth } from './AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      console.log('Login response:', response);

      if (response.data && response.data.user_role) {
        login({
          role: response.data.user_role,
          token: response.data.access_token
        });

        if (response.data.user_role === 'employee') {
          navigate('/employee');
        } else if (response.data.user_role === 'admin') {
          navigate('/admin');
        } else if (response.data.user_role === 'client') {
          navigate('/client');
        } else {
          console.error('Unknown user role:', response.data.user_role);
          alert('Login failed: Unknown user role');
        }
      } else {
        console.error('Invalid response format:', response);
        alert('Login failed: Invalid response from server');
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please check your credentials.');
    }
  };
  

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.particleContainer}>
        {[...Array(50)].map((_, index) => (
          <div key={index} className={styles.particle} />
        ))}
      </div>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <h2>Login</h2>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <button type="submit" className={styles.submitButton}>Login</button>
      </form>
    </div>
  );
}

export default Login;

